import React from "react";

const Contact = () => (
    <div>
        <div className="reservation-contact">
            {/* <div className="reservation-contact__photo">Foto</div> */}
            <div className="reservation-contact__text">
                <h2>Hulp nodig?</h2>
                <p>Wij staan voor je klaar, bel op: <a href='tel:0883745900'>088-3745900</a></p>
            </div>
        </div>
    </div>
)

export default Contact