import * as React from "react"
import { Rentvisie } from "@bakkie/ratality";
import ReservationSelectUpsell from "../../components/reservation/wispo/step-2"
import Contact from "../../components/reservation/shared/contact"
import Footer from '../../components/reservation/shared/footer'
import ReservationHeader from '../../components/reservation/shared/reservation-header'
import RentLocations from "../../components/shared/rent-locations"
import Spinner from "../../components/shared/spinner";
import { Container } from "react-bootstrap";
import { Link } from "gatsby";

const AccessoiresKiezenTemplate = (props) => {
  const [rental, setRental] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(undefined)
  const query = new URLSearchParams(props.location.search)
  const id = query.get('id')

  React.useEffect(() => {
    const rentalId = id

    Rentvisie.getRental(rentalId)
      .then(setRental)
      .catch(setError)
      .finally(() => setLoading(false))
  }, [])
  if (loading) {
    return (
      <div>
          <ReservationHeader activeStepIndex={2} />
        <Container fluid='xxl'>
          <div className="reservation__title">
            <h2>Gekozen Voertuig</h2>
          </div>
          <div className="reservation-select-wrapper">
            <div className="reservation-select-left">
              Gegevens ophalen, moment geduld! <br></br>
              <Spinner></Spinner>
            </div>
            <div className="reservation-select-right">
              <RentLocations />
              <Contact />
            </div>
          </div>
        </Container>
        <Footer />
      </div>
    )
  }
  if (error) {
    return (
      <div>
          <ReservationHeader activeStepIndex={2} />
        <Container fluid='xxl'>
          <div className="reservation__title">
            <h2>Gekozen Voertuig</h2>
          </div>
          <div className="reservation-select-wrapper">
            <div className="reservation-select-left">
              Er is iets misgegaan...! <br></br>
              <Link to='/'>Terug naar home</Link>
            </div>
            <div className="reservation-select-right">
              <RentLocations />
              <Contact />
            </div>
          </div>
        </Container>
        <Footer />
      </div>
    )
  }

  return (
    <div>
      <ReservationSelectUpsell rental={rental} id={id}/>
    </div>
  )
}

export default AccessoiresKiezenTemplate