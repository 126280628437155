import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import logo from '../../../images/logo.jpg'
import { Link } from "gatsby"
import { Container } from "react-bootstrap"


const Step = ({ step, active, beforeActive, isLast }) => (
    <div className="reservation-header__step">
        {
            beforeActive
                ? <span>{step.title}</span>
                : <span className={active ? 'bold' : ''}>{step.title}</span>
        }
        {!isLast && <div><FontAwesomeIcon icon={faChevronRight} /></div>}
    </div>
);

const RESERVATION_STEPS = [
    { title: 'Selecteer' },
    { title: `Extra's` },
    { title: 'Bevestigen' },
];

const ReservationHeader = ({ activeStepIndex }) => {
    const steps = RESERVATION_STEPS;
    return (
        <div className="background-light">
            <Container>
                <div className="reservation-header">

                    <div className="reservation-header__left">
                        <Link to='/'>
                            <img src={logo} alt="logo Bert Jonk" className="navbar-logo" />
                        </Link>
                    </div>
                    <div className="reservation-header__right">
                        <div className="reservation-header__steps">
                            {steps.map((step, index) =>
                                <Step
                                    step={step}
                                    beforeActive={index < (activeStepIndex - 1)}
                                    active={index === activeStepIndex - 1}
                                    isLast={index === steps.length - 1}
                                    key={index}
                                />)
                            }
                        </div>
                        {/* <div className="bottom-steps">
                    <div className={`inner step${activeStepIndex}`}></div>
                </div> */}
                    </div>
                </div>

            </Container>
        </div>
    )
}

export default ReservationHeader