import axios, { AxiosRequestHeaders } from 'axios';
import { firstValueFrom, ReplaySubject } from 'rxjs';

import {
  Branch,
  Category,
  DamageTemplate,
  Defect,
  PagedResponse,
  Rental,
  RentalFilters,
  Reservation,
  VehicleClass,
  VehicleCommand,
} from './models';
import { AddressQuery, AddressResponse } from './crimimail';
import { searchParams } from '@bakkie/util';
import { CheckListValue } from './models/checklist';
import { Vehicle } from './models/vehicles';

export interface CustomerData {
  company?: { name: string; vatNumber?: string };
  firstName?: string;
  lastName?: string;
  cellphoneNumber?: { countryDialingCode: string; number: string };
  identityDocument?: {
    number: string;
    documentType?: string;
  };
  dateOfBirth?: string;
  email?: string;
  iban?: string;
  bic?: string;
  driversLicense?: {
    number?: string;
    licenseType?: string;
    issuePlace?: string;
    issueDate?: string;
    expiryDate?: string;
  };
  physicalAddress?: {
    houseNumber: string;
    houseNumberAdditional?: string;
    streetName?: string;
    city?: string;
    postalCode: string;
    countryCode?: string;
  };
  externalRegistrationId?: string;
}

export interface RentvisieConfig {
  baseUrl: string;
  client: 'rentvisie' | 'bertjonk' | 'smarktbak' | string;
  useGuestToken?: boolean;
  user?: {
    username: string;
    password: string;
  };
}

export class Rentvisie {
  private static CONFIG: RentvisieConfig;

  private static readonly token$$: ReplaySubject<string> =
    new ReplaySubject<string>(1);
  static readonly token$ = this.token$$.asObservable();

  static async setConfig(config: RentvisieConfig): Promise<void> {
    Rentvisie.CONFIG = config;

    const { useGuestToken = true } = config;
    if (useGuestToken) {
      const token = await Rentvisie.refreshToken();

      Rentvisie.setToken(token);
    }
  }

  static setToken(token: string): void {
    Rentvisie.token$$.next(token);
  }

  static async signIn(username: string, password: string): Promise<string> {
    Rentvisie.CONFIG.user = { username, password };
    const token = await Rentvisie.refreshToken();

    Rentvisie.setToken(token);

    return token;
  }

  static async signUp(username: string, password: string): Promise<void> {
    const { baseUrl } = Rentvisie.CONFIG;
    const url = `${baseUrl}/auth/signup`;

    const body = { username, password };
    await axios.post(url, body);
  }

  static async googleSignIn(idToken: string): Promise<string> {
    const { baseUrl, client } = Rentvisie.CONFIG;
    const url = `${baseUrl}/auth/google`;

    const { data } = await axios.post(url, { idToken, client });

    Rentvisie.setToken(data.token);

    return data.token;
  }

  static async firebaseSignIn(idToken: string): Promise<string> {
    const { baseUrl, client } = Rentvisie.CONFIG;
    const url = `${baseUrl}/auth/firebase`;

    const { data } = await axios.post(url, { idToken, client });

    Rentvisie.setToken(data.token);

    return data.token;
  }

  static async refreshToken(): Promise<string> {
    // TODO Update to support other forms of login too
    const { baseUrl, client, user } = Rentvisie.CONFIG;

    if (user) {
      // User specific token
      const url = `${baseUrl}/auth/user_token`;
      const body = {
        ...user,
        client,
      };

      const { data } = await axios.post(url, body);

      return data.token;
    } else {
      // Guest token
      const searchParams = new URLSearchParams({ client });
      const url = `${baseUrl}/auth/token?${searchParams.toString()}`;

      const { data } = await axios.get(url);

      return data.token;
    }
  }

  static getToken(): Promise<string> {
    return firstValueFrom(Rentvisie.token$);
  }

  static async get<T>(
    path: string,
    searchParams?: URLSearchParams
  ): Promise<T> {
    if (!Rentvisie.CONFIG) {
      throw Error('No config set, use Rentvisie.setConfig to set');
    }

    const token = await Rentvisie.getToken();

    const { baseUrl } = Rentvisie.CONFIG;
    const headers: AxiosRequestHeaders = { Authorization: `Bearer ${token}` };

    const url = `${baseUrl}/${path}${
      searchParams ? `?${searchParams.toString()}` : ''
    }`;
    console.log(`GET: ${url}`);

    const { data } = await axios.get(url, { headers });

    return data;
  }

  static async post<T>(path: string, body: any): Promise<T> {
    if (!Rentvisie.CONFIG) {
      throw Error('No config set, use Rentvisie.setConfig to set');
    }

    const token = await Rentvisie.getToken();

    const { baseUrl } = Rentvisie.CONFIG;
    const headers: AxiosRequestHeaders = { Authorization: `Bearer ${token}` };

    const url = `${baseUrl}/${path}`;
    console.log(`POST: ${url}`);

    const { data } = await axios.post(url, body, { headers });

    return data;
  }

  static async put<T>(path: string, body: any): Promise<T> {
    if (!Rentvisie.CONFIG) {
      throw Error('No config set, use Rentvisie.setConfig to set');
    }

    const token = await Rentvisie.getToken();

    const { baseUrl } = Rentvisie.CONFIG;
    const headers: AxiosRequestHeaders = { Authorization: `Bearer ${token}` };

    const url = `${baseUrl}/${path}`;
    console.log(`PUT: ${url}`);

    const { data } = await axios.put(url, body, { headers });

    return data;
  }

  static async patch<T>(path: string, body: any): Promise<T> {
    if (!Rentvisie.CONFIG) {
      throw Error('No config set, use Rentvisie.setConfig to set');
    }

    const token = await Rentvisie.getToken();

    const { baseUrl } = Rentvisie.CONFIG;
    const headers: AxiosRequestHeaders = { Authorization: `Bearer ${token}` };

    const url = `${baseUrl}/${path}`;
    console.log(`PATCH: ${url}`);

    const { data } = await axios.patch(url, body, { headers });

    return data;
  }

  static async delete<T>(path: string): Promise<T> {
    if (!Rentvisie.CONFIG) {
      throw Error('No config set, use Rentvisie.setConfig to set');
    }

    const token = await Rentvisie.getToken();

    const { baseUrl } = Rentvisie.CONFIG;
    const headers: AxiosRequestHeaders = { Authorization: `Bearer ${token}` };

    const url = `${baseUrl}/${path}`;
    console.log(`DELETE: ${url}`);

    const { data } = await axios.delete(url, { headers });

    return data;
  }

  static async getCategories(): Promise<Category[]> {
    return Rentvisie.get<Category[]>('categories');
  }

  static async getLocations(): Promise<Location[]> {
    return Rentvisie.get<Location[]>('locations/Rental');
  }

  static async getRental(id: string): Promise<Rental> {
    return Rentvisie.get<Rental>(`rentals/${id}`);
  }

  static async getRentals(
    pickupLocationId: number | string | undefined,
    dropOffLocationId: number | string | undefined,
    filters: RentalFilters
  ): Promise<Rental[]> {
    const {
      pickupDate,
      pickupTime,
      dropOffDate,
      dropOffTime,
      vehicleClassId,
      categoryId,
      branchId,
      searchLocation,
    } = filters;

    const params = new URLSearchParams({
      pickupDate,
      dropOffDate,
      pickupTime,
      dropOffTime,
    });

    if (pickupLocationId) {
      params.append('pickupLocationId', `${pickupLocationId}`);
    }

    if (dropOffLocationId) {
      params.append('dropOffLocationId', `${dropOffLocationId}`);
    }

    if (vehicleClassId) {
      params.append('vehicleClassId', `${vehicleClassId}`);
    }

    if (categoryId) {
      params.append('categoryId', `${categoryId}`);
    }

    if (branchId) {
      params.append('branchId', `${branchId}`);
    }

    if (searchLocation?.latitude) {
      params.append('latitude', `${searchLocation.latitude}`);
    }
    if (searchLocation?.longitude) {
      params.append('longitude', `${searchLocation.longitude}`);
    }

    if (searchLocation?.radius) {
      params.append('radius', `${searchLocation.radius}`);
    }

    return Rentvisie.get<Rental[]>('rentals', params);
  }

  static async getVehicleClasses(
    categoryId?: number,
    branchId?: number
  ): Promise<VehicleClass[]> {
    const params = new URLSearchParams();
    if (categoryId) {
      params.set('categoryId', `${categoryId}`);
    }

    if (branchId) {
      params.set('branchId', `${branchId}`);
    }

    return Rentvisie.get<VehicleClass[]>('vehicle-classes', params);
  }

  static async getVehicleClass(id: number): Promise<VehicleClass> {
    return Rentvisie.get<VehicleClass>(`vehicle-classes/${id}`);
  }

  static async getReservation(
    reservationReference: string
  ): Promise<Reservation> {
    return Rentvisie.get<Reservation>(`reservations/${reservationReference}`);
  }

  static async getReservationSecured(
    reservationReference: string
  ): Promise<Reservation> {
    return Rentvisie.get<Reservation>(
      `reservations/${reservationReference}/sec`
    );
  }

  static async postReservation(rentalId: number): Promise<Reservation> {
    const body = { rentalId };

    return Rentvisie.post<Reservation>('reservations/alt', body);
  }

  static async getReservations(
    pageNumber: number,
    pageSize: number
  ): Promise<PagedResponse<Reservation, 'reservations'>> {
    return Rentvisie.get(
      'reservations',
      searchParams({ pageNumber, pageSize })
    );
  }

  static getVehicles(
    pageNumber: number,
    pageSize: number,
    reservationReference: string,
    bookingReference: string
  ): Promise<PagedResponse<Vehicle, 'vehicles'>> {
    return Rentvisie.get(
      'vehicles',
      searchParams({
        pageNumber,
        pageSize,
        reservationReference,
        bookingReference,
      })
    );
  }

  static setVehicle(
    reservationReference: string,
    bookingReference: string,
    vehicleId: number
  ): Promise<unknown> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/vehicle`;

    return Rentvisie.put(url, { vehicleId });
  }

  static cancelReservation(reservationReference: string): Promise<unknown> {
    const url = `reservations/${reservationReference}`;

    return Rentvisie.delete(url);
  }

  static async setInsurance(
    reservationReference: string,
    bookingReference: string,
    optionId: number
  ): Promise<any> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/insurances`;

    Rentvisie.put(url, { optionId });
  }

  static async setMileageOption(
    reservationReference: string,
    bookingReference: string,
    optionId: number
  ): Promise<any> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/mileage-packages`;

    Rentvisie.put(url, { optionId });
  }

  static async addOptionalOption(
    reservationReference: string,
    bookingReference: string,
    optionId: number
  ): Promise<any> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/optional`;

    Rentvisie.post(url, { optionId });
  }

  static async addOptionalOptions(
    reservationReference: string,
    bookingReference: string,
    optionIds: number[]
  ): Promise<any> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/optionals`;

    Rentvisie.post(url, { optionIds });
  }

  static async setOptionalOptions(
    reservationReference: string,
    bookingReference: string,
    optionIds: number[]
  ): Promise<any> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/optionals`;

    Rentvisie.put(url, { optionIds });
  }

  static async addAllOptions(
    reservationReference: string,
    bookingReference: string,
    data: any
  ): Promise<any> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/all-optionals`;

    Rentvisie.post(url, data);
  }

  static async setAllOptions(
    reservationReference: string,
    bookingReference: string,
    data: any
  ): Promise<any> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/all-optionals`;

    Rentvisie.put(url, data);
  }

  static async deleteOptionalOption(
    reservationReference: string,
    bookingReference: string,
    optionId: number
  ): Promise<any> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/optionals/${optionId}`;

    Rentvisie.delete(url);
  }

  static async getAddress(query: AddressQuery): Promise<AddressResponse> {
    const params = new URLSearchParams({ ...query });

    return Rentvisie.get<AddressResponse>('crimi/address', params);
  }

  static async addCustomerData(
    reservationReference: string,
    bookingReference: string,
    data: CustomerData
  ) {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/customer`;
    return Rentvisie.patch(url, data);
  }

  static async getBranches(): Promise<Branch[]> {
    const url = `branches`;

    return Rentvisie.get<Branch[]>(url);
  }

  static async getBranch(branchId: number) {
    const url = `branches/${branchId}`;

    return Rentvisie.get<Branch>(url);
  }

  static confirmationEmail(
    reservationReference: string,
    bookingReference: string,
    language: 'nl' | 'en' = 'nl'
  ): Promise<unknown> {
    const url = `confirmation-email`;

    return Rentvisie.post(url, {
      reservationReference,
      bookingReference,
      language,
    });
  }

  static lock(
    reservationReference: string,
    bookingReference: string
  ): Promise<unknown> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/vehicle/commands`;
    return Rentvisie.post(url, { command: VehicleCommand.Lock });
  }

  static unlock(
    reservationReference: string,
    bookingReference: string
  ): Promise<unknown> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/vehicle/commands`;
    return Rentvisie.post(url, { command: VehicleCommand.Unlock });
  }

  static getDamageTemplate(
    reservationReference: string,
    bookingReference: string
  ): Promise<DamageTemplate> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/vehicle/template`;
    return Rentvisie.get(url);
  }

  static getDefects(
    reservationReference: string,
    bookingReference: string
  ): Promise<Defect[]> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/vehicle/defects`;
    return Rentvisie.get(url);
  }

  static postDefects(
    reservationReference: string,
    bookingReference: string,
    defects: Partial<Defect>[]
  ): Promise<unknown> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/vehicle/defects`;
    return Rentvisie.post(url, { defects });
  }

  static getExtension(
    reservationReference: string,
    bookingReference: string
  ): Promise<{
    dateTo: Date;
    locationIdTo: string;
    comments: string;
    success: boolean;
  }> {
    // TODO Correct return type
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/vehicle/extension`;

    return Rentvisie.get(url);
  }

  static postExtension(
    reservationReference: string,
    bookingReference: string,
    dateTo: Date
  ): Promise<unknown> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/vehicle/extension`;

    return Rentvisie.post(url, { dateTo });
  }

  // TODO Extract return type
  static getChecklist(
    reservationReference: string,
    bookingReference: string,
    checklistType: 'Pickup' | 'DropOff'
  ): Promise<{
    checklistItems: { checklistItemId: number; description: string }[];
  }> {
    // TODO Correct return type
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/vehicle/check-list/${checklistType}`;

    return Rentvisie.get(url);
  }

  static postChecklist(
    reservationReference: string,
    bookingReference: string,
    checklistType: 'Pickup' | 'DropOff',
    checklistItems: CheckListValue[]
  ): Promise<unknown> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/vehicle/check-list/${checklistType}`;
    console.log('CHECKLISTITEMS', checklistItems);

    return Rentvisie.post(url, { checklistItems });
  }

  static postPromotionCode(
    reservationReference: string,
    code: string
  ): Promise<unknown> {
    const url = `reservations/${reservationReference}/promotion-code`;
    return Rentvisie.post(url, { code });
  }

  static deletePromotionCode(reservationReference: string): Promise<unknown> {
    const url = `reservations/${reservationReference}/promotion-code`;
    return Rentvisie.delete(url);
  }

  static async getRegistrationStatus(): Promise<string> {
    const url = `crimi/registration-status`;

    const { status } = await Rentvisie.get<{ status: string }>(url);

    return status;
  }

  static async updateReservationStatus(
    reservationReference: string,
    bookingReference: string,
    checklistType: 'Pickup' | 'Dropoff'
  ): Promise<unknown> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/vehicle/inspection/${checklistType}`;

    // const now = new Date();

    const body = {
      completed: true,
      // dateTime: now.toISOString()
    };

    return Rentvisie.post(url, { completed: true, body });
  }

  static async getCustomer(
    email: string,
    dateOfBirth: string
  ): Promise<CustomerData> {
    const url = ``; // TODO Add endpoint

    return Rentvisie.get(url, new URLSearchParams({ dateOfBirth }));
  }
}
