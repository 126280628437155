import React, { useState, useEffect } from "react";
import ReservationCheckout from "../../components/reservation/step-3"
import { Rentvisie } from "@bakkie/ratality";
import Container from "react-bootstrap/Container"
import Spinner from "../../components/shared/spinner";
// import reservationData from "./reservation.json"@bakkie/libs/ratality/src

const CheckoutTemplate = (props) => {
  const query = new URLSearchParams(props.location.search);

  const reservationReference = query.get('reservationReference');
  const [reservationData, setReservationData] = useState({})
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(undefined)

  useEffect(() => {
    Rentvisie.setConfig({
      baseUrl: 'https://api.rentvisie.com/api',
      client: 'bertjonk',
      useGuestToken: true
    })

    Rentvisie.getReservationSecured(reservationReference)
      .then(setReservationData)
      .catch(setError)
      .finally(() => setLoading(false))
  }, [])

  if (loading) {
    return (
      <Container fluid='xxl'>
        <div className="spinner-background">
          <Spinner></Spinner>
        </div>
      </Container>
    )
  }
  if (error) {
    return (
      <p>Er is iets mis gegaan. Probeer een nieuwe reservering te maken of bel ons.</p>
    )
  }
  return (
    <div>
      <ReservationCheckout
        reservationData={reservationData}
        query={query}
        hasDiscount={props.location?.state?.hasDiscount}
      />
    </div>
  )
}

export default CheckoutTemplate