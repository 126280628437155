import React, { useEffect } from "react"
import Distance from "../../../images/svg/distance.png"
import { compare } from "./HelperFunctions"

const SelectMileagePackageOptions = ({
  mileagePackageOptions,
  valueChanges,
  totalDays,
  includeVat,
  marketingOverMileagePrice,
  setMileageFilter,
  mileageFilter,
  defaultIncluded,
}) => {
  console.log(defaultIncluded)
  useEffect(() => {
    let mileageDefault = []
    let mileageDefaultNew = []
    let mileageDay = []
    let mileageWeekA = []
    let mileageWeekB = []
    let mileageFilter = []

    mileageDefault = mileagePackageOptions.filter(option =>
      option.description.includes("Inbegrepen")
    )
    if (totalDays <= 1) {
      mileageDay = mileagePackageOptions.filter(option =>
        option.description.includes(" 500")
      )
      mileageDefaultNew = mileageDefault.map(obj => ({ ...obj, km: 100 }))
    } else if (totalDays > 1 && totalDays < 7) {
      mileageDay = mileagePackageOptions.filter(option =>
        option.description.includes(" 500")
      )
      // mileageWeekend = mileagePackageOptions.filter((option) => option.description.includes('200'));
      mileageDefaultNew = mileageDefault.map(obj => ({ ...obj, km: 100 }))
    } else {
      mileageWeekA = mileagePackageOptions.filter(option =>
        option.description.includes("2100")
      )
      mileageWeekB = mileagePackageOptions.filter(option =>
        option.description.includes("3500")
      )
      mileageDefaultNew = mileageDefault.map(obj => ({ ...obj, km: 150 }))
    }

    const mileageDayNew = mileageDay.map(obj => ({ ...obj, km: 500 }))
    const MileageWeekANew = mileageWeekA.map(obj => ({ ...obj, km: 300 }))
    const mileageWeekBNew = mileageWeekB.map(obj => ({ ...obj, km: 500 }))

    mileageFilter = mileageDefaultNew.concat(
      mileageDayNew,
      MileageWeekANew,
      mileageWeekBNew
    )

    setMileageFilter(mileageFilter)
  }, [mileagePackageOptions, includeVat, totalDays])

  if (mileageFilter?.length === 0) {
    return null
  }

  return (
    <div className="extra-wrapper">
      <div className="extra-wrapper__title">
        <img src={Distance} alt="Afstand"></img>
        <h3>Kilometerpakket</h3>
      </div>
      <div
        className="extrabox-grid"
        onChange={selected =>
          valueChanges("mileagePackageOptions", [selected.target.id])
        }
      >
        {mileageFilter.sort(compare).map(
          option =>
            (defaultIncluded < option.km * totalDays ||
              option.price.value === 0) && (
              <div key={option.optionId}>
                <input
                  type="radio"
                  id={option.optionId}
                  name="mileageId"
                  value={option.price.value}
                  defaultChecked={option.price.value === 0}
                />
                <label htmlFor={option.optionId}>
                  <div className="extrabox">
                    <h3>
                      {option.price.value === 0
                        ? "Inbegrepen: "
                        : "Upgrade naar "}
                      {option.price.value === 0
                        ? defaultIncluded
                        : option.km * totalDays}{" "}
                      vrije kilometers (€
                      {includeVat !== "yes"
                        ? ((option.price.value * totalDays) / 1.21)
                            .toFixed(2)
                            .replace(".", ",")
                        : (option.price.value * totalDays)
                            .toFixed(2)
                            .replace(".", ",")}
                      )
                    </h3>
                    <p className="dark-gray">
                      Totaal:{" "}
                      {option.price.value === 0
                        ? defaultIncluded
                        : option.km * totalDays}{" "}
                      vrije kilometers (
                      {option.price.value === 0
                        ? defaultIncluded / totalDays
                        : option.km}
                      km/dag) <br></br> Meerkilometers:
                      {marketingOverMileagePrice &&
                        ` € ${marketingOverMileagePrice.value} `}{" "}
                      per km
                      <br></br>
                      <span style={{ fontStyle: "italic" }}>
                        (brandstof niet inbegrepen)
                      </span>
                    </p>
                  </div>
                </label>
              </div>
            )
        )}
      </div>
    </div>
  )
}

export default SelectMileagePackageOptions
