import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Settings from "../../../components/settings.json";
const options = Settings.locations;


// const Select = props => (
//   <FixRequiredSelect
//     {...props}
//     SelectComponent={BaseSelect}
//     options={props.options || options}
//   />
// );

const LocationSelect = ({ selectedLocation, setSelectedLocation }) => {
  const [defaultValue, setDefaultValue] = useState(options[0])
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      // Overwrittes the different states of border
      borderColor: '#DF1024',
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        cursor: 'pointer',
        borderColor: '#DF1024',
      },
      height: 48,
      fontWeight: "normal",
      fontSize: 14,
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 300,
      borderBottom: '1px dotted black',
      color: state.selectProps.menuColor,
      '&:hover': { cursor: 'pointer' },
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted black',
      fontWeight: state.isSelected ? "bold" : "normal",
      background: state.isSelected ? "yellow" : "white",
      color: "black",
      '&:active': { color: 'black' },
      '&:hover': { cursor: 'pointer', background: 'yellow' },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  }

  useEffect(() => {
    if (selectedLocation) {
      setDefaultValue(selectedLocation)
    }
  }, [selectedLocation])

  return (
    <Select
      name="location"
      value={defaultValue}
      onChange={setSelectedLocation}
      options={options}
      className='react-select-container'
      classNamePrefix="react-select"
      styles={customStyles}
      readOnly={true}
      isSearchable={false}
      required
    />
  );

}

export default LocationSelect