import { useState } from 'react';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Settings from "../settings.json"

const QuoteForm = ({ requestedVehicle, location}) => {
    const [formSubmitted, setFormSubmitted] = useState(false)
    const { register, formState: { errors }, handleSubmit } = useForm();
    const onSubmit = (data) => {
        axios.post(Settings.quoteUrl, { data })
            .then(setFormSubmitted(true))
    };
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    return (
        <div>
            {!formSubmitted &&
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='form-row'>
                        <div className='form-100'>
                            <div className='textOnInput'>
                                <label htmlFor='naam'>Naam</label>
                                <input
                                    type="text"
                                    className="form-input-alt"
                                    {...register("naam", { required: true, maxLength: 180 })}
                                />
                            </div>
                        </div>
                        <div className='form-100'>
                            <div className='textOnInput'>
                                <label htmlFor='email'>E-mail</label>
                                <input
                                    type="text"
                                    className="form-input-alt"

                                    {...register("email", {
                                        required: true,
                                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    })}
                                />
                            </div>
                        </div>
                        <div className='form-100'>
                            <div className='textOnInput'>
                                <label htmlFor='telefoon'>Telefoon</label>
                                <input
                                    type="tel"
                                    className="form-input-alt"
                                    {...register("telefoon", {
                                        required: true,
                                        maxLength: 11,
                                        minLength: 8
                                    })}
                                />
                            </div>
                        </div>
                        <div className='form-50'>
                            <div className='textOnInput'>
                                <label htmlFor='ophaaldatum'>Ophaaldatum</label>
                                <input
                                    type="text"
                                    className="form-input-alt"
                                    placeholder="dd-mm-yyyy"
                                    min={`${tomorrow.getFullYear() - tomorrow.getMonth() - tomorrow.getDate()}`}
                                    {...register("ophaaldatum", {
                                        required: true,

                                    })}
                                />
                            </div>
                        </div>
                        <div className='form-50'>
                            <div className='textOnInput'>
                                <label htmlFor='retourdatum'>Retourdatum</label>
                                <input
                                    type="text"
                                    className="form-input-alt"
                                    placeholder="dd-mm-yyyy"
                                    {...register("retourdatum", {
                                        required: true,
                                    })}
                                />
                            </div>
                        </div>
                        <div className='form-100'>
                            <div className='textOnInput'>
                                <label htmlFor='bericht'>Opmerkingen</label>
                                <textarea
                                    rows="4"
                                    className="form-input-alt"
                                    {...register("bericht")}
                                />
                            </div>
                        </div>
                    </div>
                    <input type="hidden"
                        value={requestedVehicle.description}
                        {...register("voertuig")}
                    />
                    <input type="hidden"
                        value={location}
                        {...register("locatie")}
                    />
                    <div style={{ float: 'right', marginTop: 20 }}>
                        <input type="submit" className='button button-primary' value="Offerte opvragen" />
                    </div>
                    {errors.naam && <span>Controleer uw naam</span>}
                    {errors.email && <span>Controleer uw e-mail</span>}
                    {errors.telefoon && <p>Controleer uw telefoonnummer</p>}
                    {errors.ophaaldatum && <p>Controleer de ophaaldatum</p>}
                    {errors.retourdatum && <p>Controleer de retourdatum</p>}
                    {errors.bericht && <p>Controleer uw opmerkingen</p>}
                </form>
            }
            {
                formSubmitted &&
                <h3 style={{ padding: 50 }}>Bedankt, wij hebben uw aanvraag in goede orde ontvangen.</h3>
            }
        </div>

    );
}

export default QuoteForm