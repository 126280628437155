import { AddressQuery, AddressResponse } from './crimimail';
import { ApiClient } from './api-client';
import {
  AuthResult,
  Category,
  CheckListValue,
  DamageTemplate,
  Defect,
  DepositResponse,
  PagedResponse,
  Rental,
  RentalFilters,
  Reservation,
  Schedules,
  SchedulesFilter,
  Vehicle,
  VehicleCommand,
} from './models';
import { CustomerData, RentvisieConfig } from './rentvisie';

export class RentvisieInstance {
  private readonly apiClient: ApiClient;

  constructor(private config: RentvisieConfig) {
    console.log(`Creating Rentvisie instance, ${config.baseUrl}`);
    this.apiClient = new ApiClient(config);
  }

  firebaseSignIn(idToken: string): Promise<AuthResult> {
    return this.apiClient.firebaseSignIn(idToken);
  }

  signout(): void {
    this.apiClient.signout();
  }

  getAddress(query: AddressQuery): Promise<AddressResponse> {
    return this.apiClient.authenticated
      .get<AddressResponse>('crimi/address', { params: query })
      .then((response) => response.data);
  }

  getCategories(): Promise<Category[]> {
    return this.apiClient.authenticated
      .get<Category[]>('categories')
      .then((response) => response.data);
  }

  getLocations(): Promise<Location[]> {
    return this.apiClient.authenticated
      .get<Location[]>('locations/Rental')
      .then((response) => response.data);
  }

  getRental(id: string): Promise<Rental> {
    return this.apiClient.authenticated
      .get<Rental>(`rentals/${id}`)
      .then((response) => response.data);
  }

  getVehicles(
    pageNumber: number,
    pageSize: number,
    reservationReference: string,
    bookingReference: string
  ): Promise<PagedResponse<Vehicle, 'vehicles'>> {
    return this.apiClient.authenticated
      .get('vehicles', {
        params: {
          pageNumber,
          pageSize,
          reservationReference,
          bookingReference,
        },
      })
      .then((response) => response.data);
  }

  setVehicle(
    reservationReference: string,
    bookingReference: string,
    vehicleId: number
  ): Promise<unknown> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/vehicle`;

    return this.apiClient.authenticated.put(url, { vehicleId });
  }

  getReservation(reservationReference: string): Promise<Reservation> {
    return this.apiClient.authenticated
      .get<Reservation>(`reservations/${reservationReference}`)
      .then((response) => response.data);
  }

  getReservations(
    pageNumber: number,
    pageSize: number
  ): Promise<PagedResponse<Reservation, 'reservations'>> {
    return this.apiClient.authenticated
      .get('reservations', { params: { pageNumber, pageSize } })
      .then((response) => response.data);
  }

  getRentals(
    pickupLocationId: number | string | undefined,
    dropOffLocationId: number | string | undefined,
    filters: RentalFilters
  ): Promise<Rental[]> {
    const {
      pickupDate,
      pickupTime,
      dropOffDate,
      dropOffTime,
      vehicleClassId,
      categoryId,
      branchId,
      searchLocation,
    } = filters;

    const params: any = {
      pickupDate,
      dropOffDate,
      pickupTime,
      dropOffTime,
    };

    if (pickupLocationId) {
      params.pickupLocationId = `${pickupLocationId}`;
    }

    if (dropOffLocationId) {
      params.dropOffLocationId = `${dropOffLocationId}`;
    }

    if (vehicleClassId) {
      params.vehicleClassId = `${vehicleClassId}`;
    }

    if (categoryId) {
      params.categoryId = `${categoryId}`;
    }

    if (branchId) {
      params.branchId = `${branchId}`;
    }

    if (searchLocation?.latitude) {
      params.latitude = `${searchLocation.latitude}`;
    }
    if (searchLocation?.longitude) {
      params.longitude = `${searchLocation.longitude}`;
    }

    if (searchLocation?.radius) {
      params.radius = `${searchLocation.radius}`;
    }

    return this.apiClient.authenticated
      .get<Rental[]>('rentals', { params })
      .then((response) => response.data);
  }

  getExtension(
    reservationReference: string,
    bookingReference: string,
  ): Promise<{
    dateTo: Date;
    locationIdTo: string;
    comments: string;
    success: boolean;
  }> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/vehicle/extension`;

    return this.apiClient.authenticated
      .get(url)
      .then((response) => response.data);
  }

  postExtension(
    reservationReference: string,
    bookingReference: string,
    dateTo: Date
  ): Promise<unknown> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/vehicle/extension`;
    return this.apiClient.authenticated.post(url, { dateTo });
  }
  // TODO Extract return type
  getChecklist(
    reservationReference: string,
    bookingReference: string,
    checklistType: 'Pickup' | 'DropOff'
  ): Promise<{
    checklistItems: { checklistItemId: number; description: string }[];
  }> {
    // TODO Correct return type
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/vehicle/check-list/${checklistType}`;

    return this.apiClient.authenticated
      .get(url)
      .then((response) => response.data);
  }

  postChecklist(
    reservationReference: string,
    bookingReference: string,
    checklistType: 'Pickup' | 'DropOff',
    checklistItems: CheckListValue[]
  ): Promise<unknown> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/vehicle/check-list/${checklistType}`;


    return this.apiClient.authenticated.post(url, { checklistItems });
  }

  lock(
    reservationReference: string,
    bookingReference: string
  ): Promise<unknown> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/vehicle/commands`;
    return this.apiClient.authenticated.post(url, {
      command: VehicleCommand.Lock,
    });
  }

  unlock(
    reservationReference: string,
    bookingReference: string
  ): Promise<unknown> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/vehicle/commands`;
    return this.apiClient.authenticated.post(url, {
      command: VehicleCommand.Unlock,
    });
  }

  updateReservationStatus(
    reservationReference: string,
    bookingReference: string,
    checklistType: 'Pickup' | 'Dropoff'
  ): Promise<unknown> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/vehicle/inspection/${checklistType}`;
    return this.apiClient.authenticated.post(url, { completed: true });
  }

  async postRegistration(
    userData: any,
    { front, back }: { front: string; back: string }
  ) {
    const url = `crimi/registrations`;

    const result = await this.apiClient.authenticated.post(url, {
      userData,
      front,
      back,
    });

    console.log('result: ', result);
  }

  getRegistrationStatus(): Promise<{ status: string }> {
    const url = `crimi/registration-status`;

    return this.apiClient.authenticated
      .get<{ status: string }>(url)
      .then((response) => response.data);
  }

  async postReservation(rentalId: number): Promise<Reservation> {
    return this.apiClient.authenticated
      .post<Reservation>('reservations', { rentalId })
      .then((response) => response.data);
  }

  cancelReservation(reservationReference: string): Promise<unknown> {
    const url = `reservations/${reservationReference}`;

    return this.apiClient.authenticated.delete(url);
  }

  async addCustomerData(
    reservationReference: string,
    bookingReference: string,
    data: CustomerData
  ) {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/customer`;
    return this.apiClient.authenticated.patch(url, data);
  }

  getDamageTemplate(
    reservationReference: string,
    bookingReference: string
  ): Promise<DamageTemplate> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/vehicle/template`;
    return this.apiClient.authenticated
      .get<DamageTemplate>(url)
      .then(({ data }) => {
        return {
          ...data,
          sideImages: data.sideImages.filter((image) => image.image.image),
        };
      });
  }

  getDepositOutstanding(
    reservationReference: string
  ): Promise<DepositResponse> {
    const url = `reservations/${reservationReference}/deposit`;
    return this.apiClient.authenticated.get(url).then((response) => response.data);
  }

  postDefects(
    reservationReference: string,
    bookingReference: string,
    defects: Partial<Defect>[]
  ): Promise<unknown> {
    const url = `reservations/${reservationReference}/rentals/${bookingReference}/vehicle/defects`;
    return this.apiClient.authenticated.post(url, { defects });
  }

  /**
 * Retrieves schedules based on the provided filters.
 * @param filters - The filters to apply to the schedules request.
 * @returns A Promise that resolves to the schedules data.
 * @throws {Error} If there's an issue fetching the schedules.
 */
  // TODO: Review
  async getSchedules(filters: SchedulesFilter): Promise<Schedules> {
    const params: SchedulesFilter = filters;

    try {
      const response = await this.apiClient.authenticated.get<Schedules>('schedules', { params });
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch schedules.');
    }
  }
}
