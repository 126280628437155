import { AxiosRequestConfig, AxiosResponse } from "axios";

export function logRequest(config: AxiosRequestConfig): AxiosRequestConfig {
  console.log(`${config.method?.toUpperCase()}: ${config.url}`);

  return config;
}

export function logResponse(response: AxiosResponse): AxiosResponse {
  console.log(`${response.config.url}: `, response.data);

  return response;
}
