import React, { useEffect, useState } from "react";
import Select from 'react-select'
import DatePicker from "react-datepicker";
import nl from 'date-fns/locale/nl';
import differenceInDays from 'date-fns/differenceInDays'
import add from 'date-fns/add'
import "react-datepicker/dist/react-datepicker.css";
import { timeOptionsPickup, timeOptionsReturn, defaultStartDate, defaultEndDate, filterTimes, filterTimesFunction, isNotSunday } from "../../hooks/DateSelectFunctions";
import { TimeOption } from "../../interfaces/DateTimeInterfaces";



const SplittedPicker = ({setPickupDate, setReturnDate, setPickupTime, setReturnTime}) => {
    const [startDate, setStartDate] = useState<Date>(defaultStartDate());
    const [endDate, setEndDate] = useState<Date>(defaultEndDate());
    const [selectableTimesPickup, setSelectableTimesPickup] = useState<TimeOption[] | undefined>(filterTimesFunction(timeOptionsPickup, filterTimes(startDate)))
    const [selectPickupValue, setSelectPickupValue] = useState<TimeOption | undefined>(filterTimesFunction(timeOptionsPickup, filterTimes(startDate))[0])
    const [selectReturnValue, setSelectReturnValue] = useState<TimeOption | undefined>(selectableTimesPickup[(selectableTimesPickup.length - 1)])

    useEffect(() => {
        setSelectableTimesPickup(filterTimesFunction(timeOptionsPickup, filterTimes(startDate)))
    }, [startDate])

    const today = new Date();
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    const splitSelectPickupValue = selectPickupValue.value.split(':');
    const splitSelectReturnValue = selectReturnValue.value.split(':');
    const getHoursFromSelectPickupValue = parseInt(splitSelectPickupValue[0]);
    const getHoursFromSelectReturnValue = parseInt(splitSelectReturnValue[0]);
    const getEndOfDayValueFromSelectableTimes = selectableTimesPickup[selectableTimesPickup.length - 1]
    const splitEndOfDayValueFromSelectableTimes = getEndOfDayValueFromSelectableTimes.value.split(':')
    const getEndOfDayHoursFromSelectableTimes = parseInt(splitEndOfDayValueFromSelectableTimes[0]);

    // Use first selectable time
    const splitSelectableTimes = selectableTimesPickup[0].value.split(':');
    const getHoursFromSelectableTimes = parseInt(splitSelectableTimes[0])

    const changeHandlerPickup = (date: Date) => {

        // OPTIONAL: IF DATE EQUALS CLOSED .. 
        if (date.getDay() === 0) {
            alert('Wij zijn helaas gesloten op zondag')
            setStartDate(add(date, { days: 1}))
            setSelectPickupValue(selectableTimesPickup[0])
            setSelectReturnValue(selectableTimesPickup[selectableTimesPickup.length - 1])
        } else if (date.getDate() === 26 && date.getMonth() === 11) {
            alert('Wij zijn gesloten op 2e kerstdag. Neem contact met ons op om het voertuig eerder op te halen.')
            setStartDate(add(date, { days: 1}))
            setSelectPickupValue(selectableTimesPickup[0])
            setSelectReturnValue(selectableTimesPickup[selectableTimesPickup.length - 1])
        } else {
            setStartDate(date)
        }


        if (date > endDate) {
            setEndDate(add(date, { days: 8}))
            return;
        }

        if (differenceInDays(endDate, date) > 29) {
            setEndDate(add(date, { days: 8}))
            return;
        }

        if (differenceInDays(endDate, date) < 7) {
            setEndDate(add(date, { days: 8}))
            return;
        }
    }

    const changeHandlerReturn = (date: Date) => {
        if (differenceInDays(date, startDate) < 6) {
            alert('Let op: de minimale huurperiode is één week, selecteer een andere retourdatum of pas eerst de ophaaldatum aan');
            return;
        }
        setEndDate(date)
        if ((new Date(date).getTime() <= new Date(startDate).getTime()) && (getHoursFromSelectPickupValue >= getHoursFromSelectReturnValue)) {
            setSelectPickupValue(selectableTimesPickup[0])
            setSelectReturnValue(selectableTimesPickup[selectableTimesPickup.length - 1])
        }
        return;
    }

    // Adjust select for scenario in which pickup time is restricted
    useEffect(() => {
        if (getHoursFromSelectPickupValue < getHoursFromSelectableTimes) {
            setSelectPickupValue(selectableTimesPickup[0])
        } else if (getHoursFromSelectPickupValue >= getEndOfDayHoursFromSelectableTimes) {
            setSelectPickupValue(selectableTimesPickup[selectableTimesPickup.length - 1])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectableTimesPickup])

    // OPTIONAL: Add this block to prevent pickup today if closed : alternatively use switch
    useEffect(() => {
        if (startDate.getDay() === 0) {
            setStartDate(tomorrow)
            setEndDate(add(tomorrow, { days: 8}))
        }

        // Kerst
        if (startDate.getDate() === 26 && startDate.getMonth() === 12) {
            setStartDate(tomorrow)
            setEndDate(add(tomorrow, { days: 8}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setPickupDate(startDate)
    }, [startDate])

    useEffect(() => {
        setReturnDate(endDate)
    }, [endDate])

    useEffect(() => {
        setPickupTime(selectPickupValue)
    }, [selectPickupValue])

    useEffect(() => {
        setReturnTime(selectReturnValue)
    }, [selectReturnValue])

    return (
        <div className="datetimepicker-wrapper">
            <div className="datetimepicker-row">
                <div>Ophaaldatum:</div>
                <DatePicker
                    locale={nl}
                    selected={startDate}
                    onChange={changeHandlerPickup}
                    filterDate={isNotSunday}
                    dateFormat="dd-MM-yyyy"
                    plcaeholderText="Selecteer een ophaaltijd"
                    highlightDates={[(new Date())]}
                    minDate={new Date()}
                    withPortal
                />
            </div>
            <div className="datetimepicker-row">
                <div>Ophaaltijd:</div>
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={selectableTimesPickup}
                    isSearchable={true}
                    value={selectPickupValue}
                    onChange={setSelectPickupValue}
                    placeholder={'Selecteer een ophaaltijd'}
                />
            </div>
            <div className="datetimepicker-row">
                <div>Retourdatum:</div>
                <DatePicker
                    locale={nl}
                    selected={endDate}
                    onChange={changeHandlerReturn}
                    dateFormat="dd-MM-yyyy"
                    plcaeholderText="Selecteer een retourtijd"
                    minDate={startDate}
                    withPortal
                />
            </div>
            <div className="datetimepicker-row">
                <div>Retourtijd:</div>
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={timeOptionsReturn}
                    isSearchable={true}
                    value={selectReturnValue}
                    onChange={setSelectReturnValue}
                    placeholder={'Selecteer een retourtijd'}
                />
            </div>

        </div>
    );
};

export default SplittedPicker;