import * as React from "react"
import ReactMarkDown from 'react-markdown'

const AdditionalInfo = (props) => {
    return (
        <div className="additional-information__background">
            <div className="container">
                <div className="additional-information">
                    {
                        props.blocks && props.blocks.map((item) => (
                            <div className="additional-information__content-wrapper" key={item.strapi_id}>
                                <div className="title">
                                    <h2>{item.title}</h2>
                                </div>
                                {item.description &&
                                    <div>
                                        <ReactMarkDown>{item.description.data.description}</ReactMarkDown>
                                    </div>
                                }
                            </div>
                        )
                        )
                    }
                    {
                        props.sharedBlocks && props.sharedBlocks.map((item) => (
                            <div className="additional-information__content-wrapper" key={item.strapi_id}>
                                <div className="title">
                                    <h2>{item.title}</h2>
                                </div>
                                {item.description &&
                                    <div>
                                        <ReactMarkDown>{item.description.data.description}</ReactMarkDown>
                                    </div>
                                }
                            </div>
                        )
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default AdditionalInfo