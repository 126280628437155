import React, { useState } from "react";
import ReactMarkDown from 'react-markdown';
import { Link } from "gatsby";
import { Rentvisie } from '@bakkie/ratality';
import Modal from 'react-bootstrap/Modal';
import Settings from "../settings.json";
import InfoIcon from "../../../src/images/svg/infoIcon.svg";
import PriceTable from "../shared/price-table";
import Skeleton from "../shared/skeletons";
import LpForm from "../reservation/lp-form";
import ImageCarousel from "./image-carousel";

declare global {
    interface Window {
        dataLayer: any;
    }
}

export const VehicleIntroduction = (props) => {
    return (
        <div className="container">
            <div>
                <div className="vehicle-select-headline">
                    <h1>{props.title}</h1>
                    <ReactMarkDown>{props.description}</ReactMarkDown>
                </div>
            </div>
        </div>
    )
}

const VehicleSelect = ({ category }) => {
    const [loading, setLoading] = useState(true)
    const [vehicleClasses, setVehicleClasses] = useState([])
    const [error, setError] = useState(undefined)
    const [show, setShow] = useState(null);
    const [showHover, setShowHover] = React.useState(false);
    const [pushedDataLayer, setPushedDataLayer] = useState(false)
    const [selectedBranch, setSelectedBranch] = useState<number>(1567)
    const [selectedLocation, setSelectedLocation] = useState<number>(5557)


    const handleClose = () => setShow(false);
    const handleShowModal = (id) => setShow(id);
    const filteredCategory = Settings.categories.find(({ name }) => name === category)
    const categoryId = filteredCategory?.id || undefined

    let includeVat = 'no'
    if (typeof window !== "undefined") {
        includeVat = localStorage.getItem('includeVat');
    }

    React.useEffect(() => {
        Rentvisie.setConfig({
            baseUrl: 'https://api.rentvisie.com/api',
            client: 'bertjonk',
            useGuestToken: true
        })

        Rentvisie.getVehicleClasses(categoryId, selectedBranch) // 1567 branch Purmerend
            .then(setVehicleClasses)
            .catch(setError)
            .finally(() => setLoading(false))
    }, [selectedBranch])
    if (loading) {

        return (
            <div className="container">
                <Skeleton length={5} />
            </div>
        )
    }
    if (error) {
        return (
            <div className="container">
                <p>{error.message}</p>
            </div>
        )
    }
    const dataLayer = window.dataLayer || [];

    if (!pushedDataLayer) {
        dataLayer.push({ ecommerce: null });
        dataLayer.push({
            'event': `view_item_list`,
            'ecommerce': {
                'items': [
                    vehicleClasses.map((item, index, data) => (
                        {
                            'item_list_name': `staticList_${category}`,
                            'item_name': `${item.description}`,
                            'item_id': `${item.code}`,
                            'price': `${item.marketingPrices[0].value}`,
                            'price_excl_vat': `${Math.round((item.marketingPrices[0].value / 1.21) * 100) / 100}`,
                            'item_category': `${category}`,
                            'list_length': `${data.length}`,
                            'quantity': 1
                        }
                    ))
                ],
            },
        });
        setPushedDataLayer(true)
    }

    const handleClick = (item) => {
        dataLayer.push({ ecommerce: null });
        dataLayer.push({
            'event': `select_item`,
            'ecommerce': {
                'items': [{
                    'item_list_name': `staticList_${category}`,
                    'item_name': `${item.description}`,
                    'item_id': `${item.code}`,
                    'item_daily_price_excl_vat': `${Math.round((item.marketingPrices[1].value / 1.21) * 100) / 100}`,
                    'price': `${item.marketingPrices[1].value}`,
                    'item_category': `${category}`,
                    'quantity': 1
                }],
            },
        });
    }

    // sort response
    //TODO change to marketingPrices
    const compare = (a, b) => {
        if (a.marketingPrices[0].value < b.marketingPrices[0].value) {
            return -1;
        }
        if (a.marketingPrices[0].value > b.marketingPrices[0].value) {
            return 1;
        }
        return 0;
    }

    const setterFunction = (city) => {
        // Move to Settings.JSON and Migrate to 1 functie (array.filter())
        const filteredArray = Settings.locations.filter(location => location.name === city.toLowerCase())
        setSelectedBranch(filteredArray[0].branchId)
        setSelectedLocation(filteredArray[0].value)
    }

    const handleShowIcon = (id) => setShowHover(id);
    const handleNotShowIcon = () => setShowHover(false);

    return (
        <div className='container'>
            <h2>Bekijk alle voertuigen</h2>
            <div className="select-location-wrapper">
                <div className={`select-location ${(selectedBranch === 1567) && 'select-location-active'}`} onClick={() => setterFunction('Purmerend')}>Purmerend</div>
                <div className={`select-location ${(selectedBranch === 1786) && 'select-location-active'}`} onClick={() => setterFunction('OudeMeer')}>Oude Meer</div>
                <div className={`select-location ${(selectedBranch === 1785) && 'select-location-active'}`} onClick={() => setterFunction('Zwaag')}>Zwaag</div>
            </div>
            {vehicleClasses.sort(compare).map(vehicleClass => (
                <div className="vehicle-wrapper" key={vehicleClass.code}>
                    <ImageCarousel images={vehicleClass.images} />
                    <div className="vehicle-select__content">
                        <h3 className="bold">
                            {
                                vehicleClass.labelMarkup
                                    ? vehicleClass.labelMarkup?.replace(/(<([^>]+)>)/gi, "")
                                    : vehicleClass.description
                            }
                        </h3>
                        <div dangerouslySetInnerHTML={{ __html: vehicleClass.descriptionMarkup }}></div>
                        <div className="features">
                            {vehicleClass.tags && vehicleClass.tags.slice(0, 3).map(feature => (
                                <div className="feature" key={feature.tagDescription}>
                                    <div className="feature__icon">o</div>
                                    <div className="feature__text">{feature.tagDescription}</div>
                                </div>
                            ))}
                        </div>
                        {/* <div className="vehicle-select__prices">
                            <p className="price-mileage__mileage"></p>
                            <div className="vehicle-select__price">
                                <p className="price-mileage__price" style={{textAlign:'right'}}>
                                    <img src={InfoIcon} alt="Info icon" onClick={() => handleShowIcon(vehicleClass.vehicleClassId)} width="32" height="32" style={{ paddingRight: 8 }} className='mouse'></img>
                                    Vanaf {'€ '} 
                                    {vehicleClass.marketingPrices
                                        ? (includeVat === 'yes' ? vehicleClass.marketingPrices[0].value.toFixed(2).toString().replace(".", ",") : (Math.round(vehicleClass.marketingPrices[0].value / 1.21)).toFixed(2).toString().replace(".", ","))
                                        : '0'}
                                    
                                    <span style={{fontSize:12, fontStyle:'italic'}}>{includeVat === 'yes' ? ' incl. BTW' : ' excl. BTW'}</span>
                                </p>
                            </div>
                        </div> */}
                        <div className="vehicle-select__buttons" style={{marginTop:12}}>
                            <Link to={`/${vehicleClass.subLabelMarkup?.replace(/(<([^>]+)>)/gi, "").toLowerCase().replaceAll(" ", "-")}?id=${vehicleClass.vehicleClassId}&branch=${selectedBranch}`} className="button-outline-primary" onClick={() => handleClick(vehicleClass)}><div>Meer info</div></Link>
                            <div><button className="button-primary" onClick={() => handleShowModal(vehicleClass.vehicleClassId)}>Huur direct</button></div>
                        </div>
                        {(show === vehicleClass.vehicleClassId) &&
                            <Modal show={true} dialogClassName='modal-90w' onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title></Modal.Title>
                                </Modal.Header>
                                <Modal.Body closeButton>
                                    <LpForm category={vehicleClass.category.categoryId} vehicleClassId={vehicleClass.vehicleClassId} branchId={selectedBranch} locationId={selectedLocation}></LpForm>
                                    <div className="cta-modal__selected-vehicle">
                                        <div>
                                            <h3>
                                                {
                                                    vehicleClass.labelMarkup
                                                        ? vehicleClass.labelMarkup?.replace(/(<([^>]+)>)/gi, "")
                                                        : vehicleClass.description
                                                }
                                            </h3>
                                            <span dangerouslySetInnerHTML={{ __html: vehicleClass.descriptionMarkup }}></span>
                                        </div>
                                        <img src={vehicleClass.images[0].imageUrl} alt={vehicleClass.images[0].fileName} className='img-fluid'></img>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        }
                        {(showHover === vehicleClass.vehicleClassId) &&
                            <Modal show={true} dialogClassName='modal-90w' onHide={handleNotShowIcon}>
                                <Modal.Header closeButton>
                                    <Modal.Title></Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ padding: 36 }}>
                                    <h3 className="bold" >
                                        Prijzen {' '}
                                        {
                                            vehicleClass.labelMarkup
                                                ? vehicleClass.labelMarkup?.replace(/(<([^>]+)>)/gi, "")
                                                : vehicleClass.description
                                        }
                                    </h3>
                                    <p>
                                        <span className="bold" style={{ paddingBottom: 24 }}>Let op:</span> de daadwerkelijke prijzen kunnen afwijken afhankelijk van gekozen locatie en/of periode.
                                    </p>
                                    <PriceTable vehicleClass={vehicleClass} />
                                </Modal.Body>
                            </Modal>
                        }
                    </div>
                </div>
            ))}
        </div>
    )
}

export default VehicleSelect