import * as React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Carousel from 'react-bootstrap/Carousel';
import ReviewImage from "../images/review_image.webp"

const CustomerExperience = ({ reviews }) => {

  return (
    <section>
      <div className="container">
        {<Carousel>
          {reviews && reviews.map((item) => (
            <Carousel.Item key={item.strapi_id}>
              <img
                className="d-block w-100 customer-experience"
                src={ReviewImage} // temp placeholder image
                alt={item.carouselImage.alternativeText}
                title={item.carouselImage.caption}
              />
              <Carousel.Caption className="customer-experience__content">
                <p>{item.description}</p>
                <div className="name">
                  {item.name}
                </div>
                <div className="stars">
                  {
                    [...Array(Math.round(item.rating / 2))].map((el, i) => (
                      i < 5 && (<div key={i}><FontAwesomeIcon icon="star" /></div>)
                    ))
                  }
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      }

      </div>
    </section>
  )
}

export default CustomerExperience