import SplittedPicker from "../../datetimerangepicker/SplittedPicker"
import { useState } from "react";

const CtaFormWispo = ({ productSelected }) => {
    const [pickupDate, setPickupDate] = useState(undefined);
    const [returnDate, setReturnDate] = useState(undefined);
    const [pickupTime, setPickupTime] = useState(undefined);
    const [returnTime, setReturnTime] = useState(undefined);
    const [vehicleClassId, setVehicleClassId] = useState();

    const handleChangeLocation = (e) => {
        switch (e.target.value) {
            case '5557':
                setVehicleClassId(productSelected.location.purmerend.vehicleClassId);
                return;
            case '5218':
                setVehicleClassId(productSelected.location.zwaag.vehicleClassId);
                return;
            case '5219':
                setVehicleClassId(productSelected.location.oudeMeer.vehicleClassId)
        }
    }

    return (
        <form action={`/wintersport/voertuig-kiezen`} method="get">
            <div className="wispo-cta-form__locations" id="wispo-locations" >
                <div>
                    <input type="radio" id={5557} name="location" value={5557} defaultChecked={true} onChange={handleChangeLocation}></input>
                    <label className="wispo-cta-form__location" htmlFor={5557}>
                        Purmerend
                    </label>
                </div>
                <div>
                    <input type="radio" id={5218} name="location" value={5218} defaultChecked={false} onChange={handleChangeLocation}></input>
                    <label className="wispo-cta-form__location" htmlFor={5218}>
                        Zwaag
                    </label>
                </div>
                <div>
                    <input type="radio" id={5219} name="location" value={5219} defaultChecked={false} onChange={handleChangeLocation}></input>
                    <label className="wispo-cta-form__location" htmlFor={5219}>
                        Oude Meer
                    </label>
                </div>
            </div>
            <div className='wispo-cta-form'>
                <SplittedPicker 
                    setReturnTime={setReturnTime}
                    setPickupTime={setPickupTime}
                    setReturnDate={setReturnDate}
                    setPickupDate={setPickupDate}
                />
                <input type="hidden" name="ophaaldatum" value={pickupDate && pickupDate.toLocaleDateString('nl-NL')}></input>
                <input type="hidden" name="retourdatum" value={returnDate && returnDate.toLocaleDateString('nl-NL')}></input>
                <input type="hidden" name="ophaaltijd" value={pickupTime && pickupTime.value}></input>
                <input type="hidden" name="retourtijd" value={returnTime && returnTime.value}></input>
                <input type="hidden" name="vehicleClassId" value={vehicleClassId}></input>
                <input type="hidden" name="categoryId" value={productSelected.rentvisieCategoryId}></input>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop:'3rem' }}>
                    <input type='submit' value='Beschikbaarheid Controleren' className="button button-primary" style={{ minWidth: ' 100%' }}></input>
                </div>
            </div>
        </form>
    )
}

export default CtaFormWispo