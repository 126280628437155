import React, { useEffect, useRef, useState } from "react";
import Contact from "../shared/contact";
import ReservationHeader from "../shared/reservation-header";
import { Rentvisie, Reservation } from "@bakkie/ratality";
import { navigate } from 'gatsby';
import { ValidatorUtil } from "../validator.util";
import Button from "react-bootstrap/Button";
import Spinner from "../../shared/spinner"
import SEO from "../../seo";
import FreeParking from "../shared/freeParking";
import { ReservationCheckoutProps } from '../../../interfaces/ReservationInterfaces'

declare global {
    interface Window {
        dataLayer: any;
    }
}

Rentvisie.setConfig({
    baseUrl: 'https://api.rentvisie.com/api',
    client: 'bertjonk',
    useGuestToken: true
})

const ReservationCheckout = ({ reservationData, query }: ReservationCheckoutProps) => {
    const reservationReference = reservationData.reservationReference;
    const bookingReference = reservationData.rentals[0].bookingReference;
    const category = reservationData.rentals[0].vehicleClass.category.categoryId;
    const { pickupLocation, pickupDate, pickupTime, dropOffDate, dropOffTime, vehicleClass } = reservationData.rentals[0];
    const kassaKoopje = vehicleClass.optionalOptions?.filter(option => option.category === "Kassakoopje") || [];
    const [recurringCustomer, setRecurringCustomer] = useState(undefined);
    const [populateData, setPopulateData] = useState(undefined);

    const [promocodeApplied, setPromocodeApplied] = useState(undefined);
    const [promocodeMessage, setPromocodeMessage] = useState(undefined);
    const applyPromotionCode = async (e) => {
        try {
            e.preventDefault();
            const code = await e.target[0].value;
            Rentvisie.postPromotionCode(reservationReference, code)
                .then((result) => {
                    if (result.success === true) {
                        // window.location.reload()
                        // do something
                        setPromocodeApplied(result.amount.value);
                        setPromocodeMessage('Korting toegepast')
                    } else {
                        setPromocodeApplied(0)
                        setPromocodeMessage('Onjuiste/verlopen kortingscode ')
                    }
                    console.error(result)
                }
                )
                .catch((error) => console.error(error))
        } catch (error) {
            console.error(error);
        }
    }

    let includeVat = 'no'
    let discount = ''
    if (typeof window !== "undefined") {
        includeVat = localStorage.getItem('includeVat');
        if (
            category === 542 || category === 543

        ) {
            // TODO: Add mapping for location
            const discountValue = localStorage.getItem('promocodeWispo');
            discount = `Wispo${discountValue}`
        }
        useEffect(() => {
            if (localStorage.getItem('storedCustomerData')) {
                setRecurringCustomer(JSON.parse(localStorage.getItem('storedCustomerData')));
            }
        }, [])
    }

    useEffect(() => {
        if (discount) {
            Rentvisie.postPromotionCode(reservationReference, discount)
                .then((result) => {
                    if (result.success === true) {
                        setPromocodeApplied(result.amount.value);
                        setPromocodeMessage('Korting toegepast')
                    } else {
                        setPromocodeApplied(0)
                        setPromocodeMessage('Onjuiste/verlopen kortingscode ')
                    }
                }
                )
                .catch((error) => console.error(error))
        }
    }, [])

    const rentalHasInsuranceOptions = ValidatorUtil.validateRentalInsuranceOptions(reservationData.rentals[0].insurances[0]);
    const rentalHasMileageOptions = ValidatorUtil.validateRentalMileageOptions(reservationData.rentals[0].mileagePackages[0]);
    const rentalHasOptionalOptions = ValidatorUtil.validateRentalOptionalOptions(reservationData.rentals[0].options);

    const insuranceOptionsData = [];
    if (rentalHasInsuranceOptions) {
        insuranceOptionsData.push(reservationData.rentals[0].insurances[0])
    }
    const mileagePackageOptionsData = [];
    if (rentalHasMileageOptions) {
        mileagePackageOptionsData.push(reservationData.rentals[0].mileagePackages[0])
    }
    const optionalOptionsData = reservationData.rentals[0].options;
    const [postalcodeData, setPostalcodeData] = useState(undefined)

    const [customerData, setCustomerData] = useState(
        {
            firstName: '',
            lastName: '',
            email: '',
            dateOfBirth: '',
        }
    )

    const [physicalAddress, setPhysicalAddress] = useState({
        houseNumber: '',
        houseNumberAdditional: '',
        streetName: '',
        city: '',
        postalCode: '',
        countryCode: 'NL'
    })

    const [cellphoneNumber, setcellphoneNumber] = useState({
        countryDialingCode: "31",
        number: ""
    })

    const [companyData, setCompanyData] = useState({
        name: ''
    })

    const [newCustomerData, setNewCustomerData] = useState({
        ...customerData,
        physicalAddress: {
            ...physicalAddress,
        },
        company: {
            ...companyData,
        },
        cellphoneNumber: {
            ...cellphoneNumber
        }
    })

    //Validations
    const hasValidBusinessName = ValidatorUtil.validateName(companyData.name) || (populateData && (populateData.company && ValidatorUtil.validateName(populateData.company.name)))
    const hasValidFirstName = ValidatorUtil.validateName(customerData.firstName) || (populateData && ValidatorUtil.validateName(populateData.firstName))
    const hasValidLastName = ValidatorUtil.validateName(customerData.lastName) || (populateData && ValidatorUtil.validateName(populateData.lastName))
    const hasValidPhone = ValidatorUtil.validatePhone(cellphoneNumber.number) || (populateData && ValidatorUtil.validatePhone(populateData.cellphoneNumber.number))
    const hasValidEmail = ValidatorUtil.validateEmail(customerData.email) || (populateData && ValidatorUtil.validateEmail(populateData.email))
    const hasValidZipCode = ValidatorUtil.validateZipCode(physicalAddress.postalCode) || (populateData && ValidatorUtil.validateZipCode(populateData.physicalAddress.postalCode))
    const hasValidHouseNumber = ValidatorUtil.validateHouseNumber(physicalAddress.houseNumber) || (populateData && ValidatorUtil.validateHouseNumber(populateData.physicalAddress.houseNumber))
    const hasValidAddition = ValidatorUtil.validateAddition(physicalAddress.houseNumberAdditional) || (populateData && ValidatorUtil.validateAddition(populateData.physicalAddress.houseNumberAdditional))
    const hasValidStreet = ValidatorUtil.validateStreet(physicalAddress.streetName) || (populateData && ValidatorUtil.validateStreet(populateData.physicalAddress.streetName))
    const isValidCustomer = ValidatorUtil.validateCustomer(hasValidFirstName, hasValidLastName, hasValidPhone, hasValidEmail, hasValidZipCode, hasValidHouseNumber, hasValidStreet);
    const hasValidKassakoopje = ValidatorUtil.validateKassakoopje(kassaKoopje)

    const [pushedDataLayer, setPushedDataLayer] = useState(false)
    const dataLayer = window.dataLayer || [];
    if (!pushedDataLayer) {
        dataLayer.push({ ecommerce: null });
        dataLayer.push({
            'event': `add_payent_information`,
            'ecommerce': {
                'currency': 'EUR',
                "value": `${reservationData.reservationTotal.value}`,
                "tax": `${Math.round((reservationData.reservationTotal.value * 0.21) * 100) / 100}`,
                'payment_type': 'Pay Later',
                'items':
                    [{
                        'item_name': `${reservationData.rentals[0].vehicleClass.description}`,
                        'item_id': `${reservationData.rentals[0].vehicleClass.vehicleClassId}`,
                        'price': `${reservationData.reservationTotal.value}`,
                        'price_excl_vat': `${Math.round((reservationData.reservationTotal.value / 1.21) * 100) / 100}`,
                        'currency': 'EUR',
                        'item_category': `${reservationData.rentals[0].vehicleClass.category.categoryId}`,
                        'quantity': 1,
                        'pick_up_location_id': `${reservationData.rentals[0].pickupLocation.locationId}`,
                        'pick_up_location': `${reservationData.rentals[0].pickupLocation.town}`,
                    }],
            },
        });
        setPushedDataLayer(true)
    }

    const handleDatalayer = () => {
        dataLayer.push({ ecommerce: null });
        dataLayer.push({
            'event': `purchase`,
            'ecommerce': {
                'items': [{
                    'item_name': `${reservationData.rentals[0].vehicleClass.description}`,
                    'item_id': `${reservationData.rentals[0].vehicleClass.vehicleClassId}`,
                    'price': `${reservationData.reservationTotal.value}`,
                    'price_excl_vat': `${Math.round((reservationData.reservationTotal.value / 1.21) * 100) / 100}`,
                    'currency': 'EUR',
                    'item_category': `${reservationData.rentals[0].vehicleClass.category.categoryId}`,
                    'quantity': 1,
                    'pick_up_location_id': `${reservationData.rentals[0].pickupLocation.locationId}`,
                    'pick_up_location': `${reservationData.rentals[0].pickupLocation.town}`,
                }],
                "value": `${reservationData.reservationTotal.value}`,
                "tax": `${Math.round((reservationData.reservationTotal.value * 0.21) * 100) / 100}`,
                "currency": "EUR",
                'transaction_id': `${reservationData.reservationReference}`,
            },
        });
    }

    if (typeof window !== "undefined") {
        useEffect(() => {
            const itemStr = localStorage.getItem('returnToCheckout')
            // if the item doesn't exist, return null
            if (!itemStr) {
                localStorage.removeItem('returnToCheckout')
            }
            const item = {
                reservationReference: reservationReference,
                expiry: reservationData.expiryDate,
            }
            localStorage.setItem('returnToCheckout', JSON.stringify(item))
        }, []);
    }

    const customerDataChanged = useRef(false)

    const handleChange = (e) => {
        setCustomerData({
            ...customerData,
            [e.target.name]: e.target.value
        })
    }

    const handleChangeCompany = (e) => {
        setCompanyData({
            [e.target.name]: e.target.value
        })
    }

    const handleChangePhysicalAddress = (e) => {
        setPhysicalAddress({
            ...physicalAddress,
            [e.target.name]: e.target.value
        })
        customerDataChanged.current = !customerDataChanged.current
    }

    const handleChangePhone = (e) => {
        setcellphoneNumber({
            ...cellphoneNumber,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        setNewCustomerData({
            ...customerData,
            physicalAddress: {
                ...physicalAddress,
            },
            company: {
                ...companyData,
            },
            cellphoneNumber: {
                ...cellphoneNumber
            }
        })
    }, [customerData, physicalAddress, companyData, cellphoneNumber])

    useEffect(() => {
        if (physicalAddress.postalCode !== '' && physicalAddress.houseNumber !== '') { // Prevent running and infinite loop
            const isValidQuery = hasValidHouseNumber && hasValidZipCode;
            if (isValidQuery) {
                //inputAddress into PostalCode API
                const addressQuery = {
                    "country": "NL",
                    "houseNumber": physicalAddress.houseNumber,
                    "zipCode": physicalAddress.postalCode,
                }

                Rentvisie.getAddress(addressQuery)
                    .then(
                        (data) => {
                            setPhysicalAddress({
                                ...physicalAddress,
                                streetName: data.street,
                            })
                            setPostalcodeData(data)
                        }
                    )
                    .catch(error => {
                        console.error(error)
                    });
            }
        }
    }, [customerDataChanged.current]) // Only run on customerDataChanged

    const [isCompany, setIsCompany] = useState(false)
    const customerType = (e) => {
        if (e.target.value == 'company') {
            setIsCompany(true)
        } else {
            setIsCompany(false)
        }
    }

    const [isCheckoutDeal, setIsCheckoutDeal] = useState(undefined)
    const [checkoutValue, setCheckoutValue] = useState(0)
    const handleCheckoutDeal = (e) => {
        if (e.target.checked) {
            setIsCheckoutDeal(kassaKoopje[0].optionId)
            setCheckoutValue(e.target.value)
        } else {
            setIsCheckoutDeal(undefined)
            setCheckoutValue(0)
        }
    }
    const totalDays = reservationData.rentals[0].days
    // Update Price
    const getPrice = (basePrice, checkOutValue): number => {
        return Math.round((parseFloat(basePrice) + parseFloat(checkOutValue)) * 100) / 100;
    }
    const getPriceOption = (price, type) => {
        if (type === 'PerDay') {
            return price * totalDays
        }
        return price
    }


    // Update Reservation && Redirect Booking to Thank You Page
    const updateReservation = async (newCustomerData, isCheckoutDeal) => {
        try {
            await setShowSpinner(true)
            await Rentvisie.addCustomerData(reservationReference, bookingReference, newCustomerData)
            await Rentvisie.confirmationEmail(reservationReference, bookingReference)
            handleDatalayer()
            if (isCheckoutDeal) {
                Rentvisie.addOptionalOption(reservationReference, bookingReference, isCheckoutDeal)
            }
            const url = '/huren/bedankt'
            navigate(url)

            if (typeof window !== "undefined") {
                localStorage.setItem('storedCustomerData', JSON.stringify(newCustomerData))
            }
        }
        catch (e) {
            console.error(e)
        }
    }

    const expiryDate = new Date(reservationData.expiryDate).getTime()
    const calculateTimeLeft = () => {
        const now = new Date().getTime()
        const difference = expiryDate - now;
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                minuten: Math.floor((difference / 1000 / 60) % 60),
                seconden: Math.floor((difference / 1000) % 60)
            };
        }
        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });

    const redirect = () => {
        window.location.replace('/')
    }

    const [showSpinner, setShowSpinner] = useState(false)

    // Update State for Populate All functions -- to be moved to separate file 
    const populateForm = () => {
        setPopulateData(recurringCustomer)

        // update customerData too
        setCustomerData({
            firstName: recurringCustomer.firstName,
            lastName: recurringCustomer.lastName,
            email: recurringCustomer.email,
            dateOfBirth: recurringCustomer.dateOfBirth,
        })

        if (recurringCustomer.company) {
            setCompanyData({
                name: recurringCustomer.company.name
            })
        }

        setPhysicalAddress({
            houseNumber: recurringCustomer.physicalAddress.houseNumber,
            houseNumberAdditional: recurringCustomer.physicalAddress.houseNumberAdditional,
            streetName: recurringCustomer.physicalAddress.streetName,
            city: '',
            postalCode: recurringCustomer.physicalAddress.postalCode,
            countryCode: 'NL'
        })

        setcellphoneNumber({
            countryDialingCode: recurringCustomer.cellphoneNumber.countryDialingCode,
            number: recurringCustomer.cellphoneNumber.number,
        })
    }


    return (
        <div>
            <SEO title='Bert Jonk Autoverhuur - Checkout' index={false} follow={true} />
            < ReservationHeader activeStepIndex={3} />
            {!showSpinner
                ? <div className="container">
                    <div className="reservation-select-wrapper my-l">
                        <div className="reservation-select-left">
                            <div className="checkout-wrapper">
                                <div className="reservation__title">
                                    {
                                        recurringCustomer
                                            ? (
                                                <><h2>Maak jouw reservering compleet</h2>
                                                    <h3>Terugkerende klant? Vul dan het formulier in met een druk op de knop</h3>
                                                    <button className="button-outline-primary" onClick={populateForm}> Gegevens Invullen </button>
                                                </>)
                                            : (<><h2>Maak jouw reservering compleet</h2><p style={{ fontStyle: 'italic' }}><span className="bold" >Let op:</span> U heeft nog {(timeLeft.minuten + timeLeft.seconden) > 0 ? (`${timeLeft.minuten} minuten en ${timeLeft.seconden} seconden`) : redirect()} om de reservering te voltooien. Daarna dient u het reserveringsproces opnieuw te doorlopen.</p></>)
                                    }
                                </div>
                                <div onChange={customerType}>
                                    <input type="radio" id="individual" value="individual" name="customerType" defaultChecked />
                                    <label className="" htmlFor="individual" style={{ paddingRight: 20, paddingLeft: 5 }}> Particulier </label>
                                    <input type="radio" id="company" value="company" name="customerType" />
                                    <label className="" htmlFor="company" style={{ paddingRight: 20, paddingLeft: 5 }}> Bedrijf </label>
                                </div>
                                {isCompany &&
                                    <div className="form-row">
                                        <div className="form-100">
                                            <div className={`textOnInput ${hasValidBusinessName && "valid"} ${(companyData.name && !hasValidBusinessName) && "not-valid"}`}>
                                                <label htmlFor="name">Bedrijfsnaam</label>
                                                <input type="text" name="name" onChange={handleChangeCompany} className="form-input-alt"
                                                    defaultValue={
                                                        populateData && (populateData.company && `${populateData.company.name}`)
                                                    }
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="form-row">
                                    <div className="form-50">
                                        <div className={`textOnInput ${hasValidFirstName && "valid"} ${(customerData.firstName && !hasValidFirstName) && "not-valid"}`}>
                                            <label htmlFor="firstName">Voornaam</label>
                                            <input
                                                type="text"
                                                name="firstName"
                                                onChange={handleChange}
                                                defaultValue={populateData && `${populateData.firstName}`}
                                                className="form-input-alt"
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="form-50">
                                        <div className={`textOnInput ${hasValidLastName && "valid"} ${(customerData.lastName && !hasValidLastName) && "not-valid"}`}>
                                            <label htmlFor="lastName">Achternaam</label>
                                            <input
                                                type="text"
                                                name="lastName"
                                                onChange={handleChange}
                                                defaultValue={populateData && `${populateData.lastName}`}
                                                className="form-input-alt"></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-33">
                                        <div className={`
                                            textOnInput 
                                            ${(hasValidZipCode && (hasValidStreet || physicalAddress.streetName == '')) && "valid"} 
                                            ${(customerData.postalCode && (!hasValidZipCode || physicalAddress.streetName == null)) && "not-valid"}
                                            `}>
                                            <label htmlFor="postalCode">Postcode</label>
                                            <input
                                                type="text"
                                                name="postalCode"
                                                className="form-input-alt"
                                                id="postalCode"
                                                maxLength={6}
                                                onChange={handleChangePhysicalAddress}
                                                defaultValue={populateData && `${populateData.physicalAddress.postalCode}`}
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="form-33">
                                        <div className={`
                                            textOnInput 
                                            ${(hasValidHouseNumber && (hasValidStreet || physicalAddress.streetName == '')) && "valid"} 
                                            ${(customerData.houseNumber && (!hasValidHouseNumber || physicalAddress.streetName == null)) && "not-valid"}
                                            `}>
                                            <label htmlFor="houseNumber">Huisnummer</label>
                                            <input type="text" name="houseNumber" className="form-input-alt" id="houseNumber" maxLength={6} onBlur={handleChangePhysicalAddress}
                                                defaultValue={populateData && `${populateData.physicalAddress.houseNumber}`}
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="form-33">
                                        <div className={`textOnInput ${hasValidAddition && "valid"} ${(customerData.houseNumberAdditional && !hasValidAddition) && "not-valid"}`}>
                                            <label htmlFor="houseNumberAdditional">Toevoeging</label>
                                            <input
                                                type="text"
                                                name="houseNumberAdditional"
                                                onChange={handleChangePhysicalAddress}
                                                className="form-input-alt"
                                                defaultValue={populateData && `${populateData.physicalAddress.houseNumberAdditional}`}

                                            ></input>
                                        </div>
                                    </div>
                                </div>
                                {(postalcodeData || populateData) &&
                                    <div className="form-row">
                                        <div className="form-100">
                                            <div className={`textOnInput ${hasValidStreet && "valid"} ${((physicalAddress.streetName == null && (populateData && populateData.physicalAddress.streetName == null)) || !hasValidStreet) && "not-valid"}`}>
                                                <label htmlFor="streetName">Straat</label>
                                                <input
                                                    type="text"
                                                    className="form-input-alt"
                                                    name="streetName"
                                                    value={
                                                        postalcodeData
                                                            ? `${postalcodeData.street}, ${postalcodeData.city}`
                                                            : populateData
                                                                ? `${populateData.physicalAddress.streetName}`
                                                                : ''
                                                    }
                                                    placeholder="Controleer postcode & huisnummer" disabled></input>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="form-row">
                                    <div className="form-100">
                                        <div className={`textOnInput ${hasValidPhone && "valid"} ${(cellphoneNumber.number && !hasValidPhone) && "not-valid"}`}>
                                            <label htmlFor="number">Telefoon</label>
                                            <input type="phone" name="number" onChange={handleChangePhone} className="form-input-alt"
                                                defaultValue={populateData && `${populateData.cellphoneNumber.number}`}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-100">
                                        <div className={`textOnInput ${hasValidEmail && "valid"} ${(customerData.email && !hasValidEmail) && "not-valid"}`}>
                                            <label htmlFor="email">Email</label>
                                            <input type="email" name="email" onChange={handleChange} className={'form-input-alt'}
                                                defaultValue={populateData && `${populateData.email}`}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                                {
                                    isValidCustomer &&
                                    <>
                                        {hasValidKassakoopje &&
                                            <div className="checkout-deal-wrapper">
                                                <div className='checkout-extra'>
                                                    <h2>Kassakoopje!</h2>
                                                    <input
                                                        type="checkbox"
                                                        id='kassakoopje'
                                                        name="kassakoopje"
                                                        value={kassaKoopje[0].price.value}
                                                        onChange={handleCheckoutDeal}
                                                    />
                                                    <label htmlFor='kassakoopje'>
                                                        <div className="extrabox">
                                                            <h3>{kassaKoopje[0].description} (€ {((kassaKoopje[0].price.value * 100) / 100).toFixed(2)})</h3>
                                                            <span dangerouslySetInnerHTML={{ __html: kassaKoopje[0].labelMarkup }} />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        }
                                        {/* TODO: Add Payment */}
                                        {/* <div className="payment"><h2>Betaling</h2></div> */}
                                        <div className="shopping-cart" style={{ marginTop: 20 }}>
                                            <h2>Winkelmandje</h2>
                                            <span style={{ fontSize: 10 }}>Aantal dag(en): {totalDays}</span>
                                            <div className="shopping-cart-wrapper">
                                                <div className="shopping-cart-category">
                                                    <span className="bold">Voertuig</span>
                                                    <hr></hr>
                                                    <div className="shopping-cart-row">
                                                        <div className="shopping-cart-left">
                                                            {reservationData.rentals[0].vehicleClass.labelMarkup
                                                                ? reservationData.rentals[0].vehicleClass.labelMarkup?.replace(/(<([^>]+)>)/gi, "")
                                                                : reservationData.rentals[0].vehicleClass.description
                                                            }
                                                        </div>
                                                        <div className="shopping-cart-right">
                                                            {isCompany ? `€ ${(reservationData.rentals[0].price.value * totalDays / 1.21).toFixed(2)}` : `€ ${((reservationData.rentals[0].price.value * totalDays * 100) / 100).toFixed(2)}`}
                                                        </div>
                                                    </div>
                                                </div>
                                                {rentalHasInsuranceOptions &&
                                                    <div className="shopping-cart-category">
                                                        <span className="bold">Verzekering & Eigen Risico</span>
                                                        <hr></hr>
                                                        <div className="shopping-cart-row">
                                                            <div className="shopping-cart-left">
                                                                {insuranceOptionsData[0].description}
                                                            </div>
                                                            <div className="shopping-cart-right">
                                                                {isCompany ? `€ ${(getPriceOption(insuranceOptionsData[0].price.value, insuranceOptionsData[0].price.unitType) / 1.21).toFixed(2)}` : `€ ${((getPriceOption(insuranceOptionsData[0].price.value, insuranceOptionsData[0].price.unitType) * 100) / 100).toFixed(2)}`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {rentalHasMileageOptions &&
                                                    <div className="shopping-cart-category">
                                                        <span className="bold">Kilometers</span>
                                                        <hr></hr>
                                                        <div className="shopping-cart-row">
                                                            <div className="shopping-cart-left">
                                                                {mileagePackageOptionsData[0].description}
                                                            </div>
                                                            <div className="shopping-cart-right">
                                                                {isCompany ? `€ ${(getPriceOption(mileagePackageOptionsData[0].price.value, mileagePackageOptionsData[0].price.unitType) / 1.21).toFixed(2)}` : `€ ${((getPriceOption(mileagePackageOptionsData[0].price.value, mileagePackageOptionsData[0].price.unitType) * 100) / 100).toFixed(2)}`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {rentalHasOptionalOptions &&
                                                    <div className="shopping-cart-category">
                                                        <span className="bold">Overige</span>
                                                        <hr></hr>
                                                        {optionalOptionsData.map((option) => (
                                                            <div key={option.optionId} className="shopping-cart-row">
                                                                <div className="shopping-cart-left">
                                                                    {option.description}
                                                                </div>
                                                                <div className="shopping-cart-right">
                                                                    {isCompany ? `€ ${(getPriceOption(option.price.value, option.price.unitType) / 1.21).toFixed(2)}` : `€ ${((getPriceOption(option.price.value, option.price.unitType) * 100) / 100).toFixed(2)}`}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                                {/* TODO: CHECK FOR MULTIPLE DAYS */}
                                                {isCheckoutDeal &&
                                                    <div className="shopping-cart-category">
                                                        <span className="bold">Kassakoopje!</span>
                                                        <hr></hr>
                                                        <div className="shopping-cart-row">
                                                            <div className="shopping-cart-left">
                                                                {kassaKoopje[0].description}
                                                            </div>
                                                            <div className="shopping-cart-right">
                                                                {isCompany ? `€ ${(kassaKoopje[0].price.value / 1.21).toFixed(2)}` : `€ ${((kassaKoopje[0].price.value * 100) / 100).toFixed(2)}`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="shopping-cart-category">
                                                    <span className="bold">Totaal</span>
                                                    <hr></hr>
                                                    <div className="shopping-cart-row">
                                                        <div className="shopping-cart-left">
                                                            Totaal (excl.)
                                                        </div>
                                                        <div className="shopping-cart-right">
                                                            {
                                                                promocodeApplied > 0
                                                                    ? (
                                                                        <span>€ {' '} {((promocodeApplied) / 1.21).toFixed(2).toString().replace(".", ",")}</span>
                                                                    )
                                                                    : `€ ${' '} ${((reservationData.reservationTotal.value) / 1.21).toFixed(2).toString().replace(".", ",")}`
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="shopping-cart-row">
                                                        <div className="shopping-cart-left">
                                                            BTW
                                                        </div>
                                                        <div className="shopping-cart-right">
                                                            {promocodeApplied > 0
                                                                ? (
                                                                    <span>€ {' '} {((promocodeApplied / (1.21)) * 0.21).toFixed(2).toString().replace(".", ",")}</span>
                                                                )
                                                                : `€ ${' '} ${((reservationData.reservationTotal.value / (1.21)) * 0.21).toFixed(2).toString().replace(".", ",")}`

                                                            }
                                                        </div>
                                                    </div>
                                                    {!isCompany &&
                                                        <div className="shopping-cart-row bold">
                                                            <div className="shopping-cart-left">
                                                                Totaal (incl.)
                                                            </div>
                                                            <div className="shopping-cart-right">
                                                                {promocodeApplied > 0
                                                                    ? (
                                                                        <span style={{ marginLeft: 4 }}>€ {' '} {promocodeApplied.toFixed(2).toString().replace(".", ",")}</span>
                                                                    )
                                                                    : `€ ${' '} ${reservationData.reservationTotal.value.toFixed(2).toString().replace(".", ",")}`
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div style={{ display: 'flex' }}>
                                    {
                                        isValidCustomer
                                            ? <button className="button-primary" onClick={() => updateReservation(newCustomerData, isCheckoutDeal)} style={{ marginLeft: 'auto' }}>Reserveren</button>
                                            : <Button className='btn btn-secondary btn-lg' style={{ marginLeft: 'auto' }} disabled>Vul de gevraagde gegevens in</Button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="reservation-select-right">
                            <div className="reservation-contact">
                                <div className="reservation-product__photo">
                                    <img
                                        src={vehicleClass.images[0]?.imageUrl}
                                        className='img-fluid rounded'
                                        alt={vehicleClass.images[0]?.fileName}
                                        title={vehicleClass.description}
                                    />
                                </div>
                                <div className="reservation-product__text">
                                    <p>
                                        {pickupLocation.town} <br></br>
                                        <a
                                            href={encodeURI(`https://www.google.com/maps/search/?api=1&query=${pickupLocation.streetAddress}`)}
                                            target="_blank"
                                        >
                                            {pickupLocation.streetAddress}
                                        </a>
                                    </p>
                                    <p>
                                        Van: {pickupDate} om {pickupTime} <br></br>
                                        Tot: {dropOffDate} om {dropOffTime} <br></br>
                                        ({totalDays} dagen)
                                    </p>
                                    <h3 className="bold">
                                        {reservationData.rentals[0].vehicleClass.labelMarkup
                                            ? reservationData.rentals[0].vehicleClass.labelMarkup?.replace(/(<([^>]+)>)/gi, "")
                                            : reservationData.rentals[0].vehicleClass.description
                                        }
                                    </h3>
                                    <p className="small-top">
                                        Nu voor slechts:
                                    </p>
                                    <h3 className="extrabox__price">
                                        {
                                            (includeVat === 'yes')
                                                ? promocodeApplied
                                                    ? <span>€ {' '} {promocodeApplied.toFixed(2).toString().replace(".", ",")}</span>
                                                    : `€ ${getPrice(reservationData.reservationTotal.value, checkoutValue)} incl. BTW`
                                                : promocodeApplied
                                                    ? <span>€ {' '} {((promocodeApplied / (1.21))).toFixed(2).toString().replace(".", ",")}</span>
                                                    : `€ ${(getPrice(reservationData.reservationTotal.value, checkoutValue) / 1.21).toFixed(2)} excl. BTW`
                                        }
                                    </h3>
                                    <p className="small-top" style={{ margin: 0 }}>
                                        Incl. Administratiekosten ( € 1,75 )
                                    </p>
                                    {
                                        isValidCustomer
                                            ? <button className="button-primary" onClick={() => updateReservation(newCustomerData, isCheckoutDeal)} style={{ marginLeft: 'auto' }}>Reserveren</button>
                                            : <Button className='btn btn-secondary btn-lg' style={{ marginLeft: 'auto' }} disabled>Vul de gevraagde gegevens in</Button>
                                    }
                                    {!promocodeApplied &&
                                        <form className='promotion-code' onSubmit={applyPromotionCode} style={{ display: 'flex', gap: 12, justifyContent: 'space-between', alignItems: 'center', marginTop: 24 }}>
                                            <input type='text' className='form-input' placeholder='promocode'></input>
                                            <button className="button button-primary">Toepassen</button>
                                        </form>
                                    }
                                    {promocodeMessage && <p className="small">{promocodeMessage}</p>}
                                </div>
                            </div>
                            <Contact />
                            <FreeParking />
                        </div>
                    </div>
                </div>
                : <div className="spinner-background">
                    <Spinner />
                </div>
            }
        </div>
    )
}

export default ReservationCheckout