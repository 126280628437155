import { useStaticQuery, graphql } from "gatsby";
import { useState } from "react";
import { Container } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion'
import Layout from "../components/layout";
import ReactMarkdown from "react-markdown";
import SEO from "../components/seo";

const Mostaskedquestions = () => {
  
  // staticQueryImport
  const query = useStaticQuery(graphql`
    {
      strapiMostaskedquestion {
        basicSeo {
          seoMetadescription
          seoTitle
        }
        title
        faqs {
          answer {
            data {
              answer
            }
          }
          strapi_id
          tag
          question
        }
      }
    }
  `)
  const data = query.strapiMostaskedquestion;

  const [activeFaqs, setActiveFaqs] = useState([]);
  const allTags = data.faqs.map(faq => faq.tag)

  const uniqueTags = allTags.filter((element, index) => {
    return allTags.indexOf(element) === index;
  });

  let filteredArray = [];
  const filterFunction = (tag) => {
    filteredArray = data.faqs.filter(faq => faq.tag === tag);
    setActiveFaqs(filteredArray);
  }

  return (
    <Layout>
      <SEO title={data.basicSeo.seoTitle} description={data.basicSeo.seoMetadescription} follow={true} index={true} />
      <Container style={{minHeight:'80vh'}}>
        <h1>Veelgestelde vragen</h1>

        <div className="faq__filter-button-wrapper">
          {uniqueTags.map((tag) => (
            <div className={`faq__filter-button ${
              (activeFaqs.length > 0) && ((activeFaqs[0].tag === tag) && 'active')
            }`} onClick={() => { filterFunction(tag) }}>{tag}</div>
          ))}
          {<div className={`faq__filter-button ${
            (activeFaqs.length === 0) && 'active'
          }`} onClick={() => {setActiveFaqs([])}}>alle</div>}
        </div>

        <div className="faq__content-wrapper">
          {
            (activeFaqs.length > 0) 
            ? activeFaqs.map((item) => (
              <div key={item.strapi_id}>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey={item.strapi_id}>
                    <Accordion.Header as="div">{item.question}</Accordion.Header>
                    <Accordion.Body>
                      <ReactMarkdown>{item.answer.data.answer}</ReactMarkdown>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>))
            : data.faqs.map((item) => (
              <div key={item.strapi_id}>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey={item.strapi_id}>
                    <Accordion.Header as="div">{item.question}</Accordion.Header>
                    <Accordion.Body>
                      <ReactMarkdown>{item.answer.data.answer}</ReactMarkdown>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>))
          }
        </div>
      </Container>
    </Layout>

  );
}

export default Mostaskedquestions;