import React, { useState } from "react"
import { Container } from "react-bootstrap"
import ReservationHeader from "../shared/reservation-header"
import { Link, navigate } from "gatsby"
import { locations } from "../../settings.json"
import SEO from "../../seo"
import Settings from "../../settings.json"
import Modal from "react-bootstrap/Modal"
import QuoteForm from "../../forms/quote"
import Spinner from "../../shared/spinner"
import { compare, selectItem } from "../../../hooks/ReservationFunctions"
import ImageCarousel from "../../landing-pages/image-carousel"

// TODO: Add wispo pakket to default price
const getPrice = (rental, discount) => {
  const includedInsurance = rental.vehicleClass.insuranceOptions.find(option =>
    option.description.toLowerCase().includes("verlaagd")
  )
  const includedPackage = rental.vehicleClass.optionalOptions.find(option =>
    option.description.toLowerCase().includes("wintersportpakket")
  )
  const includedMileage = rental.vehicleClass.mileagePackageOptions.find(
    option => option.description.toLowerCase().includes("2100km")
  )
  const basePrice = rental.totalPrice.value

  if (includedPackage && includedInsurance && includedMileage) {
    return (
      basePrice * ((100 - discount) / 100) +
      includedInsurance.price.value * rental.days +
      includedPackage.price.value * rental.days +
      includedMileage.price.value * rental.days
    )
  } else if (includedInsurance && includedMileage) {
    return (
      basePrice * ((100 - discount) / 100) +
      includedInsurance.price.value * rental.days +
      includedMileage.price.value * rental.days
    )
  } else if (includedInsurance) {
    return (
      basePrice * ((100 - discount) / 100) +
      includedInsurance.price.value * rental.days
    )
  }

  return basePrice * ((100 - discount) / 100)
}

const ReservationSelectVehicle = ({ availableRentals, searchArgs, props }) => {
  const [pushedDataLayer, setPushedDataLayer] = useState(false)
  const [removedClassId, setRemovedClassId] = useState(false)
  const [tryOtherLocations, setTryOtherLocations] = useState(false)
  const [show, setShow] = useState(undefined)
  const [showSpinner, setShowSpinner] = useState(false)
  const handleClose = () => setShow(false)

  const dataLayer = window.dataLayer || []

  if (!pushedDataLayer) {
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: `view_item_list`,
      ecommerce: {
        items: [
          availableRentals.map((item, index, data) => ({
            item_list_name: `Reservering Stap 1 - ${item.vehicleClass.category.description}`,
            item_name: `${item.vehicleClass.description}`,
            item_id: `${item.vehicleClass.vehicleClassId}`,
            item_availability: `${item.available}`,
            price: `${item.price.value}`,
            price_excl_vat: `${parseFloat(
              (item.price.value / 1.21).toFixed(2)
            )}`,
            currency: "EUR",
            item_category: `${item.vehicleClass.category.categoryId}`,
            list_length: `${data.length}`,
            quantity: 1,
            pick_up_location_id: `${item.pickupLocation.locationId}`,
            pick_up_location: `${item.pickupLocation.town}`,
          })),
        ],
      },
    })
    setPushedDataLayer(true)
  }

  let category = searchArgs.categoryId
  let pickupLocation = searchArgs.pickupLocationId
  let includeVat = "no"
  let discount = 0
  if (typeof window !== "undefined") {
    includeVat = localStorage.getItem("includeVat")
    if (category === "542" || category === "543") {
      discount = localStorage.getItem("promocodeWispo")
    }
  }

  if (availableRentals.length > 0) {
    category = availableRentals[0].vehicleClass.category.categoryId
    pickupLocation = availableRentals[0].pickupLocation
  }

  let parseLocation = {
    value: pickupLocation.locationId,
    label: pickupLocation.town,
  }

  const searchParams = new URLSearchParams(props.location.search)
  if (availableRentals.length === 0) {
    parseLocation.value = searchArgs.pickupLocationId

    locations.map(loc => {
      if (loc.value === Number(parseLocation.value)) {
        parseLocation.label = loc.label
      }
    })
    if (searchParams.get("vehicleClassId") !== "") {
      searchParams.set("vehicleClassId", "")
      navigate(`/wintersport/voertuig-kiezen?${searchParams.toString()}`)
      setTimeout(function () {
        setRemovedClassId(true)
      }, 100)
      setTimeout(function () {
        window.location.reload()
      }, 3000)
    }

    if (searchParams.get("vehicleClassId") === "") {
      setTimeout(function () {
        setTryOtherLocations(true)
      }, 100)
    }
  }

  const updateLocation = locationId => {
    searchParams.set("location", locationId)
    navigate(`/huren/voertuig-kiezen?${searchParams.toString()}`)
    setTimeout(function () {
      window.location.reload()
    }, 500)
  }

  const nextStep = availableRental => {
    if (
      Settings.quoteOnly.includes(availableRental.vehicleClass.vehicleClassId)
    ) {
      setShow(availableRental.vehicleClass.vehicleClassId)
    } else {
      navigate(`/wintersport/accessoires-kiezen?id=${availableRental.rentalId}`)
      setShowSpinner(true)
    }
  }

  return (
    <div>
      <SEO
        title="Bert Jonk Autoverhuur - Wintersport voertuig kiezen"
        index={false}
        follow={true}
      />
      <ReservationHeader activeStepIndex={1} />
      <Container fluid="xxl">
        {!showSpinner ? (
          <React.Fragment>
            {/* TODO: Replace by Wispo CTA Form */}
            <div className="reservation__title">
              <h2>Online Beschikbare Voertuigen</h2>
            </div>
            <Link to="/aanbiedingen/wintersport" style={{ fontSize: 12 }}>
              {"<< "}Ander voertuig uitkiezen
            </Link>
            <div className="reservation-select-wrapper">
              <div>
                {availableRentals.length === 0 && (
                  <div className="hero-form__warning">
                    <div> (!) </div>
                    <p>
                      Zoekopdracht heeft helaas geen resultaten opgeleverd. Geen
                      zorgen: wij kunnen u wellicht toch aan het gewenste
                      voertuig helpen!
                    </p>
                    <div></div>
                  </div>
                )}

                {removedClassId && (
                  <div style={{ margin: "96px 24px", textAlign: "center" }}>
                    <h2>
                      Wij zoeken nu naar een alternatief bij u in de
                      buurt...ogenblik geduld
                    </h2>
                  </div>
                )}

                {tryOtherLocations && (
                  <div style={{ marginTop: 20 }}>
                    <h2>Neem contact met ons op</h2>
                    <p>
                      Bel ons op: <a href="tel:0883745900">088 - 374 59 00</a>{" "}
                      zodat wij een voertuig voor u kunnen inhuren of een
                      passend alternatief aan kunnen bieden.
                    </p>
                    <p>
                      U kunt het ook op één van onze andere locaties proberen
                    </p>
                    <div className="select-location-wrapper">
                      {pickupLocation !== "5557" && (
                        <div
                          className="select-location"
                          onClick={() => {
                            updateLocation("5557")
                          }}
                        >
                          Purmerend
                        </div>
                      )}
                      {pickupLocation !== "5218" && (
                        <div
                          className="select-location"
                          onClick={() => updateLocation("5218")}
                        >
                          Zwaag
                        </div>
                      )}
                      {pickupLocation !== "5219" && (
                        <div
                          className="select-location"
                          onClick={() => {
                            updateLocation("5219")
                          }}
                        >
                          Oude Meer
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {availableRentals.sort(compare).map(
                  availableRental =>
                    availableRental.totalPrice.value >= 1 && (
                      <div
                        className="vehicle-wrapper"
                        key={availableRental.vehicleClass.vehicleClassId}
                      >
                        {availableRental.vehicleClass.images.length > 0 && (
                          <ImageCarousel
                            images={availableRental.vehicleClass.images}
                          />
                        )}
                        <div className="vehicle-select__content">
                          <h3 className="bold">
                            {availableRental.vehicleClass.labelMarkup
                              ? availableRental.vehicleClass.labelMarkup.replace(
                                  /(<([^>]+)>)/gi,
                                  ""
                                )
                              : availableRental.vehicleClass.description}
                          </h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                availableRental.vehicleClass.descriptionMarkup,
                            }}
                          ></div>
                          <p>
                            U krijgt {availableRental.includedMileage + (150 * availableRental.days)} vrije km. Genoeg om heen en weer naar
                            Oostenrijk, Frankrijk of Italie te rijden. <br></br>{" "}
                          </p>

                          <div className="features">
                            {/* no included mileage returns 9999, or 1 */}
                            <div className="feature">
                              <div className="feature__icon">o</div>
                              <div className="feature__text">Winterbanden</div>
                            </div>
                            <div className="feature">
                              <div className="feature__icon">o</div>
                              <div className="feature__text">
                                Sneeuwkettingen
                              </div>
                            </div>
                            <div className="feature">
                              <div className="feature__icon">o</div>
                              <div className="feature__text">
                                Autohulp- & Inzittendeverzekering
                              </div>
                            </div>
                            {availableRental.vehicleClass.tags &&
                              availableRental.vehicleClass.tags
                                .slice(0, 3)
                                .map(feature => (
                                  <div
                                    className="feature"
                                    key={feature.tagDescription}
                                  >
                                    <div className="feature__icon">o</div>
                                    <div className="feature__text">
                                      {feature.tagDescription}
                                    </div>
                                  </div>
                                ))}
                          </div>
                          <div className="vehicle-select__prices">
                            <p className="bold">Totaal:</p>
                            <div className="vehicle-select__price">
                              <p className="price-mileage__price">
                                {includeVat === "yes" ? (
                                  discount ? (
                                    <>
                                      {}
                                      <span
                                        style={{
                                          textDecoration: "line-through",
                                          fontSize: 10,
                                          marginRight: 5,
                                        }}
                                      >{`€ ${getPrice(
                                        availableRental,
                                        0
                                      ).toFixed(2)}`}</span>
                                      <span>
                                        {`€ ${getPrice(
                                          availableRental,
                                          discount
                                        ).toFixed(2)}`}
                                      </span>
                                    </>
                                  ) : (
                                    `€ ${getPrice(availableRental, 0).toFixed(
                                      2
                                    )}`
                                  )
                                ) : discount ? (
                                  <>
                                    <span
                                      style={{
                                        textDecoration: "line-through",
                                        fontSize: 10,
                                        marginRight: 5,
                                      }}
                                    >{`€ ${(
                                      getPrice(availableRental, 0) / 1.21
                                    ).toFixed(2)}`}</span>
                                    <span>{`€ ${(
                                      getPrice(availableRental, discount) / 1.21
                                    ).toFixed(2)}`}</span>
                                  </>
                                ) : (
                                  `€ ${(
                                    getPrice(availableRental, 0) / 1.21
                                  ).toFixed(2)}`
                                )}
                                <span
                                  style={{ fontSize: 12, fontStyle: "italic" }}
                                >
                                  {includeVat === "yes"
                                    ? " incl. BTW"
                                    : " excl. BTW"}
                                </span>{" "}
                                <br></br>
                                <span style={{ fontSize: 12 }}>
                                  Inclusief Verzekering
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="vehicle-select__buttons">
                            <div></div>
                            <div>
                              <button
                                onClick={() => {
                                  nextStep(availableRental)
                                  selectItem(availableRental)
                                }}
                                className="button-primary"
                                style={{ minWidth: 150 }}
                              >
                                Selecteer
                              </button>
                            </div>
                          </div>
                        </div>
                        {show ===
                          availableRental.vehicleClass.vehicleClassId && (
                          <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body style={{ padding: 24 }}>
                              <h3 style={{ fontWeight: 600 }}>
                                Voor dit voertuig bieden wij u graag een offerte
                                op maat aan.
                              </h3>
                              <p>
                                Laat uw gegevens achter en wij nemen spoedig
                                contact met u op.
                              </p>
                              <p>
                                U kunt ons ook bellen op:{" "}
                                <a href="tel:088-3745900">088-3745900</a>
                              </p>
                              <QuoteForm
                                requestedVehicle={availableRental.vehicleClass}
                                location={parseLocation.label}
                              />
                            </Modal.Body>
                          </Modal>
                        )}
                      </div>
                    )
                )}
                {availableRentals.length > 0 && (
                  <div
                    className="select-location-box"
                    style={{ textAlign: "center" }}
                  >
                    <h2>Niet direct gevonden wat u zocht? </h2>
                    <p>
                      Het beschikbare aanbod is slechts een deel van onze totale
                      wagenpark. Neem even telefonisch contact met ons op, zodat
                      wij met u mee kunnen denken. Vaak kunnen wij wat schuiven
                      of een voertuig inhuren.
                    </p>
                    <div style={{ maxWidth: 200, margin: "0 auto" }}>
                      <a
                        className="button-primary"
                        style={{ padding: 16 }}
                        href="tel:0883745900"
                      >
                        Bel ons op: 088 - 374 59 00
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <Spinner />
        )}
      </Container>
    </div>
  )
}

export default ReservationSelectVehicle
