import * as React from "react"
import Accordion from 'react-bootstrap/Accordion'

const Faq = (props) => {
    return (
        <div className="faq__background">
            <div className="container">
                <div className="faq__header">
                    <h2>Veelgestelde vragen over {props.faqKeyword ? props.faqKeyword : 'autoverhuur'}</h2>
                </div>
                <div className="faq__content-wrapper">
                {props.faqs.map(item => (
                    <div key={item.strapi_id}>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey={item.strapi_id}>
                                <Accordion.Header as="div">{item.question}</Accordion.Header>
                                <Accordion.Body>
                                    <p>{item.answer.data.answer}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                ))}
                {props.sharedFaqs && props.sharedFaqs.map(item => (
                    <div key={item.strapi_id}>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey={item.strapi_id}>
                                <Accordion.Header as="div">{item.question}</Accordion.Header>
                                <Accordion.Body>
                                    <p>{item.answer.data.answer}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                ))}

            </div>

                <div className="faq__footer">
                    Staat uw vraag hier niet tussen? Neem dan contact met ons op.
                </div>

            </div>
        </div>
    )
}

export default Faq
