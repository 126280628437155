const FreeParking = () => (
    <div>
        <div className="reservation-contact">
            {/* <div className="reservation-contact__photo">Foto</div> */}
            <div className="reservation-contact__text">
                <h2>Gratis Parkeren</h2>
                <p>Komt u met de auto? Op al onze vestigingen kunt u gratis parkeren.</p>
            </div>
        </div>
    </div>
)

export default FreeParking