const OthersBackground = ({ title, subtitle }) => (
    <div className="bg-pages bg-pages__wispo-bg">
        <div className="bg-pages__content">
            <h1>{title}</h1>
            <p>{subtitle}</p>
        </div>
        {/* <div className="bg-pages__blob"></div> */}
    </div>
)

export default OthersBackground;