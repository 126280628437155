"use server"
import axios from "axios"

async function authenticateAndGetToken() {
  const response = await axios.get(
    `${process.env.GATSBY_RENTVISIE_API_URL}/api/v2/auth/rentvisie-token`
  )
  return response.data.id_token
}

const axiosInstance = axios.create({
  baseURL: process.env.GATSBY_RENTVISIE_API_URL,
  timeout: 120000,
})

let cachedToken: string | null = null

let retryCount = 0

const maxRetries = 1

axiosInstance.interceptors.request.use(
  async config => {
    if (!cachedToken) {
      cachedToken = await authenticateAndGetToken()
    }
    config.headers.Authorization = `Bearer ${cachedToken}`
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  response => {
    retryCount = 0
    return response
  },
  async error => {
    if (error.response?.status === 401 && retryCount < maxRetries) {
      retryCount++
      cachedToken = await authenticateAndGetToken()
      error.config.headers.Authorization = `Bearer ${cachedToken}`
      return axiosInstance(error.config)
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
