import isEmail from "validator/lib/isEmail"

export class ValidatorUtil {
  // TODO: Jeroen move validator functions in here

  // static validateX(x: string): boolean {
  //     return x === 'Test';
  // }
  private static nameValidator = /^[a-z ,.'-]{2,50}$/i
  private static phoneValidator = /^[+]?[0-9]{4}?[-]?[0-9]{6,9}$/
  private static zipCodeValidator = /^(\d{4}[a-zA-Z]{2})$/
  private static houseNumberValidator = /^(\d{1,4})$/
  private static additionValidator = /^[a-zA-Z ,. -'\d]{1,3}/iu
  private static streetValidator = /^[a-z ,.'-]{2,140}$/iu
  private static placeOfBirthValidator = /^[a-z ,.'-]{2,50}$/iu
  private static dateOfBirthValidator = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/
  private static registrationNumberValidator = /^[0-9]{8}$/
  private static vatValidator = /^[a-zA-Z , 0-9]{12,18}$/
  private static driverLicenseValidator = /^\d{10}$/
  private static driverLicenseIssueDateValidator =
    /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/
  private static driverLicenseExpirationDateValidator =
    /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/
  private static driverLicenseIssuePlaceValidator = /^[a-z ,.'-]{2,50}$/iu
  static validateDateOfBirth(dateOfBirth: string): boolean {
    const dob = new Date(dateOfBirth)
    const today = new Date()
    const ageDifMs = today.getTime() - dob.getTime()
    const ageDate = new Date(ageDifMs)
    return (
      ageDate.getUTCFullYear() - 1970 > 18 &&
      ageDate.getUTCFullYear() - 1970 < 100 &&
      ValidatorUtil.dateOfBirthValidator.test(dateOfBirth)
    )
  }
  static validatePlaceOfBirth(placeOfBirth: string): boolean {
    return ValidatorUtil.placeOfBirthValidator.test(placeOfBirth)
  }
  static validateDriverLicense(driverLicense: string): boolean {
    return ValidatorUtil.driverLicenseValidator.test(driverLicense)
  }
  static validateDriverLicenseIssueDate(issueDate: string): boolean {
    return ValidatorUtil.driverLicenseIssueDateValidator.test(issueDate)
  }
  static validateDriverLicenseExpirationDate(
    expirationDate: string,
    issueDate: string
  ): boolean {
    if (
      ValidatorUtil.driverLicenseExpirationDateValidator.test(expirationDate)
    ) {
      const issue = new Date(issueDate)
      const expiration = new Date(expirationDate)
      return expiration > issue && expiration > new Date()
    }
    return false
  }
  static validateDriverLicenseIssuePlace(issuePlace: string): boolean {
    return ValidatorUtil.driverLicenseIssuePlaceValidator.test(issuePlace)
  }
  static validateRegistrationNumber(registrationNumber: string): boolean {
    return ValidatorUtil.registrationNumberValidator.test(registrationNumber)
  }
  static validateName(name: string): boolean {
    return ValidatorUtil.nameValidator.test(name)
  }
  static validateVatNumber(vatNumber: string): boolean {
    return ValidatorUtil.vatValidator.test(vatNumber)
  }
  static validatePhone(phoneNumber: string): boolean {
    return ValidatorUtil.phoneValidator.test(phoneNumber)
  }
  static validateZipCode(zipCode: string): boolean {
    return ValidatorUtil.zipCodeValidator.test(zipCode)
  }
  static validateEmail(email: string): boolean {
    return isEmail(email)
  }
  static validateHouseNumber(houseNumber: string): boolean {
    return ValidatorUtil.houseNumberValidator.test(houseNumber)
  }
  static validateAddition(addition: string): boolean {
    return ValidatorUtil.additionValidator.test(addition)
  }
  static validateStreet(street: string): boolean {
    return ValidatorUtil.streetValidator.test(street)
  }
  static validateInsuranceOptions = (insuranceOptions: any) => {
    if (insuranceOptions) {
      return true
    }
  }
  static validateMileageOptions = (mileageOptions: any) => {
    if (mileageOptions) {
      return true
    }
  }
  static validateOptionalOptions = (optionalOptions: any) => {
    if (optionalOptions) {
      return true
    }
  }
  static validateRentalInsuranceOptions = (insuranceOptions: any) => {
    if (insuranceOptions) {
      return true
    }
  }
  static validateRentalMileageOptions = (mileageOptions: any) => {
    if (mileageOptions) {
      return true
    }
  }
  static validateRentalOptionalOptions = (optionalOptions: any) => {
    if (optionalOptions) {
      return true
    }
  }
  static validateKassakoopje = kassaKoopje => {
    if (kassaKoopje.length > 0) {
      return true
    }
  }
  static validateCustomer = (
    hasValidFirstName,
    hasValidLastName,
    hasValidPhone,
    hasValidEmail,
    hasValidZipCode,
    hasValidHouseNumber,
    hasValidStreet
  ) => {
    if (
      hasValidFirstName &&
      hasValidLastName &&
      hasValidPhone &&
      hasValidEmail &&
      hasValidZipCode &&
      hasValidHouseNumber &&
      hasValidStreet
    ) {
      return true
    }
  }
  static validateCompany = (
    hasValidCompanyName,
    hasValidRegistrationNumber,
    hasValidPhone,
    hasValidEmail
  ) => {
    if (
      hasValidCompanyName &&
      hasValidPhone &&
      hasValidEmail &&
      hasValidRegistrationNumber
    ) {
      return true
    }
  }
}
