import React from "react";
import ReactMarkdown from "react-markdown";

const Links = ({ productLinks }) => {
    return (
        <>
            {productLinks &&
                <div className="container">
                    {productLinks.linksText &&
                        <ReactMarkdown>{productLinks.linksText.data.linksText}</ReactMarkdown>
                    }
                    <div className="additional-information">
                        <div className="additional-information__content-wrapper">
                            {productLinks.linksLeft &&
                                <div>
                                    <ReactMarkdown>{productLinks.linksLeft.data.linksLeft}</ReactMarkdown>
                                </div>
                            }
                        </div>
                        <div className="additional-information__content-wrapper">
                            {productLinks.linksRight &&
                                <div>
                                    <ReactMarkdown>{productLinks.linksRight.data.linksRight}</ReactMarkdown>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Links