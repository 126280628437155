import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import ReservationHeader from "./shared/reservation-header"
import { navigate } from "gatsby"
import CtaForm from "../shared/cta-form/form"
import { locations } from "../settings.json"
import SEO from "../seo"
import Settings from "../settings.json"
import Modal from "react-bootstrap/Modal"
import QuoteForm from "../forms/quote"
import Spinner from "../shared/spinner"
import {
  compareAvailabilityAndPrice,
  selectItem,
} from "../../hooks/ReservationFunctions"
import ImageCarousel from "../landing-pages/image-carousel"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isTomorrow, isSameDay } from "date-fns"

const options = Settings.locations

const getRentalPrice = (basePrice, includeVat, hasDiscount) => {
  let price = basePrice

  if (includeVat !== "yes") {
    price = (basePrice / 1.21).toFixed(2)
  } else {
    price = ((basePrice * 100) / 100).toFixed(2)
  }

  let priceAfterDiscount
  if (hasDiscount) {
    priceAfterDiscount = (price - price * 0.3).toFixed(2)
  } else {
    priceAfterDiscount = price
  }

  return priceAfterDiscount
}

const checkIfToday = date => {
  // Get the current date and time
  const today = new Date()

  const [day, month, year] = date.split("-").map(Number)
  const targetDate = new Date(year, month - 1, day)

  // Compare the year, month, and day
  const isSameDate = isSameDay(today, targetDate)
  return isSameDate
}

const checkIfTomorrowBefore8AM = (date, time) => {
  const [day, month, year] = date.split("-").map(Number)
  const targetDate = new Date(year, month - 1, day)

  const [hour, minute] = time.split(":").map(Number)

  // Check if the hour is less than 8
  const isBefore8 = hour < 8

  // Check if the parsed date is tomorrow
  return isTomorrow(targetDate) && isBefore8
}

const checkIfAfter10AM = time => {
  const hoursString = time.split(":")[0]
  const hours = parseInt(hoursString)

  if (hours >= 10) {
    return true
  }

  return false
}

const ReservationSelectVehicle = ({ availableRentals, searchArgs, props }) => {
  const [pushedDataLayer, setPushedDataLayer] = useState(false)
  const [removedClassId, setRemovedClassId] = useState(false)
  const [tryOtherLocations, setTryOtherLocations] = useState(false)
  const [show, setShow] = useState(undefined)
  const [showSpinner, setShowSpinner] = useState(false)
  const [qualifiesForDiscount, setQualifiesForDiscount] = useState(false)

  const handleClose = () => setShow(false)

  const dataLayer = window.dataLayer || []

  if (!pushedDataLayer) {
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: `view_item_list`,
      ecommerce: {
        items: [
          availableRentals.map((item, index, data) => ({
            item_list_name: `Reservering Stap 1 - ${item.vehicleClass.category.description}`,
            item_name: `${item.vehicleClass.description}`,
            item_id: `${item.vehicleClass.vehicleClassId}`,
            item_availability: `${item.available}`,
            price: `${item.price.value}`,
            price_excl_vat: `${parseFloat(
              (item.price.value / 1.21).toFixed(2)
            )}`,
            currency: "EUR",
            item_category: `${item.vehicleClass.category.categoryId}`,
            list_length: `${data.length}`,
            quantity: 1,
            pick_up_location_id: `${item.pickupLocation.locationId}`,
            pick_up_location: `${item.pickupLocation.town}`,
          })),
        ],
      },
    })
    setPushedDataLayer(true)
  }

  let category = searchArgs.categoryId
  let pickupLocation = searchArgs.location
  const pickupDate = searchArgs.pickupDate
  const pickupTime = searchArgs.pickupTime
  const returnDate = searchArgs.dropOffDate
  const returnTime = searchArgs.dropOffTime

  let includeVat = "no"
  if (typeof window !== "undefined") {
    includeVat = localStorage.getItem("includeVat")
  }

  if (availableRentals.length > 0) {
    category = availableRentals[0].vehicleClass.category.categoryId
    pickupLocation = availableRentals[0].pickupLocation
  }

  const parseLocation = options.find(
    loc => loc.value === parseInt(searchArgs.location)
  )

  const searchParams = new URLSearchParams(props.location.search)
  if (availableRentals.length === 0) {
    if (searchParams.get("vehicleClassId") !== "") {
      searchParams.set("vehicleClassId", "")
      navigate(`/huren/voertuig-kiezen?${searchParams.toString()}`)
      setTimeout(function () {
        setRemovedClassId(true)
      }, 100)
      setTimeout(function () {
        window.location.reload()
      }, 2000)
    }

    if (searchParams.get("vehicleClassId") === "") {
      setTimeout(function () {
        setTryOtherLocations(true)
      }, 100)
    }
  }

  const updateLocation = locationId => {
    searchParams.set("location", locationId)
    navigate(`/huren/voertuig-kiezen?${searchParams.toString()}`)
    setTimeout(function () {
      window.location.reload()
    }, 500)
  }

  const nextStep = availableRental => {
    if (
      Settings.quoteOnly.includes(availableRental.vehicleClass.vehicleClassId)
    ) {
      setShow(availableRental.vehicleClass.vehicleClassId)
    } else {
      navigate(`/huren/accessoires-kiezen?id=${availableRental.rentalId}`, {
        state: { hasDiscount: qualifiesForDiscount },
      })
      setShowSpinner(true)
    }
  }
  useEffect(() => {
    if (
      !checkIfToday(pickupDate) ||
      (!checkIfTomorrowBefore8AM(returnDate, returnTime) &&
        !checkIfToday(returnDate))
    ) {
      return
    }

    if (!checkIfAfter10AM(pickupTime)) {
      return
    }
    const bestelbus = Settings.categories.find(
      item => item.name === "bestelauto"
    )
    if (Settings.categories.find(item => bestelbus?.id === category)) {
      setQualifiesForDiscount(true)
    }
  }, [])

  return (
    <div>
      <SEO
        title="Bert Jonk Autoverhuur - Voertuig kiezen"
        index={false}
        follow={true}
      />
      <ReservationHeader activeStepIndex={1} />
      <Container fluid="xxl">
        {!showSpinner ? (
          <React.Fragment>
            {parseLocation && (
              <CtaForm
                category={category}
                location={parseLocation}
                pickupDate={pickupDate}
                pickupTime={pickupTime}
                returnDate={returnDate}
                returnTime={returnTime}
              ></CtaForm>
            )}
            <div className="reservation__title">
              <h2>Online Beschikbare Voertuigen</h2>
              {qualifiesForDiscount && (
                <h3 className="primary-color">
                  Last minute dagaanbieding: 30% KORTING !
                </h3>
              )}
            </div>
            <div className="reservation-select-wrapper">
              <div>
                {availableRentals.length === 0 && (
                  <div className="hero-form__warning">
                    <div> (!) </div>
                    <p>
                      Zoekopdracht heeft helaas geen resultaten opgeleverd. Geen
                      zorgen: wij kunnen u wellicht toch aan het gewenste
                      voertuig helpen!
                    </p>
                    <div></div>
                  </div>
                )}
                {tryOtherLocations && (
                  <div style={{ marginTop: 20 }}>
                    <h2>Neem contact met ons op</h2>
                    <p>
                      Bel ons op: <a href="tel:0883745900">088 - 374 59 00</a>{" "}
                      zodat wij een voertuig voor u kunnen inhuren of een
                      passend alternatief aan kunnen bieden.
                    </p>
                    <p>
                      U kunt het ook op één van onze andere locaties proberen
                    </p>
                    <div className="select-location-wrapper">
                      {pickupLocation !== "5557" && (
                        <div
                          className="select-location"
                          onClick={() => {
                            updateLocation("5557")
                          }}
                        >
                          Purmerend
                        </div>
                      )}
                      {pickupLocation !== "5218" && (
                        <div
                          className="select-location"
                          onClick={() => updateLocation("5218")}
                        >
                          Zwaag
                        </div>
                      )}
                      {pickupLocation !== "5219" && (
                        <div
                          className="select-location"
                          onClick={() => {
                            updateLocation("5219")
                          }}
                        >
                          Oude Meer
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {removedClassId && (
                  <h2>Wij zoeken nu naar een alternatief...ogenblik geduld</h2>
                )}
                {availableRentals.sort(compareAvailabilityAndPrice).map(
                  availableRental =>
                    availableRental.totalPrice.value >= 1 && (
                      <div
                        className="vehicle-wrapper"
                        key={availableRental.vehicleClass.vehicleClassId}
                      >
                        {availableRental.vehicleClass.images.length > 0 && (
                          <ImageCarousel
                            images={availableRental.vehicleClass.images}
                            key={availableRental.vehicleClass.vehicleClassId}
                          />
                        )}
                        <div className="vehicle-select__content">
                          <h3 className="bold">
                            {availableRental.vehicleClass.labelMarkup
                              ? availableRental.vehicleClass.labelMarkup.replace(
                                  /(<([^>]+)>)/gi,
                                  ""
                                )
                              : availableRental.vehicleClass.description}
                          </h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                availableRental.vehicleClass.descriptionMarkup,
                            }}
                          ></div>
                          <div className="features">
                            {/* no included mileage returns 9999, or 1 */}
                            {availableRental.includedMileage > 30 && (
                              <div className="feature">
                                <div className="feature__icon">o</div>
                                <div className="feature__text">
                                  {availableRental.includedMileage} vrije km
                                  inbegrepen
                                </div>
                              </div>
                            )}
                            {availableRental.vehicleClass.tags &&
                              availableRental.vehicleClass.tags
                                .slice(0, 3)
                                .map(feature => (
                                  <div
                                    className="feature"
                                    key={feature.tagDescription}
                                  >
                                    <div className="feature__icon">o</div>
                                    <div className="feature__text">
                                      {feature.tagDescription}
                                    </div>
                                  </div>
                                ))}
                          </div>
                          <div className="vehicle-select__prices">
                            <p className="bold">Totaal: ({availableRental.days} {availableRental.days > 1 ? 'dagen' : 'dag'})</p>
                            <div className="vehicle-select__price">
                              <p className="price-mileage__price">
                                {qualifiesForDiscount && (
                                  <span
                                    style={{
                                      textDecoration: "line-through",
                                      fontSize: 10,
                                      marginRight: 2,
                                    }}
                                  >
                                    €{" "}
                                    {getRentalPrice(
                                      availableRental.totalPrice.value,
                                      includeVat,
                                      false
                                    )}
                                  </span>
                                )}
                                €{" "}
                                {getRentalPrice(
                                  availableRental.totalPrice.value,
                                  includeVat,
                                  qualifiesForDiscount
                                )}
                                <span
                                  style={{ fontSize: 12, fontStyle: "italic" }}
                                >
                                  {includeVat === "yes"
                                    ? " incl. BTW"
                                    : " excl. BTW"}
                                </span>{" "}
                                <br></br>
                                <span style={{ fontSize: 12 }}>
                                  Inclusief Verzekering
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="vehicle-select__buttons">
                            <div></div>
                            <div>
                              {availableRental.availableVehicles !== 0 ? (
                                <button
                                  onClick={() => {
                                    nextStep(availableRental)
                                    selectItem(availableRental)
                                  }}
                                  className="button-primary"
                                >
                                  Selecteer
                                </button>
                              ) : (
                                <button
                                  onClick={() => window.open("tel:0883745900")}
                                  className="button-outline-primary"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 3,
                                  }}
                                >
                                  <div>Online niet beschikbaar.</div>{" "}
                                  <div>
                                    <FontAwesomeIcon
                                      icon="phone"
                                      flip="horizontal"
                                    />{" "}
                                    088-3745900.
                                  </div>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        {show ===
                          availableRental.vehicleClass.vehicleClassId && (
                          <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body style={{ padding: 24 }}>
                              <h3 style={{ fontWeight: 600 }}>
                                Voor dit voertuig bieden wij u graag een offerte
                                op maat aan.
                              </h3>
                              <p>
                                Laat uw gegevens achter en wij nemen spoedig
                                contact met u op.
                              </p>
                              <p>
                                U kunt ons ook bellen op:{" "}
                                <a href="tel:088-3745900">088-3745900</a>
                              </p>
                              <QuoteForm
                                requestedVehicle={availableRental.vehicleClass}
                                location={parseLocation.label}
                              />
                            </Modal.Body>
                          </Modal>
                        )}
                      </div>
                    )
                )}
                {availableRentals.length > 0 && (
                  <div
                    className="select-location-box"
                    style={{ textAlign: "center" }}
                  >
                    <h2>Niet direct gevonden wat u zocht? </h2>
                    <p>
                      Het beschikbare aanbod is slechts een deel van onze totale
                      wagenpark. Neem even telefonisch contact met ons op, zodat
                      wij met u mee kunnen denken. Vaak kunnen wij wat schuiven
                      of een voertuig inhuren.
                    </p>
                    <div style={{ maxWidth: 200, margin: "0 auto" }}>
                      <a
                        className="button-primary"
                        style={{ padding: 16 }}
                        href="tel:0883745900"
                      >
                        Bel ons op: 088 - 374 59 00
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <Spinner />
        )}
      </Container>
    </div>
  )
}

export default ReservationSelectVehicle
