import { Container } from "react-bootstrap"
import Layout from "../components/layout"
import LocationMap from "../components/location-map"
import SEO from "../components/seo"
import { OpeningHoursHome } from "../components/shared/opening-hours"
import ContactForm from "../components/shared/contact-form"
import { Link } from "gatsby"
import TrucksForm from "../components/forms/trucks"
import { useStaticQuery, graphql } from "gatsby"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"
import React, { useState } from 'react';
import TruckIcon from '../../src/images/svg/heavyTruck.svg'
import CoolerIcon from '../../src/images/svg/coolingTruck.svg'

const TrucksNl = (props) => {
    const [showCoolers, setShowCoolers] = useState(true)
    const [showTrucks, setShowTrucks] = useState(false)
    const [showQuote, setShowQuote] = useState(undefined)

    const query = new URLSearchParams(props.location.search)
    const category = query.get('categorie');

    React.useEffect(() => {
        if (category === 'trucks') {
            setShowTrucks(true)
            setShowCoolers(false)
        } else {
            setShowTrucks(false)
            setShowCoolers(true)
        }
    }, [category])

    const handleClick = (id) => {
        setShowQuote(id);
        const element = document.getElementById(id);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickButton = (category) => {
        if (category === 'trucks') {
            setShowTrucks(true)
            setShowCoolers(false)
        } else {
            setShowTrucks(false)
            setShowCoolers(true)
        }
        const element = document.getElementById('products');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    let includeVat = 'no'
    if (typeof window !== "undefined") {
        includeVat = localStorage.getItem('includeVat');
    }


    const data = useStaticQuery(graphql`
      query {
        strapiTrucknl {
          title
          subTitle
          flexlease {
            data {
              flexlease
            }
          }
          fleximage {
            alternativeText
            caption
            url
          }
          products {
            strapi_id
            category
            image {
              url
              caption
              alternativeText
            }
            monthMileage
            monthPrice
            title
            tags {
              strapi_id
              description
            }
          }
          strapi_id
        }
      }
    `)

    return (
        <Layout>
            <SEO
                title="Nu een Vrachtwagen of Koelwagen nodig? | Bert Jonk Autoverhuur"
                description="Heeft u nu een voertuig nodig? Kies voor Flex Huur bij Bert Jonk Autoverhuur. Direct beschikbaar, flexibel opzegbaar."
                follow={true}
                index={true}
            />
            <div className="bg-pages bg-pages__default-bg">
                <div className="bg-pages__content">
                    <h1>{data.strapiTrucknl.title}</h1>
                    <p>{data.strapiTrucknl.subTitle}</p>
                    <div className="trucksnl-buttons" style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                        <button className="button-secondary" onClick={() => { handleClickButton('trucks') }}><img src={TruckIcon} style={{ width: 32, marginRight: 6 }} alt='Trucks' />  Bestel-/ Vrachtwagen</button>
                        <button className="button-secondary" onClick={() => { handleClickButton('coolers') }}><img src={CoolerIcon} style={{ width: 32, marginRight: 6 }} alt='Koelwagen' />  Koel-/ Vrieswagen</button>
                    </div>
                </div>
            </div>
            <section>
                <Container>
                    <div className='row-50'>
                        <div>
                            <ReactMarkdown>
                                {data.strapiTrucknl.flexlease.data.flexlease}
                            </ReactMarkdown>
                        </div>
                        <div><img src={data.strapiTrucknl.fleximage.url} alt={data.strapiTrucknl.fleximage.alternativeText} style={{ maxWidth: '100%' }} /></div>
                    </div>
                </Container>
            </section>
            <section>
                <Container>
                    <div style={{ display: 'flex', gap: '2rem', marginBottom: 48 }}>
                        <div onClick={() => { setShowCoolers(true); setShowTrucks(false) }} className={`${showCoolers && 'primary-color bold'} mouse`}><img src={CoolerIcon} alt='Koelwagen' />  Koel-/ Vrieswagens</div>
                        <div onClick={() => { setShowCoolers(false); setShowTrucks(true) }} className={`${showTrucks && 'primary-color bold'} mouse`}><img src={TruckIcon} alt='Vrachtwagen' /> Bestel-/ Vrachtwagens</div>
                    </div>
                    <h2>Meestgehuurde {showTrucks ? 'Bestel-/Vrachtwagens' : 'Koel-/vrieswagens'}
                    </h2>

                    <div className="products" id="products">
                        {showTrucks &&

                            data.strapiTrucknl.products.filter((product) => product.category === 'truck').map((product) => (
                                <React.Fragment key={product.strapi_id}>
                                    <div className="product" style={{ paddingTop: 24 }}>
                                        <div className="product-image">
                                            {<img src={product.image.url} alt={product.image.alternativeText} title={product.image.caption} className="product__image"></img>}
                                        </div>
                                        <div className="product-content">
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div className="product__content__title"><h3 style={{ fontWeight: 600 }}>{product.title}</h3></div>
                                                <p className="price">v.a. €
                                                    {includeVat
                                                        ? (product.monthPrice / 1.21).toFixed(2).toString().replace('.', ',')
                                                        : product.monthPrice.toFixed(2).toString().replace('.', ',')
                                                    }
                                                    /maand</p>
                                            </div>
                                            <div style={{}}>
                                                <ul>
                                                    {product.tags.map((tag) => (
                                                        <li>{tag.description}</li>
                                                    ))}
                                                </ul>
                                            </div>

                                            <div className="vehicle-select__buttons" style={{ marginTop: 24 }}>
                                                <div><Link className="btn button-outline-primary" to='#'>Korte termijn huren</Link></div>
                                                <div className="btn button-primary" onClick={() => { handleClick(product.strapi_id) }}>Offerte Opvragen</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={product.strapi_id}>
                                        {showQuote === product.strapi_id && <TrucksForm truckName={product.title} monthPrice={product.monthPrice} />}
                                    </div>
                                </React.Fragment>
                            ))

                        }

                        {showCoolers &&

                            data.strapiTrucknl.products.filter((product) => product.category === 'cooler').map((product) => (
                                <React.Fragment>
                                    <div className="product" key={product.strapi_id} style={{ paddingTop: 24 }}>
                                        <div className="product-image">
                                            {<img src={product.image.url} alt={product.image.alternativeText} title={product.image.caption} className="product__image"></img>}
                                        </div>
                                        <div className="product-content">
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div className="product__content__title"><h3 style={{ fontWeight: 600 }}>{product.title}</h3></div>
                                                <p className="price">v.a. €
                                                    {includeVat
                                                        ? (product.monthPrice / 1.21).toFixed(2).toString().replace('.', ',')
                                                        : product.monthPrice.toFixed(2).toString().replace('.', ',')
                                                    }
                                                    /maand</p>
                                            </div>
                                            <div style={{}}>
                                                <ul>
                                                    {product.tags.map((tag) => (
                                                        <li>{tag.description}</li>
                                                    ))}
                                                </ul>
                                            </div>

                                            <div className="vehicle-select__buttons" style={{ marginTop: 24 }}>
                                                <div><Link className="btn button-outline-primary" to={product.linkTo}>Korte termijn huren</Link></div>
                                                <div className="btn button-primary" onClick={() => { setShowQuote(product.strapi_id) }}>Offerte Opvragen</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {showQuote === product.strapi_id && <TrucksForm truckName={product.title} />}
                                    </div>
                                </React.Fragment>
                            ))
                        }
                    </div>

                </Container>
            </section>
            <section>
                <ContactForm />
            </section>
            <LocationMap />
            <OpeningHoursHome />

        </Layout>
    )
}

export default TrucksNl