import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import { Link } from 'gatsby'

const AanvraagBedankt = () => {
  return (
    <Layout>
        <SEO follow={true} index={false} title="Bedankt voor uw verzoek" description="Bedankt voor uw verzoek aan Bert Jonk. Wij komen hier zo spoedig mogelijk op terug." />
        <div style={{minHeight: '70vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <h1>Uw verzoek is ingediend</h1>
            <Link to='/' className='button utton-primary'>Naar home</Link>
        </div>
    </Layout>
  )
}

export default AanvraagBedankt;