import * as React from "react"
import { Rentvisie } from "@bakkie/ratality"
import ReservationSelectVehicle from "../../components/reservation/step-1"
import Footer from "../../components/reservation/shared/footer"
import ReservationHeader from "../../components/reservation/shared/reservation-header"
import Skeleton from "../../components/shared/skeletons"
import CtaForm from "../../components/shared/cta-form/form"
import Spinner from "../../components/shared/spinner"
import { Container } from "react-bootstrap"
import { addMinutes, formatISO, parseISO } from "date-fns";

const VoertuigKiezen = props => {
  const [availableRentals, setAvailableRentals] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(undefined)

  const query = new URLSearchParams(props.location.search)
  const categoryId = query.get("categoryId")
  // const categoryId = 381;
  const vehicleClassId = query.get("vehicleClassId")
  // const location = 4718;
  const location = query.get("location")
  // const location = ['5557', '5218']

  // Parse PickupDateTime
  const pickupDateTime = query.get("ophalen") || "31-12-2022 08:00"

  const pickupDate = pickupDateTime.split(" ")[0].replace(/,+/g, "")
  const pickupTimeSplit = pickupDateTime.split(" ")[1]
  const pickupTime = pickupTimeSplit.split(":")[0] + ":" + pickupTimeSplit.split(":")[1]

  // Parse ReturnDateTime
  const returnDateTime = query.get("retour") || "31-12-2024 17:00"


  const returnDate = returnDateTime.split(" ")[0].replace(/,+/g, "")
  const returnTimeSplit = returnDateTime.split(" ")[1]
  const returnTime =
  returnTimeSplit.split(":")[0] + ":" + returnTimeSplit.split(":")[1]
  
  const returnDay = parseInt(returnDateTime.split("-")[0])
  const returnMonth = parseInt(returnDateTime.split("-")[1])
  const returnYear = parseInt(returnDateTime.split("-")[2])
  const returnHour = parseInt(returnTimeSplit.split(":")[0])
  const returnMinutes = parseInt(returnTimeSplit.split(":")[1])

  let returnDateManipulated = undefined;
  let returnTimeManipulated = undefined;

  if (returnHour === 23 && returnMinutes === 55) {
    const returnDateISO = formatISO(new Date(returnYear, returnMonth -1, returnDay, returnHour, returnMinutes))
    const returnDateIsoAfterMidnight = addMinutes(parseISO(returnDateISO), 5)

    const returnDateParsed = returnDateIsoAfterMidnight.toLocaleDateString('nl')
    const returnTimeParsed = returnDateIsoAfterMidnight.toLocaleTimeString('nl')

    returnDateManipulated = returnDateParsed;
    returnTimeManipulated = returnTimeParsed.split(":")[0] + ":" + returnTimeParsed.split(":")[1]
  }

  const splitPickupDate = pickupDate.split("-")
  const splitReturnDate = returnDate.split("-")

  const formattedPickupDate = `${splitPickupDate[1]}/${splitPickupDate[0]}/${splitPickupDate[2]}`
  const formattedReturnDate = `${splitReturnDate[1]}/${splitReturnDate[0]}/${splitReturnDate[2]}`

  const date1 = new Date(formattedPickupDate)
  const date2 = new Date(formattedReturnDate)

  const diffTime = Math.abs(date2 - date1)
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  React.useEffect(() => {
    Rentvisie.setConfig({
      baseUrl: "https://api.rentvisie.com/api",
      client: "bertjonk",
      useGuestToken: true,
    })

    const pickupLocationId = location || 5557
    const dropOffLocationId = location || 5557

    const filters = {
      pickupDate: pickupDate,
      pickupTime: pickupTime,
      dropOffDate: returnDateManipulated || returnDate,
      dropOffTime: returnTimeManipulated || returnTime,
      vehicleClassId: vehicleClassId || "",
      categoryId: categoryId || "",
    }

    Rentvisie.getRentals(pickupLocationId, dropOffLocationId, filters)
      .then(setAvailableRentals)
      .catch(setError)
      .finally(() => setLoading(false))
  }, [])

  if (loading) {
    return (
      <div>
        <ReservationHeader activeStepIndex={1} />
        <Container fluid="xxl">
          <div className="reservation__title">
            <h2>Ogenblik geduld...</h2>
            <h3>
              Wij rennen momenteel over ons terrein om onze beschikbare
              voertuigen te inventariseren.
            </h3>
            <Spinner></Spinner>
            {diffDays >= 6 && (
              <h3>
                <span style={{ fontWeight: 600 }}>Let op: </span> U zoekt voor
                een periode van {diffDays + 1} dagen. De inventarisatie van
                beschikbaarheid duurt daardoor iets langer.
              </h3>
            )}
            <div className="loading-bar">
              <div className="loading-bar__progress"></div>
            </div>
          </div>
          <Skeleton length={6} />
        </Container>
        <Footer />
      </div>
    )
  }

  if (error) {
    return (
      <div>
        <ReservationHeader activeStepIndex={1} />
        <Container fluid="xxl">
          <div className="reservation__title">
            <h2>Oeps, er ging iets mis...</h2>
            <p>Probeer het opnieuw</p>
            <CtaForm />
          </div>
        </Container>
        <Footer />
      </div>
    )
  }

  //niet zo netjes...2 keer zelfde variablele, hoie gebruik ik filters ipv searchargs ?
  const searchArgs = {
    pickupDate: pickupDate,
    pickupTime: pickupTime,
    dropOffDate: returnDate,
    dropOffTime: returnTime,
    vehicleClassId: vehicleClassId || "",
    categoryId: categoryId || "",
    pickupLocationId: location || 5557,
    dropOffLocationId: location || 5557,
    location: location || 5557,
  }

  return (
    <div>
      <ReservationSelectVehicle
        availableRentals={availableRentals}
        searchArgs={searchArgs}
        categoryId={categoryId}
        props={props}
      />
    </div>
  )
}

export default VoertuigKiezen
