import React, { useEffect, useState } from "react"
import Insurance from "../../../images/svg/insurance.png"
import { compare, getDisplayPriceOfOption } from "./HelperFunctions"

const SelectAdditionalInsuranceOptions = ({
  optionalOptions,
  totalDays,
  handleCheckBox,
  includeVat,
}) => {
  const [insuranceFilter, setInsuranceFilter] = useState([])

  useEffect(() => {
    const passengerInsurances = optionalOptions.filter(option =>
      option.category?.toUpperCase().includes("INZITTENDEVERZEKERING")
    )

    const passengerInsuranceDayCar = passengerInsurances
      ? passengerInsurances.find(
          option => option.featurePeriod?.toUpperCase() === "DAY"
        )
      : []
    const passengersInsuranceWeekCar = passengerInsurances
      ? passengerInsurances.find(
          option => option.featurePeriod?.toUpperCase() === "WEEK"
        )
      : []
    const passengersInsuranceMonthCar = passengerInsurances
      ? passengerInsurances.find(
          option => option.featurePeriod?.toUpperCase() === "MONTH"
        )
      : []

    const passengerInsurance = []
    if (totalDays > 6 && totalDays < 30) {
      if (passengersInsuranceWeekCar) {
        passengerInsurance.push(passengersInsuranceWeekCar)
      }
    } else if (totalDays > 29) {
      if (passengersInsuranceMonthCar) {
        passengerInsurance.push(passengersInsuranceMonthCar)
      }
    } else {
      if (passengerInsuranceDayCar) {
        passengerInsurance.push(passengerInsuranceDayCar)
      }
    }

    const insuranceFilterPreConcat = optionalOptions
      ? optionalOptions.filter(
          option =>
            option.category === "Verzekering (Overig)" &&
            !option.description.includes("Inzittendeverzekering")
        )
      : []

    const insuranceFilter =
      passengerInsurance.length > 0
        ? insuranceFilterPreConcat.concat(passengerInsurance)
        : insuranceFilterPreConcat

    setInsuranceFilter(insuranceFilter)
  }, [totalDays, optionalOptions, includeVat])
  // Inzittendeverzekering (dag, week, maand)

  if (insuranceFilter.length === 0) {
    return null;
  }

  return (
    <div className="extra-wrapper">
      <div className="extra-wrapper__title">
        <img src={Insurance} alt="Overige Verzekeringen"></img>
        <h3>Verzekeringen</h3>
      </div>
      <div className="extrabox-grid">
        {insuranceFilter.sort(compare).map(option => (
          <div key={option.optionId}>
            <input
              type="checkbox"
              id={option.optionId}
              name="optionId"
              value={option.optionId}
              onChange={event => handleCheckBox("optionalOptions", event)}
            />
            <label htmlFor={option.optionId}>
              <div className="extrabox">
                <h3>
                  {option.description} (€
                  {getDisplayPriceOfOption(option, totalDays, includeVat)}
                  )
                </h3>
                <p className="dark-gray">
                  {option.labelMarkup.replace(/(<([^>]+)>)/gi, "")}
                </p>
              </div>
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SelectAdditionalInsuranceOptions
