import { Container } from "react-bootstrap";
import Layout from "../components/layout";
import SEO from "../components/seo";


const GeneralTerms = () => (
    <Layout>
        <SEO follow={true} index={false} title='Bert Jonk Autoverhuur Algemene Voorwaarden' description="Wij hanteren de Bovag voorwaarden als algemene voorwaarden"></SEO>
        <Container fluid='xxl' style={{ minHeight: '70vh' }}>
            <div style={{padding: '2rem 0'}}>
                <h1>Algemene Voorwaarden</h1>
                <p>Als officieel Bovag Verhuurbedrijf maken wij gebruik van de Bovag algemene voorwaarden. Download deze hieronder.</p>
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <div>
                        <a
                            href='https://strapibja.s3.eu-west-1.amazonaws.com/Algemene_voorwaarden_verhuur_en_deelautobedrijven_met_bijlage_deelauto_web_printbestand_01_05_21_cdb8675c67.pdf?updated_at=2022-07-22T11:48:19.901Z'
                            target='_blank'
                            rel='noreferrer'
                            download
                        > Algemene Huurvoorwaarden Particulier
                        </a>
                    </div>
                    <div>
                        <a
                            href='https://strapibja.s3.eu-west-1.amazonaws.com/Algemene_huurvoorwaarden_auto_zakelijk_webversie_01_05_21_ae1a01a9ea.pdf?updated_at=2022-07-22T11:48:19.884Z'
                            download
                            target='_blank'
                            rel='noreferrer'
                        >
                            Algemene Huurvoorwaarden Zakelijk
                        </a>
                    </div>
                </div>
            </div>

        </Container>

    </Layout>
)

export default GeneralTerms