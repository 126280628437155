import * as React from "react"
import ReactMarkDown from 'react-markdown'
import { Container } from "react-bootstrap"

export default function HomeIntroduction(props) {
  return (
    <Container>
    <div className="home-introduction">
    <div className="home-introduction__content">
      <div className="home-introduction__content__title">
        <h1>{props.title}</h1>
      </div>
      <ReactMarkDown>{props.introduction.data.introduction}</ReactMarkDown>
    </div>
  </div>
    </Container>
  )
}
