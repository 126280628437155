import * as React from "react";
import { Rentvisie } from "@bakkie/ratality";
import ReservationSelectVehicle from "../../components/reservation/wispo/step-1";
import Footer from '../../components/reservation/shared/footer';
import ReservationHeader from '../../components/reservation/shared/reservation-header';
import Skeleton from "../../components/shared/skeletons";
import Spinner from "../../components/shared/spinner";
import { Container } from "react-bootstrap";

const VoertuigKiezen = (props) => {
  const [availableRentals, setAvailableRentals] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(undefined)

  const query = new URLSearchParams(props.location.search)
  const categoryId = query.get('categoryId');
  const vehicleClassId = query.get('vehicleClassId');
  const location = query.get('location')
  const pickupDate = query.get('ophaaldatum') || '31-12-2023'
  const returnDate = query.get('retourdatum') || '31-12-2023'
  const pickupTime = query.get('ophaaltijd')
  const returnTime = query.get('retourtijd')

  const splitPickupDate = pickupDate.split('-')
  const splitReturnDate = returnDate.split('-')

  const formattedPickupDate = `${splitPickupDate[1]}/${splitPickupDate[0]}/${splitPickupDate[2]}`
  const formattedReturnDate = `${splitReturnDate[1]}/${splitReturnDate[0]}/${splitReturnDate[2]}`

  const date1 = new Date(formattedPickupDate);
  const date2 = new Date(formattedReturnDate);

  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  React.useEffect(() => {
    Rentvisie.setConfig({
      baseUrl: 'https://api.rentvisie.com/api',
      client: 'bertjonk',
      useGuestToken: true,
    })

    const pickupLocationId = location || 5557
    const dropOffLocationId = location || 5557

    const filters = {
      pickupDate: pickupDate,
      pickupTime: pickupTime,
      dropOffDate: returnDate,
      dropOffTime: returnTime,
      vehicleClassId: vehicleClassId || '',
      categoryId: categoryId || '',
    }

    Rentvisie.getRentals(pickupLocationId, dropOffLocationId, filters)
      .then(setAvailableRentals)
      .catch(setError)
      .finally(() => setLoading(false))
  }, [])

  if (loading) {
    return (
      <div>
        <ReservationHeader activeStepIndex={1} />
        <Container fluid='xxl'>
          <div className="reservation__title">
            <h2>Trek uw wintersport outfit alvast maar aan...</h2>
            <h3>Wij rennen momenteel over ons terrein om onze beschikbare voertuigen te inventariseren.</h3>
            <Spinner></Spinner>
            {
              (
                diffDays >= 6
              ) &&
              <h3><span style={{ fontWeight: 600 }}>Let op: </span> U zoekt voor een periode van {diffDays + 1} dagen. De inventarisatie van beschikbaarheid duurt daardoor iets langer.</h3>
            }
          <div className="loading-bar">
            <div className="loading-bar__progress"></div>
          </div>
          </div>
          <Skeleton length={6} />
        </Container>
        <Footer />
      </div>
    )
  }

  if (error) {
    return (
      <div>
        <ReservationHeader activeStepIndex={1} />
        <Container fluid='xxl'>
          <div className="reservation__title">
            <h2>Oeps, er ging iets mis...</h2>
            <p>Probeer het opnieuw</p>
          </div>
        </Container>
        <Footer />
      </div>
    )
  }

  //niet zo netjes...2 keer zelfde variablele, hoie gebruik ik filters ipv searchargs ?
  const searchArgs = {
    pickupDate: pickupDate,
    pickupTime: pickupTime,
    dropOffDate: returnDate,
    dropOffTime: returnTime,
    vehicleClassId: vehicleClassId || '',
    categoryId: categoryId || '',
    pickupLocationId: location || 5557,
    dropOffLocationId: location || 5557
  }

  return (
    <div>
      <ReservationSelectVehicle
        availableRentals={availableRentals}
        searchArgs={searchArgs}
        props={props}
      />
    </div>
  )
}

export default VoertuigKiezen