import { useState, useEffect } from "react";
import Customer from "../../images/svg/customer.png";
import BusinessBuilding from "../../images/svg/business-building.png";

const AskForVat = () => {
    const [askVat, setAskVat] = useState(false)

    if (typeof window !== "undefined") {
        useEffect(() => {
            const itemStr = localStorage.getItem('includeVat')
            // if the item doesn't exist, return null
            if (!itemStr) {
                setAskVat(true)
            }
        });
    }
    const setVat = async (arg) => {
        if (arg === 'yes') {
            localStorage.setItem('includeVat', 'yes');
        } else {
            localStorage.setItem('includeVat', 'no');
        }
        setAskVat(false)
    }
    return (
        askVat &&
        <div className={`company-or-individual__wrapper ${!askVat && 'hide'}`}>
            <p>Help ons u de juiste prijzen te tonen.</p>
            <div className="company-or-individual__select">
                <div className="company-or-individual__select-box" onClick={() => setVat('yes')}>
                    <div style={{ fontSize: 16 }}>Particulier</div>
                    <div>
                        <img className="ddv-icon" src={Customer} alt='customer' style={{ width: 24, height: 24 }}></img>
                    </div>
                    <div style={{ fontSize: 10 }}>Prijzen incl. BTW</div>
                </div>
                <div className="company-or-individual__select-box mouse" onClick={() => setVat('no')}>
                    <div style={{ fontSize: 16 }}>Bedrijf</div>
                    <div>
                        <img className="ddv-icon" src={BusinessBuilding} alt='businessbuilding' style={{ width: 24, height: 24 }}></img>
                    </div>
                    <div style={{ fontSize: 10 }}>Prijzen excl. BTW</div>
                </div>
            </div>
        </div>
    )
}

export default AskForVat;