export const compare = (a, b) => {
  if (a.price.value < b.price.value) {
    return -1
  }
  if (a.price.value > b.price.value) {
    return 1
  }
  return 0
}

export const compareAvailabilityAndPrice = (a, b) => {
  // If both rentals have availableVehicles === 0, don't change their order
  if (a.availableVehicles === 0 && b.availableVehicles === 0) {
    return 0
  }
  // If a has availableVehicles === 0, move it to the end of the array
  if (a.availableVehicles === 0) {
    return 1
  }
  // If b has availableVehicles === 0, move it to the end of the array
  if (b.availableVehicles === 0) {
    return -1
  }
  // For other cases, sort by some other criteria (you can customize this)
  // For example, sort by totalPrice.value in ascending order
  return a.totalPrice.value - b.totalPrice.value
}

export const selectItem = item => {
  const dataLayer = window.dataLayer || []

  dataLayer.push({ ecommerce: null })
  dataLayer.push({
    event: `select_item`,
    ecommerce: {
      items: [
        {
          item_list_name: `Reservering Stap 1 - ${item.vehicleClass.category.description}`,
          item_name: `${item.vehicleClass.description}`,
          item_id: `${item.vehicleClass.vehicleClassId}`,
          item_availability: `${item.available}`,
          price: `${item.price.value}`,
          price_excl_vat: `${parseFloat((item.price.value / 1.21).toFixed(2))}`,
          currency: "EUR",
          item_category: `${item.vehicleClass.category.categoryId}`,
          quantity: 1,
          pick_up_location_id: `${item.pickupLocation.locationId}`,
          pick_up_location: `${item.pickupLocation.town}`,
        },
      ],
    },
  })
}
