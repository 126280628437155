import * as React from "react"
import { graphql } from "gatsby"
import Hero from "../components/hero"
import HomeIntroduction from "../components/home-introduction"
import Layout from "../components/layout"
import Seo from "../components/seo"
import loadable from '@loadable/component'
import HighlightedBlogs from "../components/blogs/blogs"
const LoadableProducts = loadable(() => import('../components/products'))
const LoadableOpeningHoursHome = loadable(() => import('../components/shared/opening-hours'), {
  resolveComponent: (components) => components.OpeningHoursHome
})
const LoadableLocationMap = loadable(() => import('../components/location-map'))
const LoadableAdditionalInfo = loadable(() => import('../components/shared/additional-info'))
const LoadableFaqs = loadable(() => import('../components/shared/faq'))

const IndexPage = ({ data }) => {
  const { seoTitle, seoMetadescription } = data.strapiHomepage.basicSeo
  const { title, introduction } = data.strapiHomepage;
  const blocks = data.strapiHomepage.homepageBlocks;
  const cover = data.strapiHomepage.cover;
  const faqs = data.strapiHomepage.homepageFaqs;
  // const reviews = data.strapiHomepage.homepageReviews;
  const products = data.strapiHomepage.popular_products;
  return (
    <Layout>
      <Seo index={true} follow={true} title={seoTitle} description={seoMetadescription} />
      <Hero cover={cover} title={'Voertuig Boeken'}></Hero>
      <HomeIntroduction title={title} introduction={introduction}></HomeIntroduction>
      {/* TODO : REDESIGN PRODUCTS */}
      <LoadableProducts products={products} />
      <LoadableOpeningHoursHome />
      <LoadableLocationMap />
      <LoadableFaqs faqs={faqs} />
      <HighlightedBlogs />
      <LoadableAdditionalInfo blocks={blocks} />
      {/* <CustomerExperience reviews={reviews} /> */}
    </Layout>
  )
}

export const homepageQuery = graphql`
{
  strapiHomepage {
    basicSeo {
      seoMetadescription
      seoTitle
    }
    homepageBlocks {
      title
      strapi_id
      description {
        data {
          description
        }
      }
    }
    homepageFaqs {
      strapi_id
      question
      answer {
        data {
          answer
        }
      }
    }
    strapi_id
    title
    introduction {
      data {
        introduction
      }
    }
    cover {
      url
      name
      alternativeText
    }
    homepageReviews {
      strapi_id
      name
      description
      rating
      strapi_id
      carouselImage {
        url
        alternativeText
        caption
      }
    }
    popular_products {
      title
      description
      featureOne
      featureThree
      featureTwo
      price
      priceMileage
      link
      cta
      image {
        url
        alternativeText
        caption
      }
    }
  }
}
`

export default IndexPage
