import React from "react";
import { Link } from "gatsby";

const BreadCrumbs = ({ title, subFolder }) => {
    const subFolderLink = subFolder && `/${subFolder.replaceAll(' ', '-').toLowerCase()}`
    return (
        <div className='container'>
            <div className="breadcrumbs">
                <Link to='/'>
                    <div className="breadcrumb">
                        Home
                    </div>
                </Link>
                {subFolder &&
                    <Link to={subFolderLink}>
                        <div className="breadcrumb">
                            {subFolder.charAt(0).toUpperCase()+subFolder.replaceAll('-',' ').slice(1)}
                        </div>
                    </Link>
                }
                <div className="breadcrumb">
                    {title}
                </div>
            </div>
        </div>
    )
}

export default BreadCrumbs