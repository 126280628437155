import { createSlice } from '@reduxjs/toolkit';

// workaround for browser-only feature gatsby
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let localstoreModal: any;

if (typeof window !== 'undefined') {
  localstoreModal = JSON.parse(localStorage.getItem('shownOpeningHoursModal') || 'false');
}

const initialState = {
  show: localstoreModal,
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setShow: (state, action) => {
      state.show = action.payload;
      localStorage.setItem('shownOpeningHoursModal', JSON.stringify(action.payload));
    },

  },
})

export const { setShow } = modalSlice.actions;
export default modalSlice.reducer;