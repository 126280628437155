import React, { useEffect, useRef, useState } from "react"
import Contact from "./shared/contact"
import ReservationHeader from "./shared/reservation-header"
import { Rentvisie } from "@bakkie/ratality"
import { navigate } from "gatsby"
import { ValidatorUtil } from "./validator.util"
import Button from "react-bootstrap/Button"
import Spinner from "../shared/spinner"
import SEO from "../seo"
import FreeParking from "./shared/freeParking"
import { ReservationCheckoutProps } from "../../interfaces/ReservationInterfaces"
import getCustomer from "../../utils/getCustomer"
import convertDateFormat from "../../utils/functions/dateConverter"
import patchCustomer from "../../utils/patchCustomer"
import postCustomer from "../../utils/patchCustomer"
// import PaymentPopup from "./payment-popup"

declare global {
  interface Window {
    dataLayer: any
  }
}

Rentvisie.setConfig({
  baseUrl: "https://api.rentvisie.com/api",
  client: "bertjonk",
  useGuestToken: true,
})

const getBasePrice = (
  basePrice: number,
  totalDays: number,
  isCompany: boolean
) => {
  const initPrice = basePrice * totalDays

  let priceAfterVat = initPrice

  if (!isCompany) {
    priceAfterVat = initPrice / 1.21
  }

  return priceAfterVat
}

const ReservationCheckout = ({
  reservationData,
  query,
  hasDiscount,
}: ReservationCheckoutProps) => {
  const reservationReference = reservationData.reservationReference
  const bookingReference = reservationData.rentals[0].bookingReference
  const category = reservationData.rentals[0].vehicleClass.category.categoryId
  const {
    pickupLocation,
    pickupDate,
    pickupTime,
    dropOffDate,
    dropOffTime,
    vehicleClass,
  } = reservationData.rentals[0]
  const kassaKoopje =
    vehicleClass.optionalOptions?.filter(
      option => option.category === "Kassakoopjes"
    ) || []

  const [recurringCustomer, setRecurringCustomer] = useState(undefined)
  const [populateData, setPopulateData] = useState(undefined)

  const [isSoftLogin, setIsSoftLogin] = useState(false)
  const [email, setEmail] = useState("")
  const [dateOfBirth, setDateOfBirth] = useState("")
  const [registrationNumber, setRegistrationNumber] = useState("")
  const [customerBData, setCustomerBData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    company: {
      name: "",
    },
    customerId: "",
  })
  const [noFound, setNoFound] = useState(false)
  const [isLogged, setIsLogged] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  let includeVat = "no"
  if (typeof window !== "undefined") {
    includeVat = localStorage.getItem("includeVat")
  }

  // SOFT LOGIN HANDLER
  const isEmailValid = ValidatorUtil.validateEmail(email)
  const isDateOfBirthValid = ValidatorUtil.validateDateOfBirth(dateOfBirth)
  const isRegistrationNumberValid =
    ValidatorUtil.validateRegistrationNumber(registrationNumber)

  const softLoginHandler = async () => {
    if (!isCompany && !isEmailValid && !isDateOfBirthValid) {
      return
    }
    if (isCompany && !isEmailValid && !isRegistrationNumberValid) {
      return
    }
    const response = await getCustomer(
      email,
      convertDateFormat(dateOfBirth),
      registrationNumber
    )
    if (response !== undefined) {
      setCustomerBData(response)
    } else {
      setNoFound(true)
    }
    setIsLogged(true)
  }

  // const handlePaymentPopup = () => {

  //   setOpenModal(true)
  // }

  const informationHandler = async (a: "again" | "first") => {
    if (a === "first") {
      setIsSoftLogin(false)
    }
    setCustomerBData(null)
    setIsLogged(false)
    setNoFound(false)
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("storedCustomerData")) {
        setRecurringCustomer(
          JSON.parse(localStorage.getItem("storedCustomerData"))
        )
      }
    }
  }, [])

  const rentalHasInsuranceOptions =
    ValidatorUtil.validateRentalInsuranceOptions(
      reservationData.rentals[0].insurances[0]
    )
  const rentalHasMileageOptions = ValidatorUtil.validateRentalMileageOptions(
    reservationData.rentals[0].mileagePackages[0]
  )
  const rentalHasOptionalOptions = ValidatorUtil.validateRentalOptionalOptions(
    reservationData.rentals[0].options
  )

  const insuranceOptionsData = []
  if (rentalHasInsuranceOptions) {
    insuranceOptionsData.push(reservationData.rentals[0].insurances[0])
  }
  const mileagePackageOptionsData = []
  if (rentalHasMileageOptions) {
    mileagePackageOptionsData.push(
      reservationData.rentals[0].mileagePackages[0]
    )
  }
  const optionalOptionsData = reservationData.rentals[0].options
  const [postalcodeData, setPostalcodeData] = useState(undefined)

  const [customerData, setCustomerData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: "",
    placeOfBirth: "",
    iban: "",
  })
  const [driversLicense, setDriversLicense] = useState({
    number: "",
    issueDate: "",
    expiryDate: "",
    issuePlace: "",
  })
  const [physicalAddress, setPhysicalAddress] = useState({
    houseNumber: "",
    houseNumberAdditional: "",
    streetName: "",
    city: "",
    postalCode: "",
    countryCode: "NL",
  })

  const [cellphoneNumber, setcellphoneNumber] = useState({
    countryDialingCode: "31",
    number: "",
  })

  const [companyData, setCompanyData] = useState({
    name: "",
    vatNumber: "",
    registrationNumber: "",
  })

  const [newCustomerData, setNewCustomerData] = useState({})

  //Validations
  const hasValidBusinessName =
    ValidatorUtil.validateName(companyData.name) ||
    (populateData &&
      populateData.company &&
      ValidatorUtil.validateName(populateData.company.name))

  const hasValidFirstName =
    ValidatorUtil.validateName(customerData.firstName) ||
    (populateData && ValidatorUtil.validateName(populateData.firstName))

  const hasValidLastName =
    ValidatorUtil.validateName(customerData.lastName) ||
    (populateData && ValidatorUtil.validateName(populateData.lastName))

  const hasValidPhone =
    ValidatorUtil.validatePhone(cellphoneNumber.number) ||
    (populateData &&
      ValidatorUtil.validatePhone(populateData.cellphoneNumber.number))

  const hasValidEmail =
    ValidatorUtil.validateEmail(customerData.email) ||
    (populateData && ValidatorUtil.validateEmail(populateData.email))

  const hasValidZipCode =
    ValidatorUtil.validateZipCode(physicalAddress.postalCode) ||
    (populateData &&
      ValidatorUtil.validateZipCode(populateData.physicalAddress.postalCode))

  const hasValidHouseNumber =
    ValidatorUtil.validateHouseNumber(physicalAddress.houseNumber) ||
    (populateData &&
      ValidatorUtil.validateHouseNumber(
        populateData.physicalAddress.houseNumber
      ))

  const hasValidAddition =
    ValidatorUtil.validateAddition(physicalAddress.houseNumberAdditional) ||
    (populateData &&
      ValidatorUtil.validateAddition(
        populateData.physicalAddress.houseNumberAdditional
      ))

  const hasValidStreet =
    ValidatorUtil.validateStreet(physicalAddress.streetName) ||
    (populateData &&
      ValidatorUtil.validateStreet(populateData.physicalAddress.streetName))

  const hasValidKassakoopje = ValidatorUtil.validateKassakoopje(kassaKoopje)

  const hasValidRegistrationNumber = ValidatorUtil.validateRegistrationNumber(
    companyData.registrationNumber
  )

  const hasValidVatNumber = ValidatorUtil.validateVatNumber(
    companyData.vatNumber
  )
  // const hasValidDriverLicense = ValidatorUtil.validateDriverLicense(
  //   driversLicense.number
  // )
  // const hasValidissueDate = ValidatorUtil.validateDriverLicenseIssueDate(
  //   driversLicense.issueDate
  // )
  // const hasValiexpiryDate = ValidatorUtil.validateDriverLicenseExpirationDate(
  //   driversLicense.expiryDate,
  //   driversLicense.issueDate
  // )
  // const hasValidissuePlace = ValidatorUtil.validateDriverLicenseIssuePlace(
  //   driversLicense.issuePlace
  // )
  // const hasValidPlaceOfBirth = ValidatorUtil.validatePlaceOfBirth(
  //   customerData.placeOfBirth
  // )
  // const hasValidDateOfBirth = ValidatorUtil.validateDateOfBirth(
  //   customerData.dateOfBirth
  // )

  const isValidCustomer = ValidatorUtil.validateCustomer(
    hasValidFirstName,
    hasValidLastName,
    hasValidPhone,
    hasValidEmail,
    hasValidZipCode,
    hasValidHouseNumber,
    hasValidStreet
  )
  const isValidCompany = ValidatorUtil.validateCompany(
    hasValidBusinessName,
    hasValidRegistrationNumber,
    hasValidPhone,
    hasValidEmail
  )

  const [pushedDataLayer, setPushedDataLayer] = useState(false)
  const dataLayer = window.dataLayer || []
  if (!pushedDataLayer) {
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: `add_payent_information`,
      ecommerce: {
        currency: "EUR",
        value: `${reservationData.reservationTotal.value}`,
        tax: `${
          Math.round(reservationData.reservationTotal.value * 0.21 * 100) / 100
        }`,
        payment_type: "Pay Later",
        items: [
          {
            item_name: `${reservationData.rentals[0].vehicleClass.description}`,
            item_id: `${reservationData.rentals[0].vehicleClass.vehicleClassId}`,
            price: `${reservationData.reservationTotal.value}`,
            price_excl_vat: `${
              Math.round(
                (reservationData.reservationTotal.value / 1.21) * 100
              ) / 100
            }`,
            currency: "EUR",
            item_category: `${reservationData.rentals[0].vehicleClass.category.categoryId}`,
            quantity: 1,
            pick_up_location_id: `${reservationData.rentals[0].pickupLocation.locationId}`,
            pick_up_location: `${reservationData.rentals[0].pickupLocation.town}`,
          },
        ],
      },
    })
    setPushedDataLayer(true)
  }

  const handleDatalayer = () => {
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: `purchase`,
      ecommerce: {
        items: [
          {
            item_name: `${reservationData.rentals[0].vehicleClass.description}`,
            item_id: `${reservationData.rentals[0].vehicleClass.vehicleClassId}`,
            price: `${reservationData.reservationTotal.value}`,
            price_excl_vat: `${
              Math.round(
                (reservationData.reservationTotal.value / 1.21) * 100
              ) / 100
            }`,
            currency: "EUR",
            item_category: `${reservationData.rentals[0].vehicleClass.category.categoryId}`,
            quantity: 1,
            pick_up_location_id: `${reservationData.rentals[0].pickupLocation.locationId}`,
            pick_up_location: `${reservationData.rentals[0].pickupLocation.town}`,
            customer_email: `${newCustomerData?.email}`,
            customer_phone: `${newCustomerData?.cellphoneNumber?.number}`,
          },
        ],
        value: `${reservationData.reservationTotal.value}`,
        tax: `${
          Math.round(reservationData.reservationTotal.value * 0.21 * 100) / 100
        }`,
        currency: "EUR",
        transaction_id: `${reservationData.reservationReference}`,
      },
    })
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      const itemStr = localStorage.getItem("returnToCheckout")
      // if the item doesn't exist, return null
      if (!itemStr) {
        localStorage.removeItem("returnToCheckout")
      }
      const item = {
        reservationReference: reservationReference,
        expiry: reservationData.expiryDate,
      }
      localStorage.setItem("returnToCheckout", JSON.stringify(item))
    }
  }, [])

  const customerDataChanged = useRef(false)

  const handleChange = e => {
    setCustomerData({
      ...customerData,
      [e.target.name]: e.target.value,
    })
  }

  const handleChangeCompany = e => {
    setCompanyData({
      ...companyData,
      [e.target.name]: e.target.value,
    })
  }

  const handleChangePhysicalAddress = e => {
    setPhysicalAddress({
      ...physicalAddress,
      [e.target.name]: e.target.value,
    })
    customerDataChanged.current = !customerDataChanged.current
  }

  const handleChangePhone = e => {
    setcellphoneNumber({
      ...cellphoneNumber,
      [e.target.name]: e.target.value,
    })
  }
  const handleChangeDriverLicense = e => {
    setDriversLicense({
      ...driversLicense,
      [e.target.name]: e.target.value,
    })
  }

  useEffect(() => {
    setNewCustomerData({
      ...customerData,
      physicalAddress: {
        ...physicalAddress,
      },
      company: {
        ...companyData,
      },
      cellphoneNumber: {
        ...cellphoneNumber,
      },
    })
  }, [customerData, physicalAddress, companyData, cellphoneNumber])

  useEffect(() => {
    if (
      physicalAddress.postalCode !== "" &&
      physicalAddress.houseNumber !== ""
    ) {
      // Prevent running and infinite loop
      const isValidQuery = hasValidHouseNumber && hasValidZipCode
      if (isValidQuery) {
        //inputAddress into PostalCode API
        const addressQuery = {
          country: "NL",
          houseNumber: physicalAddress.houseNumber,
          zipCode: physicalAddress.postalCode,
        }

        Rentvisie.getAddress(addressQuery)
          .then(data => {
            setPhysicalAddress({
              ...physicalAddress,
              streetName: data.street,
            })
            setPostalcodeData(data)
          })
          .catch(error => {
            console.error(error)
          })
      }
    }
  }, [customerDataChanged.current]) // Only run on customerDataChanged

  const [isCompany, setIsCompany] = useState(false)
  const customerType = e => {
    if (e.target.value === "company") {
      setIsCompany(true)
    } else {
      setIsCompany(false)
    }
  }

  const [isCheckoutDeal, setIsCheckoutDeal] = useState(undefined)
  const [checkoutValue, setCheckoutValue] = useState(0)
  const handleCheckoutDeal = e => {
    if (e.target.checked) {
      setIsCheckoutDeal(kassaKoopje[0].optionId)
      setCheckoutValue(e.target.value)
    } else {
      setIsCheckoutDeal(undefined)
      setCheckoutValue(0)
    }
  }
  const totalDays = reservationData.rentals[0].days
  // Update Price
  const getPrice = (basePrice, checkOutValue): number => {
    return (
      Math.round((parseFloat(basePrice) + parseFloat(checkOutValue)) * 100) /
      100
    )
  }
  const getPriceOption = (price, type) => {
    if (type === "PerDay") {
      return price * totalDays
    }
    return price
  }

  console.log("newCustomerData", newCustomerData)
  // Update Reservation && Redirect Booking to Thank You Page
  const updateReservation = async (newCustomerData, isCheckoutDeal) => {
    try {
      setShowSpinner(true)
      const customerData = isSoftLogin
        ? {
            customerId: customerBData.customerId,
          }
        : newCustomerData
      console.log("post res", customerData)
      await postCustomer(reservationReference, bookingReference, customerData)

      if (isCheckoutDeal) {
        await Rentvisie.addOptionalOption(
          reservationReference,
          bookingReference,
          isCheckoutDeal
        )
      }

      await Rentvisie.confirmationEmail(reservationReference, bookingReference)
      handleDatalayer()

      const url = "/huren/bedankt"
      navigate(url)

      if (typeof window !== "undefined") {
        localStorage.setItem(
          "storedCustomerData",
          JSON.stringify(newCustomerData)
        )
      }
    } catch (e) {
      console.error(e)
    }
  }

  const expiryDate = new Date(reservationData.expiryDate).getTime()
  const calculateTimeLeft = () => {
    const now = new Date().getTime()
    const difference = expiryDate - now
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        minuten: Math.floor((difference / 1000 / 60) % 60),
        seconden: Math.floor((difference / 1000) % 60),
      }
    }
    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
  })

  const redirect = () => {
    window.location.replace("/")
  }

  const [showSpinner, setShowSpinner] = useState(false)

  // Update State for Populate All functions -- to be moved to separate file
  const populateForm = () => {
    console.log("r: ", recurringCustomer)
    setPopulateData(recurringCustomer)

    // update customerData too
    setCustomerData({
      firstName: recurringCustomer.firstName,
      lastName: recurringCustomer.lastName,
      email: recurringCustomer.email,
      dateOfBirth: recurringCustomer.dateOfBirth,
      placeOfBirth: recurringCustomer.placeOfBirth,
      iban: recurringCustomer.iban,
    })

    if (recurringCustomer.company) {
      setCompanyData({
        name: recurringCustomer.company.name,
        vatNumber: recurringCustomer.company.vatNumber,
        registrationNumber: recurringCustomer.company.registrationNumber,
      })
    }

    setPhysicalAddress({
      houseNumber: recurringCustomer.physicalAddress.houseNumber,
      houseNumberAdditional:
        recurringCustomer.physicalAddress.houseNumberAdditional,
      streetName: recurringCustomer.physicalAddress.streetName,
      city: "",
      postalCode: recurringCustomer.physicalAddress.postalCode,
      countryCode: "NL",
    })

    setcellphoneNumber({
      countryDialingCode: recurringCustomer.cellphoneNumber.countryDialingCode,
      number: recurringCustomer.cellphoneNumber.number,
    })
  }

  if (reservationData.reservationStatus !== "Draft") {
    navigate("/")
  }

  return (
    <div>
      <SEO
        title="Bert Jonk Autoverhuur - Checkout"
        index={false}
        follow={true}
      />
      <ReservationHeader activeStepIndex={3} />
      {!showSpinner ? (
        <div className="container">
          <div className="reservation-select-wrapper my-l">
            {/* {(!isSoftLogin
              ? !isCompany
                ? isValidCustomer
                : isValidCompany
              : true) && (
              <PaymentPopup
                reservationReference={reservationReference}
                bookingReference={bookingReference}
                customer={
                  isSoftLogin && customerBData
                    ? customerBData.customerId
                    : newCustomerData
                }
                open={openModal}
                setOpen={setOpenModal}
              />
            )} */}

            <div className="reservation-select-left">
              <div className="checkout-wrapper">
                <div className="reservation__title">
                  <h2>Maak jouw reservering compleet</h2>
                  <p style={{ fontStyle: "italic" }}>
                    <span className="bold">Let op:</span> U heeft nog{" "}
                    {timeLeft.minuten + timeLeft.seconden > 0
                      ? timeLeft.minuten > 0
                        ? `${timeLeft.minuten} minuten en ${timeLeft.seconden} seconden`
                        : `${timeLeft.seconden} seconden`
                      : redirect()}{" "}
                    om de reservering te voltooien. Daarna dient u het
                    reserveringsproces opnieuw te doorlopen.
                  </p>
                </div>

                {!isLogged ? (
                  <>
                    <h3>Terugkerende klant?</h3>
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        marginBottom: "24px",
                      }}
                    >
                      {
                        <button
                          className="button-outline-primary"
                          onClick={() => setIsSoftLogin(!isSoftLogin)}
                        >
                          {!isSoftLogin
                            ? `Heb je een account?`
                            : `Nieuwe klant?`}
                        </button>
                      }
                      {recurringCustomer && !isSoftLogin && (
                        <button
                          className="button-outline-primary"
                          onClick={populateForm}
                        >
                          Gegevens Invullen
                        </button>
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div onChange={customerType}>
                  <input
                    type="radio"
                    id="individual"
                    value="individual"
                    name="customerType"
                    defaultChecked
                  />
                  <label
                    className=""
                    htmlFor="individual"
                    style={{ paddingRight: 20, paddingLeft: 5 }}
                  >
                    {" "}
                    Particulier{" "}
                  </label>
                  <input
                    type="radio"
                    id="company"
                    value="company"
                    name="customerType"
                  />
                  <label
                    className=""
                    htmlFor="company"
                    style={{ paddingRight: 20, paddingLeft: 5 }}
                  >
                    {" "}
                    Bedrijf{" "}
                  </label>
                </div>
                {isSoftLogin ? (
                  isLogged ? (
                    <div>
                      {noFound ? (
                        <div style={{ marginTop: "16px" }}>
                          <h3>Geen klant gevonden</h3>
                          <p>
                            Wij hebben geen klant gevonden met de ingevoerde
                            gegevens. Probeer het opnieuw of vul de gegevens
                            handmatig in.
                          </p>
                        </div>
                      ) : (
                        <div>
                          {customerBData.firstName && (
                            <div>
                              <h1>Welkom</h1>
                              <h3>Controleer of u het bent</h3>
                              <p>Voornaam: {customerBData.firstName}</p>
                              <p>Achternaam: {customerBData.lastName}</p>
                              <p>E-mail: {customerBData.email}</p>
                              <p>Telefoon: {customerBData.phone}</p>
                              <p>
                                {!dateOfBirth &&
                                  `Bedrijfsnaam: ${customerBData.company}`}
                              </p>
                              <p
                                style={{
                                  fontWeight: 700,
                                  padding: "12px 0",
                                  fontStyle: "italic",
                                  // borderBottom: '1px dotted #000'
                                }}
                                onClick={() => informationHandler("first")}
                              >
                                {">>"} Reserveren zonder inlog
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="form-row">
                      <div className="form-100">
                        <div
                          className={`textOnInput ${isEmailValid && "valid"} ${
                            email && !isEmailValid && "not-valid"
                          }`}
                        >
                          <label htmlFor="email">Email</label>
                          <input
                            type="text"
                            name="email"
                            onChange={e => setEmail(e.target.value)}
                            className="form-input-alt"
                          ></input>
                        </div>
                      </div>
                      {!isCompany ? (
                        <div className="form-100">
                          <div
                            className={`textOnInput ${
                              isDateOfBirthValid && "valid"
                            } ${
                              dateOfBirth && !isDateOfBirthValid && "not-valid"
                            }`}
                          >
                            <label htmlFor="dateOfBirth">Geboortedatum</label>
                            <input
                              type="date"
                              name="dateOfBirth"
                              onChange={e => setDateOfBirth(e.target.value)}
                              className="form-input-alt"
                            ></input>
                          </div>
                        </div>
                      ) : (
                        <div className="form-100">
                          <div
                            className={`textOnInput ${
                              isRegistrationNumberValid && "valid"
                            } ${
                              registrationNumber &&
                              !isRegistrationNumberValid &&
                              "not-valid"
                            }`}
                          >
                            <label htmlFor="registrationNumber">
                              KVK-nummer
                            </label>
                            <input
                              type="text"
                              name="registrationNumber"
                              onChange={e =>
                                setRegistrationNumber(e.target.value)
                              }
                              className="form-input-alt"
                            ></input>
                          </div>
                        </div>
                      )}
                    </div>
                  )
                ) : (
                  <>
                    {isCompany && (
                      <>
                        <div className="form-row">
                          <div className="form-100">
                            <div
                              className={`textOnInput ${
                                hasValidBusinessName && "valid"
                              } ${
                                companyData.name &&
                                !hasValidBusinessName &&
                                "not-valid"
                              }`}
                            >
                              <label htmlFor="name">Bedrijfsnaam</label>
                              <input
                                type="text"
                                name="name"
                                onChange={handleChangeCompany}
                                className="form-input-alt"
                                value={
                                  `${companyData?.name}`
                                }
                              ></input>
                            </div>
                          </div>
                        </div>
                        {/* <div className="form-row">
                          <div className="form-100">
                            <div
                              className={`textOnInput ${
                                hasValidVatNumber && "valid"
                              } ${
                                companyData.vatNumber &&
                                !hasValidVatNumber &&
                                "not-valid"
                              }`}
                            >
                              <label htmlFor="vatNumber">
                                BTW-nummer (optioneel)
                              </label>
                              <input
                                type="text"
                                name="vatNumber"
                                onChange={handleChangeCompany}
                                className="form-input-alt"
                              ></input>
                            </div>
                          </div>
                        </div> */}
                        <div className="form-row">
                          <div className="form-100">
                            <div
                              className={`textOnInput ${
                                hasValidRegistrationNumber && "valid"
                              } ${
                                companyData.registrationNumber &&
                                !hasValidRegistrationNumber &&
                                "not-valid"
                              }`}
                            >
                              <label htmlFor="registrationNumber">
                                KvK-nummer
                              </label>
                              <input
                                type="text"
                                name="registrationNumber"
                                onChange={handleChangeCompany}
                                className="form-input-alt"
                                value={
                                  `${companyData?.registrationNumber}`
                                }
                              ></input>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="form-row">
                      <div className="form-50">
                        <div
                          className={`textOnInput ${
                            hasValidFirstName && "valid"
                          } ${
                            customerData.firstName &&
                            !hasValidFirstName &&
                            "not-valid"
                          }`}
                        >
                          <label htmlFor="firstName">Voornaam</label>
                          <input
                            type="text"
                            name="firstName"
                            onChange={handleChange}
                            defaultValue={
                              populateData && `${populateData.firstName}`
                            }
                            className="form-input-alt"
                          ></input>
                        </div>
                      </div>
                      <div className="form-50">
                        <div
                          className={`textOnInput ${
                            hasValidLastName && "valid"
                          } ${
                            customerData.lastName &&
                            !hasValidLastName &&
                            "not-valid"
                          }`}
                        >
                          <label htmlFor="lastName">Achternaam</label>
                          <input
                            type="text"
                            name="lastName"
                            onChange={handleChange}
                            defaultValue={
                              populateData && `${populateData.lastName}`
                            }
                            className="form-input-alt"
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-33">
                        <div
                          className={`
                                            textOnInput 
                                            ${
                                              hasValidZipCode &&
                                              (hasValidStreet ||
                                                physicalAddress.streetName ===
                                                  "") &&
                                              "valid"
                                            } 
                                            ${
                                              customerData.postalCode &&
                                              (!hasValidZipCode ||
                                                physicalAddress.streetName ==
                                                  null) &&
                                              "not-valid"
                                            }
                                            `}
                        >
                          <label htmlFor="postalCode">Postcode</label>
                          <input
                            type="text"
                            name="postalCode"
                            className="form-input-alt"
                            id="postalCode"
                            maxLength={6}
                            onChange={handleChangePhysicalAddress}
                            defaultValue={
                              populateData &&
                              `${populateData.physicalAddress.postalCode}`
                            }
                          ></input>
                        </div>
                      </div>
                      <div className="form-33">
                        <div
                          className={`
                                            textOnInput 
                                            ${
                                              hasValidHouseNumber &&
                                              (hasValidStreet ||
                                                physicalAddress.streetName ===
                                                  "") &&
                                              "valid"
                                            } 
                                            ${
                                              customerData.houseNumber &&
                                              (!hasValidHouseNumber ||
                                                physicalAddress.streetName ==
                                                  null) &&
                                              "not-valid"
                                            }
                                            `}
                        >
                          <label htmlFor="houseNumber">Huisnummer</label>
                          <input
                            type="text"
                            name="houseNumber"
                            className="form-input-alt"
                            id="houseNumber"
                            maxLength={6}
                            onBlur={handleChangePhysicalAddress}
                            defaultValue={
                              populateData &&
                              `${populateData.physicalAddress.houseNumber}`
                            }
                          ></input>
                        </div>
                      </div>
                      <div className="form-33">
                        <div
                          className={`textOnInput ${
                            hasValidAddition && "valid"
                          } ${
                            customerData.houseNumberAdditional &&
                            !hasValidAddition &&
                            "not-valid"
                          }`}
                        >
                          <label htmlFor="houseNumberAdditional">
                            Toevoeging
                          </label>
                          <input
                            type="text"
                            name="houseNumberAdditional"
                            onChange={handleChangePhysicalAddress}
                            className="form-input-alt"
                            defaultValue={
                              populateData &&
                              `${populateData.physicalAddress.houseNumberAdditional}`
                            }
                          ></input>
                        </div>
                      </div>
                    </div>
                    {(postalcodeData || populateData) && (
                      <div className="form-row">
                        <div className="form-100">
                          <div
                            className={`textOnInput ${
                              hasValidStreet && "valid"
                            } ${
                              ((physicalAddress.streetName == null &&
                                populateData &&
                                populateData.physicalAddress.streetName ==
                                  null) ||
                                !hasValidStreet) &&
                              "not-valid"
                            }`}
                          >
                            <label htmlFor="streetName">Straat</label>
                            <input
                              type="text"
                              className="form-input-alt"
                              name="streetName"
                              value={
                                postalcodeData
                                  ? `${postalcodeData.street}, ${postalcodeData.city}`
                                  : populateData
                                  ? `${populateData.physicalAddress.streetName}`
                                  : ""
                              }
                              placeholder="Controleer postcode & huisnummer"
                              disabled
                            ></input>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="form-row">
                      <div className="form-100">
                        <div
                          className={`textOnInput ${hasValidPhone && "valid"} ${
                            cellphoneNumber.number &&
                            !hasValidPhone &&
                            "not-valid"
                          }`}
                        >
                          <label htmlFor="number">Telefoon</label>
                          <input
                            type="phone"
                            name="number"
                            onChange={handleChangePhone}
                            className="form-input-alt"
                            defaultValue={
                              populateData &&
                              `${populateData.cellphoneNumber.number}`
                            }
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-100">
                        <div
                          className={`textOnInput ${hasValidEmail && "valid"} ${
                            customerData.email && !hasValidEmail && "not-valid"
                          }`}
                        >
                          <label htmlFor="email">Email</label>
                          <input
                            type="email"
                            name="email"
                            onChange={handleChange}
                            className={"form-input-alt"}
                            defaultValue={
                              populateData && `${populateData.email}`
                            }
                          ></input>
                        </div>
                      </div>
                    </div>

                    {!isCompany && (
                      <>
                        {/* <div className="form-row">
                          <div className="form-50">
                            <div
                              className={`textOnInput ${
                                hasValidPlaceOfBirth && "valid"
                              } ${
                                customerData.placeOfBirth &&
                                !hasValidPlaceOfBirth &&
                                "not-valid"
                              }`}
                            >
                              <label htmlFor="number">Geboorteplaats</label>
                              <input
                                type="text"
                                name="placeOfBirth"
                                onChange={handleChange}
                                className="form-input-alt"
                              ></input>
                            </div>
                          </div>
                          <div className="form-33">
                            <div
                              className={`textOnInput ${
                                hasValidDateOfBirth && "valid"
                              } ${
                                customerData.dateOfBirth &&
                                !hasValidDateOfBirth &&
                                "not-valid"
                              }`}
                            >
                              <label htmlFor="number">Geboortedatum</label>
                              <input
                                type="date"
                                name="dateOfBirth"
                                onChange={handleChange}
                                className="form-input-alt"
                              ></input>
                            </div>
                          </div>
                        </div> */}

                        {/* <div className="form-row">
                          <div className="form-50">
                            <div
                              className={`textOnInput ${
                                hasValidDriverLicense && "valid"
                              } ${
                                driversLicense.number &&
                                !hasValidDriverLicense &&
                                "not-valid"
                              }`}
                            >
                              <label htmlFor="number">Rijbewijs Nummer</label>
                              <input
                                type="text"
                                name="number"
                                onChange={handleChangeDriverLicense}
                                className="form-input-alt"
                              ></input>
                            </div>
                          </div>
                          <div className="form-50">
                            <div
                              className={`textOnInput ${
                                hasValidissuePlace && "valid"
                              } ${
                                driversLicense.issuePlace &&
                                !hasValidissuePlace &&
                                "not-valid"
                              }`}
                            >
                              <label htmlFor="number">
                                Rijbewijs Uitgifte plaats
                              </label>
                              <input
                                type="text"
                                name="issuePlace"
                                onChange={handleChangeDriverLicense}
                                className="form-input-alt"
                              ></input>
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-50">
                            <div
                              className={`textOnInput ${
                                hasValidissueDate && "valid"
                              } ${
                                driversLicense.issueDate &&
                                !hasValidissueDate &&
                                "not-valid"
                              }`}
                            >
                              <label htmlFor="number">
                                Rijbewijs Uitgifte datum
                              </label>
                              <input
                                type="date"
                                name="issueDate"
                                onChange={handleChangeDriverLicense}
                                className="form-input-alt"
                              ></input>
                            </div>
                          </div>
                          <div className="form-50">
                            <div
                              className={`textOnInput ${
                                hasValiexpiryDate && "valid"
                              } ${
                                driversLicense.expiryDate &&
                                !hasValiexpiryDate &&
                                "not-valid"
                              }`}
                            >
                              <label htmlFor="number">
                                Rijbewijs Vervaldatum
                              </label>
                              <input
                                type="date"
                                name="expiryDate"
                                onChange={handleChangeDriverLicense}
                                className="form-input-alt"
                              ></input>
                            </div>
                          </div>
                        </div> */}
                      </>
                    )}
                  </>
                )}
                {isValidCustomer && (
                  <>
                    {hasValidKassakoopje && (
                      <div className="checkout-deal-wrapper">
                        <div className="checkout-extra">
                          <h2>Unieke aanbieding!</h2>
                          <input
                            type="checkbox"
                            id="kassakoopje"
                            name="kassakoopje"
                            value={kassaKoopje[0].price.value}
                            onChange={handleCheckoutDeal}
                          />
                          <label htmlFor="kassakoopje">
                            <div className="extrabox">
                              <h3>
                                {kassaKoopje[0].description} (€{" "}
                                {(
                                  (kassaKoopje[0].price.value * 100) /
                                  100
                                ).toFixed(2)}
                                )
                              </h3>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: kassaKoopje[0].labelMarkup,
                                }}
                              />
                            </div>
                          </label>
                        </div>
                      </div>
                    )}
                    {/* TODO: Add Payment */}
                    {/* <div className="payment"><h2>Betaling</h2></div> */}
                    <div className="shopping-cart" style={{ marginTop: 20 }}>
                      <h2>Winkelmandje</h2>
                      <span style={{ fontSize: 10 }}>
                        Aantal dag(en): {totalDays}
                      </span>
                      <div className="shopping-cart-wrapper">
                        <div className="shopping-cart-category">
                          <span className="bold">Voertuig</span>
                          <hr></hr>
                          <div className="shopping-cart-row">
                            <div className="shopping-cart-left">
                              {reservationData.rentals[0].vehicleClass
                                .labelMarkup
                                ? reservationData.rentals[0].vehicleClass.labelMarkup?.replace(
                                    /(<([^>]+)>)/gi,
                                    ""
                                  )
                                : reservationData.rentals[0].vehicleClass
                                    .description}
                              {hasDiscount && (
                                <span
                                  style={{ color: "red", marginRight: "12px" }}
                                >
                                  {" "}
                                  30% KORTING!
                                </span>
                              )}
                            </div>
                            <div className="shopping-cart-right">
                              €{" "}
                              {getBasePrice(
                                reservationData.rentals[0].price.value,
                                totalDays,
                                isCompany
                              ).toFixed(2)}
                            </div>
                          </div>
                        </div>
                        {rentalHasInsuranceOptions && (
                          <div className="shopping-cart-category">
                            <span className="bold">
                              Verzekering & Eigen Risico
                            </span>
                            <hr></hr>
                            <div className="shopping-cart-row">
                              <div className="shopping-cart-left">
                                {insuranceOptionsData[0].description}
                              </div>
                              <div className="shopping-cart-right">
                                {isCompany
                                  ? `€ ${(
                                      getPriceOption(
                                        insuranceOptionsData[0].price.value,
                                        insuranceOptionsData[0].price.unitType
                                      ) / 1.21
                                    ).toFixed(2)}`
                                  : `€ ${(
                                      (getPriceOption(
                                        insuranceOptionsData[0].price.value,
                                        insuranceOptionsData[0].price.unitType
                                      ) *
                                        100) /
                                      100
                                    ).toFixed(2)}`}
                              </div>
                            </div>
                          </div>
                        )}
                        {rentalHasMileageOptions && (
                          <div className="shopping-cart-category">
                            <span className="bold">Kilometers</span>
                            <hr></hr>
                            <div className="shopping-cart-row">
                              <div className="shopping-cart-left">
                                {mileagePackageOptionsData[0].description}
                              </div>
                              <div className="shopping-cart-right">
                                {isCompany
                                  ? `€ ${(
                                      getPriceOption(
                                        mileagePackageOptionsData[0].price
                                          .value,
                                        mileagePackageOptionsData[0].price
                                          .unitType
                                      ) / 1.21
                                    ).toFixed(2)}`
                                  : `€ ${(
                                      (getPriceOption(
                                        mileagePackageOptionsData[0].price
                                          .value,
                                        mileagePackageOptionsData[0].price
                                          .unitType
                                      ) *
                                        100) /
                                      100
                                    ).toFixed(2)}`}
                              </div>
                            </div>
                          </div>
                        )}
                        {rentalHasOptionalOptions && (
                          <div className="shopping-cart-category">
                            <span className="bold">Overige</span>
                            <hr></hr>
                            {optionalOptionsData.map(option => (
                              <div
                                key={option.optionId}
                                className="shopping-cart-row"
                              >
                                <div className="shopping-cart-left">
                                  {option.description}
                                </div>
                                <div className="shopping-cart-right">
                                  {isCompany
                                    ? `€ ${(
                                        getPriceOption(
                                          option.price.value,
                                          option.price.unitType
                                        ) / 1.21
                                      ).toFixed(2)}`
                                    : `€ ${(
                                        (getPriceOption(
                                          option.price.value,
                                          option.price.unitType
                                        ) *
                                          100) /
                                        100
                                      ).toFixed(2)}`}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        {/* TODO: CHECK FOR MULTIPLE DAYS */}
                        {isCheckoutDeal && (
                          <div className="shopping-cart-category">
                            <span className="bold">Kassakoopje!</span>
                            <hr></hr>
                            <div className="shopping-cart-row">
                              <div className="shopping-cart-left">
                                {kassaKoopje[0].description}
                              </div>
                              <div className="shopping-cart-right">
                                {isCompany
                                  ? `€ ${(
                                      kassaKoopje[0].price.value / 1.21
                                    ).toFixed(2)}`
                                  : `€ ${(
                                      (kassaKoopje[0].price.value * 100) /
                                      100
                                    ).toFixed(2)}`}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="shopping-cart-category">
                          <span className="bold">Totaal</span>
                          <hr></hr>
                          <div className="shopping-cart-row">
                            <div className="shopping-cart-left">
                              Totaal (excl.)
                            </div>
                            <div className="shopping-cart-right">
                              {`€ ${" "} ${(
                                reservationData.reservationTotal.value / 1.21
                              )
                                .toFixed(2)
                                .toString()
                                .replace(".", ",")}`}
                            </div>
                          </div>
                          <div className="shopping-cart-row">
                            <div className="shopping-cart-left">BTW</div>
                            <div className="shopping-cart-right">
                              {`€ ${" "} ${(
                                (reservationData.reservationTotal.value /
                                  1.21) *
                                0.21
                              )
                                .toFixed(2)
                                .toString()
                                .replace(".", ",")}`}
                            </div>
                          </div>
                          <div className="shopping-cart-row bold">
                            <div className="shopping-cart-left">
                              Totaal (incl.)
                            </div>
                            <div className="shopping-cart-right">
                              {`€ ${" "} ${reservationData.reservationTotal.value
                                .toFixed(2)
                                .toString()
                                .replace(".", ",")}`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {!isSoftLogin &&
                  (isValidCustomer ? (
                    <button
                      className="button-primary"
                      onClick={() =>
                        updateReservation(newCustomerData, isCheckoutDeal)
                      }
                      style={{ width: "100%" }}
                    >
                      Reserveren
                    </button>
                  ) : (
                    <Button
                      className="btn btn-secondary btn-lg"
                      style={{ marginLeft: "auto" }}
                    >
                      Vul de gevraagde gegevens in!
                    </Button>
                  ))}

                {isSoftLogin &&
                  (isLogged && !noFound ? (
                    <button
                      className="button-primary"
                      onClick={() =>
                        updateReservation(newCustomerData, isCheckoutDeal)
                      }
                      style={{ width: "100%" }}
                    >
                      Reserveren
                    </button>
                  ) : (
                    <button
                      className="button-primary"
                      onClick={
                        noFound
                          ? () => informationHandler("again")
                          : () => softLoginHandler()
                      }
                    >
                      {noFound ? "Probeer het opnieuw" : "Inloggen"}
                    </button>
                  ))}

                {/* <div style={{ display: "flex" }}>
                  {isSoftLogin ? (
                    isLogged && !noFound ? (
                      <div
                        style={{
                          display: "flex",
                          gap: 10,
                        }}
                      >
                        {isCompany && (
                          <div>
                            <button
                              className="button-secondary"
                              onClick={() =>
                                updateReservation(
                                  newCustomerData,
                                  isCheckoutDeal
                                )
                              }
                            >
                              Reserveren
                            </button>
                          </div>
                        )}
                        <div>
                          <button
                            className="button-primary"
                            onClick={() => handlePaymentPopup()}
                          >
                            Naar betalen
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <button
                          className="button-primary"
                          onClick={
                            noFound
                              ? () => informationHandler("again")
                              : () => softLoginHandler()
                          }
                        >
                          {noFound ? "Probeer het opnieuw" : "Inloggen"}
                        </button>
                      </div>
                    )
                  ) : (
                    <div>
                      {isValidCustomer || isValidCompany ? (
                        !isCompany ? (
                          <button
                            className="button-primary"
                            onClick={() => handlePaymentPopup()}
                          >
                            Naar betalen
                          </button>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              gap: 10,
                            }}
                          >
                            <button
                              className="button-secondary"
                              onClick={() =>
                                updateReservation(
                                  newCustomerData,
                                  isCheckoutDeal
                                )
                              }
                            >
                              Reserveren
                            </button>
                            <button
                              className="button-primary"
                              onClick={() => handlePaymentPopup()}
                            >
                              Betalen
                            </button>
                          </div>
                        )
                      ) : (
                        <Button
                          className="btn btn-secondary btn-lg"
                          style={{ marginLeft: "auto" }}
                          disabled
                        >
                          Vul de gevraagde gegevens in
                        </Button>
                      )}
                    </div>
                  )}
                </div> */}
              </div>
            </div>
            <div className="reservation-select-right">
              <div className="reservation-contact">
                <div className="reservation-product__photo">
                  <img
                    src={vehicleClass.images[0]?.imageUrl}
                    className="img-fluid rounded"
                    alt={vehicleClass.images[0]?.fileName}
                    title={vehicleClass.description}
                  />
                </div>
                <div className="reservation-product__text">
                  <p>
                    {pickupLocation.town} <br></br>
                    <a
                      href={encodeURI(
                        `https://www.google.com/maps/search/?api=1&query=${pickupLocation.streetAddress}`
                      )}
                      target="_blank"
                    >
                      {pickupLocation.streetAddress}
                    </a>
                  </p>
                  <p>
                    Van: {pickupDate} om {pickupTime} <br></br>
                    Tot: {dropOffDate} om {dropOffTime} <br></br>({totalDays}{" "}
                    dag{totalDays > 1 && 'en'})
                  </p>
                  <h3 className="bold">
                    {reservationData.rentals[0].vehicleClass.labelMarkup
                      ? reservationData.rentals[0].vehicleClass.labelMarkup?.replace(
                          /(<([^>]+)>)/gi,
                          ""
                        )
                      : reservationData.rentals[0].vehicleClass.description}
                  </h3>
                  <p className="small-top">Nu voor slechts:</p>
                  <h3 className="extrabox__price">
                    {includeVat === "yes"
                      ? `€ ${(
                          getPrice(
                            reservationData.reservationTotal.value,
                            checkoutValue
                          ) - 1.75
                        ).toFixed(2)} incl. BTW`
                      : `€ ${(
                          (getPrice(
                            reservationData.reservationTotal.value,
                            checkoutValue
                          ) -
                            1.75) /
                          1.21
                        ).toFixed(2)} excl. BTW`}{" "}
                    <br />
                    <span className="small-top">
                      excl. €{" "}
                      {includeVat === "yes" || !isCompany
                        ? `1.75`
                        : `${(1.75 / 1.21).toFixed(2)}`}{" "}
                      Administratiekosten
                    </span>
                  </h3>
                  {/* {isValidCustomer ? (
                    <button
                      className="button-primary"
                      onClick={() =>
                        updateReservation(newCustomerData, isCheckoutDeal)
                      }
                      style={{ width: "100%" }}
                    >
                      Reserveren
                    </button>
                  ) : (
                    <Button
                      className="btn btn-secondary btn-lg"
                      style={{ marginLeft: "auto" }}
                      disabled
                    >
                      Vul de gevraagde gegevens in
                    </Button>
                  )} */}
                </div>
              </div>
              <Contact />
              <FreeParking />
            </div>
          </div>
        </div>
      ) : (
        <div className="spinner-background">
          <Spinner />
        </div>
      )}
    </div>
  )
}

export default ReservationCheckout
