const Skeleton = ({ length }) => {
    let arrayLength;
    if (length) {
        arrayLength = length
    } else {
        arrayLength = 1
    }
    // const skeletons = new Array(arrayLength); for (let i = 0; i < arrayLength; ++i) skeletons[i] = 0;
    const items = new Array(arrayLength).fill(0)
    return (
        items.map((item, index) => (
            <div className="vehicle-wrapper" key={index}>
                <div className="vehicle-select__image">
                    <div className="skeleton skeleton__image"></div>
                </div>
                <div className="vehicle-select__content">
                    <div className="skeleton skeleton__text"></div>
                    <div className="skeleton skeleton__text-area"></div>

                    <div className="features">
                        <div className="skeleton skeleton__text"></div>
                        <div className="skeleton skeleton__text"></div>
                        <div className="skeleton skeleton__text"></div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="skeleton skeleton__button"></div>
                        <div className="skeleton skeleton__button"></div>
                    </div>
                </div>
            </div>
        ))
    )
}

export default Skeleton;