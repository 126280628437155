import React from "react";
import { useForm, ValidationError } from '@formspree/react';
import ContactFormContent from "../forms/contact";

const ContactForm = () => {
    const [state, handleSubmit] = useForm('xknydjvz');
    if (state.succeeded) {
        return (
            <div className="container">
                <div className="contact-form">
                    <div className="contact-form__text">
                        <div className="contact-form__title">
                            Wij hebben uw contactaanvraag in goede orde ontvangen!
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="container">
            <div className="contact-form">
                <div className="contact-form__text">
                    <div className="contact-form__title">
                        Contact Bert Jonk Autoverhuur
                    </div>
                    <p>
                        Kom in contact met ons....
                    </p>
                </div>
                <ContactFormContent />
                {/* <form onSubmit={handleSubmit} id='contact-form'>
                    <div className='form-row'>
                        <div className="form-50">
                            <input type="text" name="voornaam" id="voornaam" placeholder="Voornaam" className="form-input" required />
                            <ValidationError
                                prefix="voornaam"
                                field="voornaam"
                                errors={state.errors}
                            />
                        </div>
                        <div className="form-50">
                            <input type="text" name="achternaam" placeholder="Achternaam" className="form-input" required />
                            <ValidationError
                                prefix="achternaam"
                                field="achternaam"
                                errors={state.errors}
                            />
                        </div>
                        <div className="form-50">
                            <input type="email" name="email" placeholder="E-mail" className="form-input" required />
                            <ValidationError
                                prefix="Email"
                                field="email"
                                errors={state.errors}
                            />
                        </div>
                        <div className="form-50">
                            <input type="phone" name="phone" placeholder="Telefoon" className="form-input" required />
                            <ValidationError
                                prefix="Phone"
                                field="phone"
                                errors={state.errors}
                            />
                        </div>
                        <div className="form-100">
                            <textarea name="message" style={{ "height": "300px" }} form='contact-form' placeholder="Jouw bericht..." className="form-input" required />
                            <ValidationError
                                prefix="Message"
                                field="message"
                                errors={state.errors}
                            />
                        </div>
                    </div>

                    <button type="submit" className='button-primary' disabled={state.submitting}>
                        Contactformulier Indienen
                    </button>
                </form> 
                 */}
            </div>
        </div>
    )
}

export default ContactForm