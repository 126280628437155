import { CustomerData } from '../rentvisie';
import { GeoLocation } from './location';
import { Price } from './price';
import { Rental } from './rental';
import { ExcessAmount, VehicleClass } from './vehicle-class';

export interface PickupLocation {
  locationId: number;
  streetAddress: string;
  town: string;
  country: string;
  areaCode: string;
  geoLocation: GeoLocation;
  primaryLocation: boolean;
}

export interface DropOffLocation {
  locationId: number;
  streetAddress: string;
  town: string;
  country: string;
  areaCode: string;
  geoLocation: GeoLocation;
  primaryLocation: boolean;
}

export interface TicketPrice {
  value: number;
  currency: string;
}

export interface PriceClass {
  personType: string;
  price: Price;
}

export interface IdentityDocument {
  documentType: string;
  number: string;
}

export interface CellphoneNumber {
  countryDialingCode: string;
  number: string;
}

export interface ContactNumber {
  countryDialingCode: string;
  number: string;
}

export interface EmergencyContact {
  contactName: string;
  contactNumber: ContactNumber;
}

export interface Company {
  name: string;
  vatNumber: string;
}

export interface PhyiscalAddress {
  houseNumber: string;
  houseNumberAdditional?: string;
  streetName?: string;
  city?: string;
  postalCode: string;
  countryCode?: string;
}

export interface BookingTotal {
  value: number;
  currency: string;
}

export interface Booking extends Rental {
  bookingReference: string;
  thirdPartyReference: string;
  bookingStatus: string;
  customer: CustomerData;
  bookingTotal: BookingTotal;
  rentalStatus: RentalStatus;
  vehicle?: Vehicle;
  // TODO Update typing
  options: any[];
  pickupLocation: PickupLocation;
  dropOffLocation: DropOffLocation;
  pickupDate: string;
  pickupTime: string;
  dropOffDate: string;
  dropOffTime: string;
  vehcleClass: VehicleClass;
}

interface Vehicle {
  make: string;
  model: string;
  registrationNumber: string;
  vehicleId: number;
}

export interface Trip {
  tripId: number;
  routeNumber: string;
  pickupDate: string;
  pickupTime: string;
  dropOffDate: string;
  dropOffTime: string;
  pickupLocation: PickupLocation;
  dropOffLocation: DropOffLocation;
  availableSeats: number;
  ticketPrice: TicketPrice;
  priceClasses: PriceClass[];
  bookings: Booking[];
}

export interface Feature {
  featureId: number;
  description: string;
  labelMarkup: string;
  featureCategory: string;
  price: Price;
}

export interface Insurance {
  insuranceId: number;
  description: string;
  overview: string;
  insuranceCategory: string;
  glassIncluded: boolean;
  tyresIncluded: boolean;
  price: Price;
  excessAmount: ExcessAmount;
  default: boolean;
}

export interface ReservationTotal {
  value: number;
  currency: string;
}

export interface DepositAmount {
  value: number;
  currency: string;
}

export interface Reservation {
  reservationReference: string;
  thirdPartyReference?: string;
  reservationTotal: ReservationTotal;
  reservationStatus: ReservationStatus;
  transactionDate: Date | string; // TODO: paolo pick one
  expiryDate: Date | string; // TODO: paolo pick one
  trips?: Trip[]; // TODO: @boris please verify if trips can be undefined
  rentals: Booking[];
  depositAmount: DepositAmount;
}

export enum ReservationStatus {
  Draft = 'Draft',
  CancelledTimeout = 'CancelledTimeout',
  Cancelled = 'Cancelled',
  PendingPayment = 'PendingPayment',
  Processed = 'Processed',
}

export enum RentalStatus {
  Draft = 'Draft',
  Scheduled = 'Scheduled',
  InProgress = 'InProgress',
  Completed = 'Completed',
}

export interface DepositResponse {
  success: boolean;
  message: string;
  amount: {
    value: number;
    currency: string;
  }
}