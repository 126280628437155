import React from 'react'
import SEO from '../components/seo'
import { Button } from '@mui/material'
import Layout from '../components/layout'

const Cms = () => {
  return (
    <Layout>
        <SEO follow={false} index={false} title="Content aanpassen Bert Jonk" description="Pas hier alle content aan van de website" />
        <div style={{minHeight: '70vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Button variant='contained' href='https://bja-cms-staging.herokuapp.com/admin'>Naar CMS</Button>
        </div>
    </Layout>
  )
}

export default Cms