import { TimeOption, PickupHoursAndMinutes } from "../interfaces/DateTimeInterfaces";
import add from 'date-fns/add'
import isSameDay from 'date-fns/isSameDay'

export const defaultStartDate = () => {
    const today = new Date();
    const tomorrow = add(today, { days: 1});
    // tomorrow.setDate(tomorrow.getDate() + 1)

    if (today.getHours() < 17) {
        return today;
    } else {
        return tomorrow;
    }
}

export const defaultEndDate = () => {
    const today = new Date();
    const todayPlusNine = add(today, { days: 8});
    return todayPlusNine;
}

export const filterTimes = (startDate: Date) => {

    // TODO: Add logic for if Date is Holiday
    switch (startDate.getDay()) {
        case 0:
            return { startHours: 8, startMinutes: 0, endHours: 18, endMinutes: 0 }
        // return { startHours: openinghours.sunday.openFrom.hours, startMinutes: openinghours.sunday.openFrom.minutes, endHours: openinghours.sunday.closedAt.hours, endMinutes: openinghours.sunday.closedAt.minute }
        case 1:
            return { startHours: 8, startMinutes: 0, endHours: 18, endMinutes: 0 }
        case 2:
            return { startHours: 8, startMinutes: 0, endHours: 18, endMinutes: 0 }
        case 3:
            return { startHours: 8, startMinutes: 0, endHours: 18, endMinutes: 0 }
        case 4:
            return { startHours: 8, startMinutes: 0, endHours: 18, endMinutes: 0 }
        case 5:
            return { startHours: 8, startMinutes: 0, endHours: 18, endMinutes: 0 }
        case 6:
            return { startHours: 8, startMinutes: 0, endHours: 17, endMinutes: 0 }
    }
}

// Define default options for time select
const timeArrayPickup = [];
for (let i = 6; i < 24; i++) {
    for (let j = 0; j < 2; j++) {
        if (i < 10) {
            timeArrayPickup.push({
                value: `0${i}:${j === 0 ? '00' : j * 30}`,
                label: `${i}:${j === 0 ? '00' : j * 30}`
            });
        } else if (i >= 10) {
            timeArrayPickup.push({
                value: `${i}:${j === 0 ? '00' : j * 30}`,
                label: `${i}:${j === 0 ? '00' : j * 30}`
            });
        }
    }
}

export const timeOptionsPickup: TimeOption[] = timeArrayPickup;

// Define default options for return time select
const timeArrayReturn = [];
for (let i = 6; i < 24; i++) {
    for (let j = 0; j < 2; j++) {
        if (i < 10) {
            timeArrayReturn.push({
                value: `0${i}:${j === 0 ? '00' : j * 30}`,
                label: `${i}:${j === 0 ? '00' : j * 30}`
            });
        } else if (i >= 10) {
            timeArrayReturn.push({
                value: `${i}:${j === 0 ? '00' : j * 30}`,
                label: `${i}:${j === 0 ? '00' : j * 30}`
            });
        }
    }
}
timeArrayReturn.push({
    value: `23:45`,
    label: `23:45`
})

export const timeOptionsReturn: TimeOption[] = timeArrayReturn;

export const filterTimesFunction = (array: TimeOption[], hours: PickupHoursAndMinutes) => {
    return array.filter((item: TimeOption) => {
        const splitItem = item.label.split(':')
        const startHours = hours.startHours
        const startMinutes = hours.startMinutes
        const endHours = hours.endHours
        const endMinutes = hours.endMinutes
        if (
            ((parseInt(splitItem[0]) > startHours) || ((parseInt(splitItem[1]) >= startMinutes) && (parseInt(splitItem[0]) === startHours)))
            && ((parseInt(splitItem[0]) < endHours) || ((parseInt(splitItem[0]) === endHours) && (parseInt(splitItem[1]) <= endMinutes)))
        ) {
            return true
        } else {
            return false
        }
    })
}

export const isNotSunday = (date: Date) => {
    const christmas = new Date('2022-12-26')
    // TODO: Rename Function and Add different Holidays
    return (date.getDay() !== 0 && isSameDay(christmas, date) !== true);
};