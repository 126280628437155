import React from "react";
import ReactMarkDown from 'react-markdown'
import RentLocations from "../shared/rent-locations";
import Carousel from 'react-bootstrap/Carousel';

const OtherContent = ({ title, description, carousel, image }) => {
    return (
        <div className="container">
            <div className="grid-two-thirds">
                <div className="grid-two-thirds__left">
                    <h1>{title}</h1>
                    {description?.data && 
                        <ReactMarkDown>{description.data.description}</ReactMarkDown>
                    }
                    {carousel &&
                        <Carousel variant="dark">
                            {carousel.map((item) => (
                                <Carousel.Item key={item?.strapi_id}>
                                    <img
                                        className="d-block w-100"
                                        src={item?.url}
                                        alt={item?.alternativeText}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    }
                </div>
                <div className="grid-two-thirds__right">
                    <RentLocations />
                    <img src={image?.url} alt={image?.alternativeText && image.alternativeText || 'Overig'} title={image?.caption} className="img-fluid round-10"></img>
                </div>
            </div>
        </div>
    )
}

export default OtherContent