import React from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"

const ImageCarousel = ({ images }) => {
  return (
    <div className="vehicle-select__image">
      {images && images.length > 1 ? (
        <Carousel showStatus={false} className="carousel" autoPlay={false} style={{ backgroundColor: 'transparent' }}>
          {images.slice(0, 4).map((image, index) => (
            <div key={index}>
              <img src={image.imageUrl} alt={image.fileName || `Image ${index + 1}`} />
            </div>
          ))}
        </Carousel>
      ) : images && images.length === 1 ? (
        <img
          src={images[0].imageUrl}
          alt={images[0].fileName || 'placeholderVehicle'}
          className="img-fluid"
        />
      ) : (
        <div className="no-image-placeholder">
          <p>No images available</p>
        </div>
      )}
    </div>
  )
}

export default ImageCarousel
