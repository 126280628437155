import * as React from "react"
import CtaForm from './shared/cta-form/form'
import { Container } from "react-bootstrap"
import BackgroundImage from '../images/backgrounds/homepage.png'

const Hero = ({ cover, title }) => {
    const image = cover.url;
    return (
        <div
            className="hero-image"
        >
            <div className="call-to-action">
                <CtaForm title={title}></CtaForm>
            </div>
        </div>
    )
}

export default Hero