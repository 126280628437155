import React, { useState } from 'react'
import { Link } from 'gatsby';
import { Icon } from "leaflet";
import "leaflet-gesture-handling";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import bertJonk from "../images/icons/bertjonk-truck.png"
import { Marker, Popup, useMapEvents } from 'react-leaflet'
import Purmerend from "../images/locations/purmerend.webp"
import Zwaag from "../images/locations/zwaag.webp"
import OudeMeer from "../images/locations/oudemeer.webp"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import loadable from '@loadable/component'
const TileLayer = loadable(() => import('react-leaflet'), {
  resolveComponent: (components) => components.TileLayer
})
const MapContainer = loadable(() => import('react-leaflet'), {
  resolveComponent: (components) => components.MapContainer
})

const positionPurmerend = [52.512540, 4.999890];
const positionZwaag = [52.668919, 5.076320];
const positionOudeMeer = [52.291980, 4.790810]

function LocationMarker() {
  const [position, setPosition] = useState(null)
  const map = useMapEvents({
    click() {
      map.locate()
    },
    locationfound(e) {
      setPosition(e.latlng)
      map.flyTo(e.latlng, map.getZoom())
    },
  })

  return position === null ? null : (
    <Marker position={position} gestureHandling="true">
      <Popup>
        <div className='popup__location'>
          Huidige locatie
        </div>
      </Popup>
    </Marker>
  )
}

const LocationMap = () => {
  if (typeof window !== 'undefined') {
    const customIcon = new Icon({
      iconUrl: bertJonk,
    });

    return (
      <MapContainer
        center={positionPurmerend}
        zoom={9}
        gestureHandling='true'
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={positionPurmerend} icon={(!!customIcon ? customIcon : null)}>
          <Popup>
            <img src={Purmerend} alt="Bert Jonk Purmerend" className='popup__image'></img>
            <div className='popup__content'>
              <h3>Bert Jonk Purmerend</h3>
              <ul>
                <li>
                  <span className='bold'>
                    <FontAwesomeIcon icon={['fas', 'map-marker-alt']} />
                  </span><a href='https://g.page/bert-jonk-autoverhuur-purmerend?share'>Einsteinstraat 11, 1446VE</a>
                </li>
                <li>
                  <span className='bold'>
                    <FontAwesomeIcon icon={['fas', 'phone']} flip="horizontal" />
                  </span><a href="tel:0299423852">0299423852</a>
                </li>
              </ul>
              <Link to='/verhuurvestigingen/purmerend'>
                <div className='circle-button'>
                  Bert Jonk Purmerend <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                </div>
              </Link>
            </div>
          </Popup>
        </Marker>
        <Marker position={positionZwaag} icon={(!!customIcon ? customIcon : null)}>
          <Popup>
            <img src={Zwaag} alt="Bert Jonk Zwaag / Hoorn" className='popup__image'></img>
            <div className='popup__content'>
              <h3>Bert Jonk Zwaag / Hoorn</h3>
              <ul>
                <li>
                  <span className='bold'>
                    <FontAwesomeIcon icon={['fas', 'map-marker-alt']} />
                  </span><a href='https://g.page/bert-jonk-autoverhuur-zwaag?share'>De Compagnie 49, 1689AG</a>
                </li>
                <li>
                  <span className='bold'>
                    <FontAwesomeIcon icon={['fas', 'phone']} flip="horizontal" />
                  </span><a href="tel:0299244340">0299-244 340</a>
                </li>
              </ul>
              <Link to='/verhuurvestigingen/zwaag-hoorn'>
                <div className='circle-button'>
                  Bert Jonk Zwaag / Hoorn <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                </div>
              </Link>
            </div>
          </Popup>
        </Marker>
        <Marker position={positionOudeMeer} icon={(!!customIcon ? customIcon : null)}>
          <Popup>
            <img src={OudeMeer} alt="Bert Jonk Oude Meer" className='popup__image'></img>
            <div className='popup__content'>
              <h3>Bert Jonk Oude Meer (Schiphol)</h3>
              <ul>
                <li>
                  <span className='bold'>
                    <FontAwesomeIcon icon={['fas', 'map-marker-alt']} />
                  </span><a href='https://g.page/bert-jonk-autoverhuur-schiphol?share'>Schipholdijk 245, 1438AC</a>
                </li>
                <li>
                  <span className='bold'>
                    <FontAwesomeIcon icon={['fas', 'phone']} flip="horizontal" />
                  </span><a href="tel:0206530032">020 653 0032</a>
                </li>
              </ul>
              <Link to='/verhuurvestigingen/oude-meer-schiphol'>
                <div className='circle-button'>
                  Bert Jonk Oude Meer (Schiphol) <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                </div>
              </Link>
            </div>
          </Popup>
        </Marker>
        <LocationMarker />
      </MapContainer>
    )
  }
  return null
}

export default LocationMap