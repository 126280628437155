import React, { useEffect } from "react";
import Bestelbus from "../../../images/svg/deliveryVan-right.svg"
import Auto from "../../../images/svg/car-right.svg"
import Vrachtwagen from "../../../images/svg/heavyTruck-right.svg"
import Personenbus from "../../../images/svg/passengerVan-right.svg"
import Koelwagen from "../../../images/svg/coolingTruck-right.svg"
import Ambulance from "../../../images/svg/autoambulance.png"
import Pickup from "../../../images/svg/pickup.svg"
import Rolstoelbus from "../../../images/svg/wheelchair.svg"
import Settings from "../../../components/settings.json";


const Categories = ({ category, setCoolingTruck }) => {
    if (!category) {
        category = Settings.categoryId.bestelauto;
    } else {
        switch (category) {
            case 'personenauto':
                category = Settings.categoryId.personenauto;
                break;
            case 'bestelauto':
                category = Settings.categoryId.bestelauto;
                break;
            case 'busje':
                category = Settings.categoryId.bestelauto;
                break;
            case 'vrachtwagen':
                category = Settings.categoryId.vrachtwagen;
                break;
            case 'koelwagen':
                category = Settings.categoryId.koelwagen;
                break;
            case 'personenbus':
                category = Settings.categoryId.personenbus;
                break;
            case 'ambulance':
                category = Settings.categoryId.ambulance;
                break;
            case 'rolstoelbus':
                category = Settings.categoryId.rolstoelbus;
                break;
            case 'pickup':
                category = Settings.categoryId.pickup;
                break;
        }
    }

    if (category === Settings.categoryId.koelwagen) {
        setCoolingTruck(true);
    }

    const handleChangeCooling = (e) => {
        if (e.target.value === Settings.categoryId.koelwagen.toString()) {
            setCoolingTruck(true);
        } else {
            setCoolingTruck(false);
        }
    }
    const handleDefaultChecked = (categoryId) => categoryId === Number(category);
    return (
        <div className="hero-form__tabs" id="hero-form" >
            <div>
                <input type="radio" id={Settings.categoryId.bestelauto} onChange={(e) => {handleChangeCooling(e)}} name="categoryId" value={Settings.categoryId.bestelauto} defaultChecked={handleDefaultChecked(Settings.categoryId.bestelauto)}></input>
                <label className="hero-form__tab" htmlFor={Settings.categoryId.bestelauto}>
                    Bestelauto
                    <img src={Bestelbus} alt='bestelauto'></img>
                </label>
            </div>
            <div>
                <input type="radio" id={Settings.categoryId.personenauto} onChange={(e) => {handleChangeCooling(e)}} name="categoryId" value={Settings.categoryId.personenauto} defaultChecked={handleDefaultChecked(Settings.categoryId.personenauto)}></input>
                <label className="hero-form__tab" htmlFor={Settings.categoryId.personenauto}>
                    Personenauto
                    <img src={Auto} alt='Personenauto'></img>
                </label>
            </div>
            <div>
                <input type="radio" id={Settings.categoryId.vrachtwagen} onChange={(e) => {handleChangeCooling(e)}} name="categoryId" value={Settings.categoryId.vrachtwagen} defaultChecked={handleDefaultChecked(Settings.categoryId.vrachtwagen)}></input>
                <label className="hero-form__tab" htmlFor={Settings.categoryId.vrachtwagen}>
                    Vrachtwagen
                    <img src={Vrachtwagen} alt='Vrachtwagen'></img>
                </label>
            </div>
            <div>
                <input type="radio" id={Settings.categoryId.koelwagen} onChange={(e) => {handleChangeCooling(e)}} name="categoryId" value={Settings.categoryId.koelwagen} defaultChecked={handleDefaultChecked(Settings.categoryId.koelwagen)}></input>
                <label className="hero-form__tab" htmlFor={Settings.categoryId.koelwagen} >
                    Koelwagen
                    <img src={Koelwagen} alt='Koelwagen'></img>
                </label>
            </div>
            <div>
                <input type="radio" id={Settings.categoryId.personenbus} onChange={(e) => {handleChangeCooling(e)}} name="categoryId" value={Settings.categoryId.personenbus} defaultChecked={handleDefaultChecked(Settings.categoryId.personenbus)}></input>
                <label className="hero-form__tab" htmlFor={Settings.categoryId.personenbus}>
                    Personenbus
                    <img src={Personenbus} alt='Personenbus'></img>
                </label>
            </div>
            <div>
                <input type="radio" id={Settings.categoryId.ambulance} onChange={(e) => {handleChangeCooling(e)}} name="categoryId" value={Settings.categoryId.ambulance} defaultChecked={handleDefaultChecked(Settings.categoryId.ambulance)}></input>
                <label className="hero-form__tab" htmlFor={Settings.categoryId.ambulance}>
                    Ambulance
                    <img src={Ambulance} alt='Ambulance' style={{ scale: '3' }}></img>
                </label>
            </div>
            <div>
                <input type="radio" id={Settings.categoryId.rolstoelbus} onChange={(e) => {handleChangeCooling(e)}} name="categoryId" value={Settings.categoryId.rolstoelbus} defaultChecked={handleDefaultChecked(Settings.categoryId.rolstoelbus)}></input>
                <label className="hero-form__tab" htmlFor={Settings.categoryId.rolstoelbus}>
                    Rolstoelbus
                    <img src={Rolstoelbus} alt='Rolstoelbus'></img>
                </label>
            </div>
            <div>
                <input type="radio" id={Settings.categoryId.pickup} onChange={(e) => {handleChangeCooling(e)}} name="categoryId" value={Settings.categoryId.pickup} defaultChecked={handleDefaultChecked(Settings.categoryId.pickup)}></input>
                <label className="hero-form__tab" htmlFor={Settings.categoryId.pickup}>
                    Pickup
                    <img src={Pickup} alt='Pickup'></img>
                </label>
            </div>
        </div>
    )
}

export default Categories