import React from "react"
import Vehicle from "../../../images/svg/vehicle.png"

const SelectSpecificExtras = () => {
  return (
    <div className="extra-wrapper">
      <div className="extra-wrapper__title">
        <img src={Vehicle} alt="Voertuig Opties"></img>
        <h3>Voertuig Opties</h3>
      </div>
      <h3>
        Specifieke voertuig extra's nodig zoals een{" "}
        <span style={{ fontWeight: 600 }}>trekhaak</span> of een{" "}
        <span style={{ fontWeight: 600 }}>automaat</span>? Neem contact met ons
        op via het telefoonnummer: <a href="tel:0883745900">088 374 59 00</a>{" "}
      </h3>
    </div>
  )
}

export default SelectSpecificExtras
