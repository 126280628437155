import React from "react"
import OwnRisk from "../../../images/svg/own_risk.png"
import { compare } from "./HelperFunctions"

const SelectInsuranceOptions = ({ insuranceOptions, valueChanges, totalDays, includeVat }) => {
  let newInsuranceOptions = []

  const defaultInsurance = insuranceOptions.find(
    option => option.price.value === 0 && !option.description.includes("Geen")
  )

  if (defaultInsurance) {
    newInsuranceOptions.push(defaultInsurance)
   }

  let loweredInsurance = {}
  if (totalDays > 6 && totalDays < 30) {
    loweredInsurance = insuranceOptions.find(option => option.featurePeriod?.toUpperCase() === 'WEEK')
  } else if (totalDays > 29) {
    loweredInsurance = insuranceOptions.find(option => option.featurePeriod?.toUpperCase() === 'MONTH')
  } else {
    loweredInsurance = insuranceOptions.find(option => option.featurePeriod?.toUpperCase() === 'DAY')
  }

  if (loweredInsurance) {
    newInsuranceOptions.push(loweredInsurance)
  }

  return (
    <div className="extra-wrapper">
      <div className="extra-wrapper__title">
        <img src={OwnRisk} alt="Eigen Risico en Borg"></img>
        <h3>Eigen Risico en Borg</h3>
      </div>
      <div
        className="extrabox-grid"
        onChange={selected =>
          valueChanges("insuranceOptions", [selected.target.id])
        }
      >
        {newInsuranceOptions.length > 0 && newInsuranceOptions.sort(compare).map(option => (
          <div key={option.optionId}>
            <input
              type="radio"
              id={option.optionId}
              name="insuranceId"
              value={option.price.value}
              defaultChecked={option.price.value === 0}
            />
            <label htmlFor={option.optionId}>
              <div className="extrabox">
                <h3>Eigen Risico van € {option.insuranceExcessAmount.value} (€{includeVat !== 'yes' ? (option.price.value * totalDays / 1.21).toFixed(2).replace('.',',') : (option.price.value * totalDays).toFixed(2).replace('.',',')})</h3>
                <p className="dark-gray">
                  In geval van schade ben je aansprakelijk tot een bedrag van €{" "}
                  {option.insuranceExcessAmount.value} per schadegeval <br></br>
                  <span style={{ fontStyle: "italic" }}>
                    (bovenhoofdse schade uitgezonderd)
                  </span>{" "}
                </p>
              </div>
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SelectInsuranceOptions
