// Display Article -- created via Node-Config

import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ReactMarkdown from "react-markdown"

const Article = props => {
  const { data } = props.pageContext
  return (
    <Layout>
      <Seo
        index={true}
        follow={true}
        title={data.seo.metaTitle}
        description={data.seo.metaDescription}
        image={data.seo.metaImage?.url}
      ></Seo>
      <section>
        <div className="blog-container">
          <div style={{ display: 'flex', justifyContent: 'center', background: 'unset'}}>

          {
            <img
              src={data.heroImage.url}
              alt={data.heroImage.alternativeText}
              title={data.heroImage.caption}
              style={{ objectFit: "contain", maxHeight: "50vh", maxWidth: '100%' }}
            ></img>
          }
          </div>
          <div className="breadcrumb" style={{ display: 'flex', justifyContent: 'center', background: 'unset'}}>
            <Link to="/"> home </Link>{" "}
            <span style={{ margin: "0 3px" }}>/</span>{" "}
            <Link to="/blogs"> blogs </Link>{" "}
            <span style={{ margin: "0 3px" }}>/</span>{" "}
            {data.title.toLowerCase()}
          </div>
          <div className="blog-content-wrapper">
            <h1>{data.title}</h1>
            <div id="quick-summary-wrapper">
              <div className="created-wrapper">
                <p id="createdAt"></p>
              </div>
              <h3>In het kort...</h3>
              <ReactMarkdown>
                {data.quickSummary?.data?.quickSummary}
              </ReactMarkdown>
            </div>

            <div id="article">
              <ReactMarkdown>{data.body?.data?.body}</ReactMarkdown>
            </div>

            {data.cta && (
              <section className="contact-wrapper">
                <h3>{data.cta.title}</h3>
                <p>{data.cta.description}</p>
                <a
                  href={data.cta.buttonUrl}
                  className="contact-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  {data.cta.buttonText}
                </a>
              </section>
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Article
