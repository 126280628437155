import * as React from "react"
import OtherContent from "../../components/other-pages/other-content"
import Layout from "../../components/layout"
import LocationMap from "../../components/location-map"
import Seo from "../../components/seo"
import { OpeningHoursHome } from "../../components/shared/opening-hours"
import BreadCrumbs from "../../components/shared/breadcrumbs"

const Others = (props) => {
    const { 
        title, 
        description, 
        image, 
        carousel, 
        subFolder,
        basicSeo 
    } = props.pageContext;
    return (
        <Layout>
            <Seo index={true} follow={true} title={basicSeo.seoTitle} description={basicSeo.seoMetadescription}></Seo>
            <BreadCrumbs title={title} subFolder={subFolder}></BreadCrumbs>
            <OtherContent title={title} description={description} image={image} carousel={carousel}></OtherContent>
            <OpeningHoursHome />
            {typeof window !== 'undefined' &&
                <LocationMap />
            }
        </Layout>
    )
}

export default Others