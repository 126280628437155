import axios from 'axios';

export class Payments {
  private static BASE_URL = 'https://rentvisie-payments.herokuapp.com/api';

  static async get<T>(
    path: string,
    searchParams?: URLSearchParams
  ): Promise<T> {
    const url = `${Payments.BASE_URL}/${path}${
      searchParams ? `?${searchParams.toString()}` : ''
    }`;
    console.log(`GET: ${url}`);

    const { data } = await axios.get(url);

    return data;
  }

  static async post<T>(path: string, body: any): Promise<T> {
    const url = `${Payments.BASE_URL}/${path}`;
    console.log(`POST: ${url}`);

    const { data } = await axios.post(url, body);

    return data;
  }

  static paymentIntent(
    reservationReference: string,
    client: string
  ): Promise<{ clientSecret: string }> {
    // TODO Wrap up payments, this needs work in payment app
    return this.post('payment-intent', { reservationReference, client });
  }

  static paymentIntentWithDeposit(
    reservationReference: string,
    client: string
  ): Promise<{ clientSecret: string }> {
    // TODO Wrap up payments, this needs work in payment app
    return this.post('payment-intent-with-deposit', {
      reservationReference,
      client,
    });
  }

  static getStripePublishableKey(): Promise<{ publishableKey: string }> {
    return this.get('publishable-key');
  }
}
