import * as React from "react"
import Layout from "../../components/layout"
import LocationMap from "../../components/location-map"
import SEO from "../../components/seo"
import RentLocations from "../../components/shared/rent-locations"
import thanksImage from "../../images/reservering-bert-jonk.webp"
const ThankYou = () => {
  if (typeof window !== "undefined") {
    const itemStr = localStorage.getItem('returnToCheckout')
    if (!itemStr) {
      return null
    }
    localStorage.removeItem('returnToCheckout')
  }

  return (
    <Layout>
      <SEO title='Bert Jonk Autoverhuur - Bedankt voor uw reservering' index={false} follow={true} />
      <div className="container" style={{ marginTop: 50, marginBottom: 50 }}>
        <div className="reservation__title">
          <h1>Uw reservering is bevestigd, bedankt!</h1>
          <p>U ontvangt binnen enkele seconde een e-mail ter bevestiging.</p>
          <p>
            Neemt u bij het ophalen van het voertuig ten minste de volgende documenten mee:
            <ul>
              <li>Rijbewijs</li>
              <li>Paspoort of ID-kaart</li>
              <li>Bewijs van Adres ((digitaal) Poststuk of Internet Bankieren op uw telefoon)</li>
            </ul>
          </p>
          <p>Let op: bij personenauto's vanaf klasse Opel Corsa / Citroen C1 en groter kunt u de borg alleen voldoen met creditcard.</p>
          <p>Heeft u nog vragen over uw reservering? Neem dan contact met ons op!</p>
        </div>
        <div className="reservation-select-wrapper">
          <div className="reservation-select-left">
            <img src={thanksImage} alt='bedankt voor uw reservering' title='welkom bij de Bert Jonk familie' className='img-fluid rounded shadow'></img>
          </div>
          <div className="reservation-select-right">
            <RentLocations />
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default ThankYou