export const compare = (a, b) => {
  if (a.price.value < b.price.value) {
    return -1
  }
  if (a.price.value > b.price.value) {
    return 1
  }
  return 0
}

export const getDisplayPriceOfOption = (option, days, includeVat) => {
  const basePrice = option.price.value
  const type = option.price.unitType

  let priceBeforeTax

  switch (type) {
    case "PerTrip":
      priceBeforeTax = basePrice
      break
    case "PerDay":
      priceBeforeTax = basePrice * days
      break
    default:
      priceBeforeTax = basePrice * days
      break
  }

  let priceAfterTax
  if (includeVat === "yes") {
    priceAfterTax = priceBeforeTax
  } else {
    priceAfterTax = priceBeforeTax / 1.21
  }

  priceAfterTax = priceBeforeTax / 1.21;
  
  return priceAfterTax.toFixed(2).replace(".", ",");
}
