import { useState } from 'react';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Settings from "../settings.json"
import { navigate } from 'gatsby';

const ContactFormContent = ({ requestedVehicle }) => {
    const [formSubmitted, setFormSubmitted] = useState(false)
    const { register, formState: { errors }, handleSubmit } = useForm();
    const onSubmit = (data) => {
        axios.post(Settings.contactUrl, { data })
            .then(setFormSubmitted(true))
            .finally(() => { navigate('/verzoek-bedankt') })
        // alert(JSON.stringify(data))
    };
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    return (
        <div>
            {!formSubmitted &&
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='form-row' style={{ textAlign: 'left' }}>
                        <div className='form-50'>
                            <div className='textOnInput'>
                                <label htmlFor='voornaam'>Voornaam</label>
                                <input
                                    type="text"
                                    className="form-input-alt"
                                    {...register("voornaam", { required: true, maxLength: 180 })}
                                />
                            </div>
                        </div>
                        <div className='form-50'>
                            <div className='textOnInput'>
                                <label htmlFor='achternaam'>Achternaam</label>
                                <input
                                    type="text"
                                    className="form-input-alt"
                                    {...register("achternaam", { required: true, maxLength: 180 })}
                                />
                            </div>
                        </div>
                        <div className='form-50'>
                            <div className='textOnInput'>
                                <label htmlFor='email'>E-mail</label>
                                <input
                                    type="text"
                                    className="form-input=alt"

                                    {...register("email", {
                                        required: true,
                                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    })}
                                />
                            </div>
                        </div>
                        <div className='form-50'>
                            <div className='textOnInput'>
                                <label htmlFor='telefoon'>Telefoon</label>
                                <input
                                    type="tel"
                                    className="form-input-alt"
                                    {...register("telefoon", {
                                        required: true,
                                        maxLength: 11,
                                        minLength: 8
                                    })}
                                />
                            </div>
                        </div>
                        <div className='form-100'>
                            <div className='textOnInput'>

                                <label htmlFor='bericht'>Opmerkingen</label>
                                <textarea
                                    rows="4"
                                    className="form-input-alt"
                                    {...register("bericht")}
                                />
                            </div>
                        </div>
                        <div style={{ marginLeft: 'auto', marginRight: '8px'}}>
                            <input type="submit" className='button button-primary' value="Offerte opvragen" />
                        </div>
                    </div>
                    {errors.voornaam && <span>Controleer uw voornaam</span>}
                    {errors.achternaam && <span>Controleer uw achternaam</span>}
                    {errors.email && <span>Controleer uw e-mail</span>}
                    {errors.telefoon && <p>Controleer uw telefoonnummer</p>}
                    {errors.bericht && <p>Controleer uw opmerkingen</p>}
                </form>
            }
            {
                formSubmitted &&
                <h3 style={{ padding: 50 }}>Bedankt, wij hebben uw verzoek in goede orde ontvangen.</h3>
            }
        </div>
    );
}

export default ContactFormContent