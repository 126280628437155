import React, { useEffect, useRef, useState } from "react"
import TextField from "@mui/material/TextField"
import { useSelector } from "react-redux"
import CustomerDocuments from "./customerDocuments"
import { Button } from "@mui/material"
import { API_URL, channelId, clientId } from "../../constants/api"
import axios from "axios"
import { IMaskInput, IMaskMixin } from "react-imask"
import CustomerDeposit from "./customerDeposit"

const DateMask = React.forwardRef(function DateMask(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask="00-00-#000"
      definitions={{
        "#": /[12]/,
      }}
      inputRef={ref}
      onAccept={value => onChange({ target: { name: props.name, value } })}
      overwrite
      placeholder={"dd-mm-yyyy"}
    />
  )
})

const IbanMask = React.forwardRef(function DateMask(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask={
        /^AL\d{10}[0-9A-Z]{16}$|^AD\d{10}[0-9A-Z]{12}$|^AT\d{18}$|^BH\d{2}[A-Z]{4}[0-9A-Z]{14}$|^BE\d{14}$|^BA\d{18}$|^BG\d{2}[A-Z]{4}\d{6}[0-9A-Z]{8}$|^HR\d{19}$|^CY\d{10}[0-9A-Z]{16}$|^CZ\d{22}$|^DK\d{16}$|^FO\d{16}$|^GL\d{16}$|^DO\d{2}[0-9A-Z]{4}\d{20}$|^EE\d{18}$|^FI\d{16}$|^FR\d{12}[0-9A-Z]{11}\d{2}$|^GE\d{2}[A-Z]{2}\d{16}$|^DE\d{20}$|^GI\d{2}[A-Z]{4}[0-9A-Z]{15}$|^GR\d{9}[0-9A-Z]{16}$|^HU\d{26}$|^IS\d{24}$|^IE\d{2}[A-Z]{4}\d{14}$|^IL\d{21}$|^IT\d{2}[A-Z]\d{10}[0-9A-Z]{12}$|^[A-Z]{2}\d{5}[0-9A-Z]{13}$|^KW\d{2}[A-Z]{4}22!$|^LV\d{2}[A-Z]{4}[0-9A-Z]{13}$|^LB\d{6}[0-9A-Z]{20}$|^LI\d{7}[0-9A-Z]{12}$|^LT\d{18}$|^LU\d{5}[0-9A-Z]{13}$|^MK\d{5}[0-9A-Z]{10}\d{2}$|^MT\d{2}[A-Z]{4}\d{5}[0-9A-Z]{18}$|^MR13\d{23}$|^MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}$|^MC\d{12}[0-9A-Z]{11}\d{2}$|^ME\d{20}$|^NL\d{2}[A-Z]{4}\d{10}$|^NO\d{13}$|^PL\d{10}[0-9A-Z]{,16}n$|^PT\d{23}$|^RO\d{2}[A-Z]{4}[0-9A-Z]{16}$|^SM\d{2}[A-Z]\d{10}[0-9A-Z]{12}$|^SA\d{4}[0-9A-Z]{18}$|^RS\d{20}$|^SK\d{22}$|^SI\d{17}$|^ES\d{22}$|^SE\d{22}$|^CH\d{7}[0-9A-Z]{12}$|^TN59\d{20}$|^TR\d{7}[0-9A-Z]{17}$|^AE\d{21}$|^GB\d{2}[A-Z]{4}\d{14}$/
      }
      inputRef={ref}
      onAccept={value => onChange({ target: { name: props.name, value } })}
      overwrite
      placeholder={"NL01INGB0001112233"}
    />
  )
})

function trimObjectStrings(obj) {
  const trimmedObj = {};

  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      trimmedObj[key] = obj[key].trim();
    } else if (typeof obj[key] === 'object') {
      trimmedObj[key] = trimObjectStrings(obj[key]);
    } else {
      trimmedObj[key] = obj[key];
    }
  }

  return trimmedObj;
}

const CustomerBody = () => {
  const [validIban, setValidIban] = useState(false)

  const customer = useSelector(state => state.customer.customer)
  const { reservationReference } = useSelector(state => state.customer)

  const [state, setState] = useState(undefined)
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (customer) {
      setState(customer.customer)
    }
  }, [customer])

  useEffect(() => {
    if (state && customer) {
      if (!state.company?.name) {
        setState(prevState => ({
          ...prevState,
          company: {
            ...prevState.company,
            name: ` ${prevState.firstName} ${prevState.lastName}`
          }
        }));
      }
    }
  }, [state, customer]);

  const handleChange = e => {
    setState(prevState => {
      return { ...prevState, [e.target.name]: e.target.value }
    })
  }

  const handleChangeCompany = e => {
    setState(prevState => {
      return {
        ...prevState,
        company: {
          ...prevState.company,
          [e.target.name]: e.target.value,
        },
      }
    })
  }

  const handleChangeAddress = e => {
    setState(prevState => {
      return {
        ...prevState,
        physicalAddress: {
          ...prevState.physicalAddress,
          [e.target.name]: e.target.value,
        },
      }
    })
  }

  const validateIBAN = e => {
    setValidIban(false);
    const iban = e.target.value.replace(/\s/g, '').toUpperCase();

    const ibanRegex = /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/;
    if (!ibanRegex.test(iban)) {
      return;
    }
  

    // Check if the IBAN starts with a valid country code (first two characters)
    const country_code = iban.slice(0, 2);
    const valid_country_codes = [
      'AT',
      'BE',
      'BG',
      'HR',
      'CY',
      'CZ',
      'DK',
      'EE',
      'FI',
      'FR',
      'DE',
      'GR',
      'HU',
      'IE',
      'IT',
      'LV',
      'LT',
      'LU',
      'MT',
      'NL',
      'PL',
      'PT',
      'RO',
      'SK',
      'SI',
      'ES',
      'SE',
    ];

    if (!valid_country_codes.includes(country_code)) {
      return;
    }

    // Perform IBAN checksum validation
    function customModulo(x, y) {
      let result = 0;
    
      for (let i = 0; i < x.length; i++) {
        result = (result * 10 + parseInt(x[i], 10)) % y;
      }
    
      return result;
    }
    
    const ibanDigits = (iban.substring(4) + iban.substring(0, 4)).split('');
    let numericIban = '';
    
    for (let i = 0; i < ibanDigits.length; i++) {
      const char = ibanDigits[i];
      if (char >= 'A' && char <= 'Z') {
        numericIban += (char.charCodeAt(0) - 'A'.charCodeAt(0) + 10).toString();
      } else {
        numericIban += char;
      }
    }
    
    const remainder = customModulo(numericIban, 97);
    
    if (remainder !== 1) {
      return;
    }


    setValidIban(true);
  }

  const handleChangePhone = e => {
    setState(prevState => {
      return {
        ...prevState,
        cellphoneNumber: {
          ...prevState.cellphoneNumber,
          [e.target.name]: e.target.value,
        },
      }
    })
  }

  const handleChangeDL = e => {
    setState(prevState => {
      return {
        ...prevState,
        driversLicense: {
          ...prevState.driversLicense,
          [e.target.name]: e.target.value,
        },
      }
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    trimObjectStrings(state)

    if (state.iban && !validIban) {
      return alert("IBAN niet geldig. Vul het volledige IBAN zonder spaties in: vb. NL51INGB0008762509")
    }
    setLoading(true)

    try {
      await axios.patch(
        `${API_URL}/customers/reservations/${reservationReference}/bookings/${reservationReference}01/customer`,
        state,
        {
          headers: {
            clientId,
            channelId,
          },
        }
      )
      alert("Gegevens bijgewerkt")
      setError("")
    } catch (e) {
      setError("Gegevens niet opgeslagen, probeer het opnieuw.")
    }

    setLoading(false)
    return
    // TODO: Add validation
  }

  return (
    <div className="customer-fields-wrapper">
      {state && customer && (
        <>
          {customer.rentalStatus?.toUpperCase() === "COMPLETED" && (
            <p>Reservering voltooid, download uw documenten hieronder.</p>
          )}
          {customer.rentalStatus?.toUpperCase() !== "COMPLETED" && (
            <form className="customer-fields-wrapper" onSubmit={handleSubmit}>
              <p className="border-bottom">Factuurgegevens </p>
              <div className="customer-fields-row">
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.company?.name || ""}
                  onChange={handleChangeCompany}
                  label="Factuurnaam"
                  name="name"
                  required
                  fullWidth
                ></TextField>
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.company?.vatNumber || ""}
                  onChange={handleChangeCompany}
                  label="BTW Nummer (optioneel)"
                  name="vatNumber"
                  fullWidth
                ></TextField>
              </div>

              <p className="border-bottom">Contactpersoon *</p>
              <div className="customer-fields-row">
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.firstName || ""}
                  onChange={handleChange}
                  label="Voornaam"
                  required
                  name="firstName"
                  fullWidth
                ></TextField>
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.lastName || ""}
                  onChange={handleChange}
                  label="Achternaam"
                  required
                  name="lastName"
                  fullWidth
                ></TextField>
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.dateOfBirth || ""}
                  onChange={handleChange}
                  InputProps={{
                    inputComponent: DateMask,
                  }}
                  label="Geboortedatum"
                  name="dateOfBirth"
                  fullWidth
                ></TextField>
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.placeOfBirth || ""}
                  onChange={handleChange}
                  label="Geboorteplaats"
                  name="placeOfBirth"
                  fullWidth
                ></TextField>
                {/* TODO: Add date of birth */}
              </div>

              <p className="border-bottom">Adres *</p>
              <div className="customer-fields-row">
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.physicalAddress?.postalCode || ""}
                  onChange={handleChangeAddress}
                  required
                  label="Postcode"
                  name="postalCode"
                  fullWidth
                ></TextField>
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.physicalAddress?.houseNumber || ""}
                  onChange={handleChangeAddress}
                  label="Huisnummer"
                  required
                  name="houseNumber"
                  fullWidth
                ></TextField>
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.physicalAddress?.houseNumberAdditional || ""}
                  onChange={handleChangeAddress}
                  label="Toevoeging"
                  name="houseNumberAdditional"
                ></TextField>
              </div>
              <div className="customer-fields-row">
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.physicalAddress?.streetName || ""}
                  onChange={handleChangeAddress}
                  label="Straat"
                  required
                  name="streetName"
                  fullWidth
                ></TextField>
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.physicalAddress?.city || ""}
                  onChange={handleChangeAddress}
                  label="Stad"
                  name="city"
                  required
                  fullWidth
                ></TextField>
              </div>

              <p>Contact *</p>

              <div className="customer-fields-row">
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.cellphoneNumber?.countryDialingCode || ""}
                  onChange={handleChangePhone}
                  label="Land Code (vb. +31)"
                  name="countryDialingCode"
                  required
                ></TextField>
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.cellphoneNumber?.number || ""}
                  onChange={handleChangePhone}
                  label="Telefoonnummer"
                  name="number"
                  required
                  fullWidth
                ></TextField>
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.email}
                  onChange={handleChange}
                  label="E-mail"
                  name="email"
                  required
                  fullWidth
                ></TextField>
              </div>

              <p className="border-bottom">Rijbewijs </p>
              <div className="customer-fields-row">
                <TextField
                  variant="outlined"
                  margin="dense"
                  value="Rijbewijs"
                  label="Type Document"
                  name="documentType"
                  onClick={() => {
                    alert("Je kunt dit veld niet wijzigen.")
                  }}
                ></TextField>
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.driversLicense?.number || ""}
                  onChange={handleChangeDL}
                  label="Rijbewijsnummer"
                  name="number"
                  fullWidth
                ></TextField>
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.driversLicense?.licenseType || ""}
                  onChange={handleChangeDL}
                  placeholder="B"
                  label="Type"
                  name="licenseType"
                  fullWidth
                ></TextField>
              </div>
              <div className="customer-fields-row">
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.driversLicense?.issuePlace || ""}
                  onChange={handleChangeDL}
                  label="Plaats van uitgifte"
                  name="issuePlace"
                  placeholder=""
                  fullWidth
                ></TextField>
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.driversLicense?.issueDate || ""}
                  onChange={handleChangeDL}
                  InputProps={{
                    inputComponent: DateMask,
                  }}
                  label="Datum van uitgifte"
                  name="issueDate"
                  fullWidth
                ></TextField>
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.driversLicense?.expiryDate || ""}
                  onChange={handleChangeDL}
                  InputProps={{
                    inputComponent: DateMask,
                  }}
                  label="Vervaldatum"
                  name="expiryDate"
                  fullWidth
                ></TextField>
              </div>

              <p className="border-bottom">IBAN </p>
              <div className="customer-fields-row">
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.iban || ""}
                  onChange={e => {
                    handleChange(e)
                    validateIBAN(e)
                  }}
                  label="iban"
                  name="iban"
                  fullWidth
                  inputProps={{
                    inputComponent: IbanMask,
                  }}
                ></TextField>
              </div>

              <div style={{ textAlign: "right", marginTop: 12 }}>
                {!loading && (
                  <Button type="submit" variant="contained" size="large">
                    Gegevens updaten
                  </Button>
                )}
                {loading && <p>Updaten...</p>}
              </div>

              {error && <p>{error}</p>}
            </form>
          )}

          {/* {customer.rentalStatus?.toUpperCase() !== 'COMPLETED' && (
            <CustomerDeposit />
          )} */}

          <CustomerDocuments />
        </>
      )}
    </div>
  )
}

export default CustomerBody
