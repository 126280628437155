import { Link } from "gatsby"

const CompleteCheckout = () => {
    let url = undefined
    if (typeof window !== "undefined") {
        const itemStr = localStorage.getItem('returnToCheckout')
        // if the item doesn't exist, return null
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        const expiryDate = new Date(item.expiry).getTime()
        url = `/huren/checkout?&reservationReference=${item.reservationReference}`
        // compare the expiry time of the item with the current time
        if (now.getTime() > expiryDate) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem('returnToCheckout')
            return null
        }
    }
    

    return (<div className="to-checkout-wrapper">
        {url && <Link to={url}>Reservering Afmaken</Link>}
    </div>)
}

export default CompleteCheckout