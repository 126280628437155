import axios, { AxiosRequestHeaders } from 'axios';
import {
  Branch,
  Category,
  DamageTemplate,
  Defect,
  Rental,
  RentalFilters,
  Reservation,
  Schedules,
  SchedulesFilter,
  VehicleClass,
  VehicleClassFilters,
  VehicleCommand,
} from './models';
import { CheckListValue } from './models/checklist';
import { Vehicle } from './models/vehicles';
import { CustomerData } from './rentvisie';

export interface RatalityServiceConfig {
  readonly baseUrl: string;
  readonly username: string;
  readonly password: string;
  readonly allowGetReservationForGuestToken: boolean | undefined;
}

export interface RatalityRequestOptions {
  clientId: number;
  channelId: number;
  userId?: string;
}

export interface RatalityGetRequestOptions extends RatalityRequestOptions {
  searchParams?: URLSearchParams;
}

export class RatalityService {
  private token?: string;

  constructor(private readonly config: RatalityServiceConfig) {}

  async login(): Promise<void> {
    this.token = await this.getToken();
  }

  getRental(
    id: string,
    requestOptions: RatalityRequestOptions
  ): Promise<Rental> {
    return this.get<Rental>(undefined, `api/v2/rentals/${id}`, requestOptions);
  }

  getRentals(
    pickupLocationId: number | string | undefined,
    dropOffLocationId: number | string | undefined,
    filters: RentalFilters,
    requestOptions: RatalityRequestOptions
  ): Promise<Rental[]> {
    const {
      pickupDate,
      pickupTime,
      dropOffDate,
      dropOffTime,
      vehicleClassId,
      categoryId,
      branchId,
      searchLocation,
    } = filters;

    const searchParams = new URLSearchParams({
      pickupDate,
      dropOffDate,
      pickupTime,
      dropOffTime,
    });

    if (pickupLocationId) {
      searchParams.append('pickupLocationId', `${pickupLocationId}`);
    }

    if (dropOffLocationId) {
      searchParams.append('dropOffLocationId', `${dropOffLocationId}`);
    }

    if (vehicleClassId) {
      searchParams.append('vehicleClassId', `${vehicleClassId}`);
    }

    if (categoryId) {
      searchParams.append('categoryId', `${categoryId}`);
    }

    if (branchId) {
      searchParams.append('branchId', `${branchId}`);
    }

    if (searchLocation) {
      searchParams.append('latitude', `${searchLocation.latitude}`);
      searchParams.append('longitude', `${searchLocation.longitude}`);
      searchParams.append('radius', `${searchLocation.radius}`);
    }

    return this.get<Rental[]>('rentals', 'api/v2/rentals', {
      ...requestOptions,
      searchParams,
    });
  }

  getVehicleClasses(
    filters: VehicleClassFilters,
    requestOptions: RatalityRequestOptions
  ): Promise<VehicleClass[]> {
    const { categoryId, branchId } = filters;

    const searchParams = new URLSearchParams();
    if (categoryId) {
      searchParams.append('categoryId', `${categoryId}`);
    }

    if (branchId) {
      searchParams.append('branchId', `${branchId}`);
    }

    return this.get<VehicleClass[]>(
      'vehicleClasses',
      'api/v2/vehicle-classes',
      { ...requestOptions, searchParams }
    );
  }

  async getVehicles(
    requestOptions: RatalityGetRequestOptions
  ): Promise<Vehicle[]> {
    const result = await this.get<Vehicle[]>(
      undefined,
      'api/v2/vehicles',
      requestOptions
    );
    console.log('vehicle result: ', result);
    return result;
  }

  getLocations(requestOptions: RatalityRequestOptions): Promise<Location[]> {
    return this.get<Location[]>(
      'locations',
      'api/v2/locations/Rental',
      requestOptions
    );
  }

  getCategories(requestOptions: RatalityRequestOptions): Promise<Category[]> {
    return this.get<Category[]>(
      'categories',
      'api/v2/categories',
      requestOptions
    );
  }

  getVehicleClass(
    id: number | string,
    requestOptions: RatalityRequestOptions
  ): Promise<VehicleClass> {
    return this.get<VehicleClass>(
      undefined,
      `api/v2/vehicle-classes/${id}`,
      requestOptions
    );
  }

  /**
   * @warn this not safe!
   */
  getReservationUnchecked(
    reservationRef: string,
    requestOptions: RatalityGetRequestOptions
  ): Promise<Reservation> {
    return this.get<Reservation>(
      undefined,
      `api/v2/reservation/${reservationRef}`,
      requestOptions
    );
  }

  async getReservation(
    reservationRef: string,
    requestOptions: RatalityRequestOptions
  ): Promise<Reservation> {
    const { allowGetReservationForGuestToken } = this.config;

    if (allowGetReservationForGuestToken) {
      return this.getReservationUnchecked(reservationRef, requestOptions);
    }

    if (!requestOptions.userId) {
      return Promise.reject('No user id set');
    }
    const reservation = await this.get<Reservation>(
      undefined,
      `api/v2/reservation/${reservationRef}`,
      requestOptions
    );

    if (reservation.thirdPartyReference !== requestOptions.userId) {
      return Promise.reject('Reservation does not belong to this user');
    }

    return reservation;
  }

  async getReservationByLastName(
    reservationRef: string,
    lastName: string,
    requestOptions: RatalityRequestOptions
  ): Promise<Reservation> {
    // TODO: @boris do we need the userId check or is it for firebase logins?
    // if (!requestOptions.userId) {
    //   return Promise.reject('No user id set');
    // }
    const reservation = await this.get<Reservation>(
      undefined,
      `api/v2/reservation/${reservationRef}`,
      requestOptions
    );

    // TODO: @boris -- please verify trips can't be undefined
    if (reservation.trips[0].bookings[0].customer.lastName !== lastName) {
      return Promise.reject('Booking not found');
    }
    return reservation;
  }

  async deleteReservation(
    reservationReference: string,
    requestOptions: RatalityRequestOptions
  ) {
    await this.getReservation(reservationReference, requestOptions);

    return this.delete(
      `api/v2/reservation/${reservationReference}`,
      requestOptions
    );
  }

  getReservations(
    requestOptions: RatalityGetRequestOptions
  ): Promise<Reservation> {
    if (!requestOptions.userId) {
      return Promise.reject('No user id set');
    }
    requestOptions.searchParams =
      requestOptions.searchParams ?? new URLSearchParams();
    requestOptions.searchParams.append(
      'thirdPartyReference',
      requestOptions.userId
    );
    requestOptions.searchParams.append('detailed', 'true');
    
    // Available values : Draft, Cancelled, Processed, PendingPayment, CancelledTimeout, CancelledPaymentTimeout, ConfirmedDepositReceived, Quotation
    const reservationStatusParams = {
      reservationStatus: ['Draft', 'Processed', 'PendingPayment'],
    };
    
    // Iterate over the key-value pairs and append each one to searchParams
    for (const [key, value] of Object.entries(reservationStatusParams)) {
      for (const item of value) {
        requestOptions.searchParams.append(key, item);
      }
    }

    return this.get<Reservation>(
      undefined,
      `api/v2/reservation`,
      requestOptions
    );
  }

  postReservation(
    rentalId: number,
    thirdPartyReference: string,
    requestOptions: RatalityRequestOptions
  ): Promise<Reservation> {
    // TODO: Assemble reservation
    const body = {
      rentals: [{ rentalId }],
      thirdPartyReference,
    };

    return this.post<Reservation>(
      'api/v2/reservation?language=nl',
      body,
      requestOptions
    );
  }

  patchCustomerData(
    reservationReference: string,
    bookingReference: string,
    data: CustomerData,
    requestOptions: RatalityRequestOptions
  ) {
    return this.patch<CustomerData>(
      `api/v2/reservation/${reservationReference}/${bookingReference}/customer`,
      data,
      requestOptions
    );
  }

  setInsuranceOption(
    reservationReference: string,
    bookingReference: string,
    optionId: string,
    requestOptions: RatalityRequestOptions
  ): Promise<any> {
    return this.put<any>(
      `api/v2/reservation/${reservationReference}/rentals/${bookingReference}/insurances/${optionId}`,
      {},
      requestOptions
    );
  }

  setMilageOption(
    reservationReference: string,
    bookingReference: string,
    optionId: string,
    requestOptions: RatalityRequestOptions
  ): Promise<any> {
    return this.put<any>(
      `api/v2/reservation/${reservationReference}/rentals/${bookingReference}/mileage-packages/${optionId}`,
      {},
      requestOptions
    );
  }

  addOptionalOptions(
    reservationReference: string,
    bookingReference: string,
    optionIds: number[],
    requestOptions: RatalityRequestOptions
  ): Promise<any> {
    return this.post<any>(
      `api/v2/reservation/${reservationReference}/rentals/${bookingReference}/options`,
      { ids: optionIds },
      requestOptions
    );
  }

  addOptionalOption(
    reservationReference: string,
    bookingReference: string,
    optionId: string,
    requestOptions: RatalityRequestOptions
  ): Promise<any> {
    return this.post<any>(
      `api/v2/reservation/${reservationReference}/rentals/${bookingReference}/options/${optionId}`,
      {},
      requestOptions
    );

    // This does not work
    // return this.put<any>(`api/v2/reservation/${reservationReference}/rentals/${bookingReference}/options/${optionId}`, {});

    // This works
    // return this.put<any>(`api/v2/reservation/${reservationReference}/rentals/${bookingReference}/options/`, { ids: [optionId] });
  }

  deleteOptionalOption(
    reservationReference: string,
    bookingReference: string,
    optionId: string,
    requestOptions: RatalityRequestOptions
  ): Promise<any> {
    return this.delete<any>(
      `api/v2/reservation/${reservationReference}/rentals/${bookingReference}/options/${optionId}`,
      requestOptions
    );
  }

  setOptionalOptions(
    reservationReference: string,
    bookingReference: string,
    optionIds: number[],
    requestOptions: RatalityRequestOptions
  ): Promise<any> {
    return this.put<any>(
      `api/v2/reservation/${reservationReference}/rentals/${bookingReference}/options`,
      { ids: optionIds },
      requestOptions
    );
  }

  // TODO INTERFACE
  addAllOptions(
    reservationReference: string,
    bookingReference: string,
    data: {
      ids?: number[];
      insuranceOptionId?: number;
      mileagePackageOptionId?: number;
    },
    requestOptions: RatalityRequestOptions
  ): Promise<any> {
    return this.post<any>(
      `api/v2/reservation/${reservationReference}/rentals/${bookingReference}/options`,
      data,
      requestOptions
    );
  }

  // TODO INTERFACE
  setAllOptions(
    reservationReference: string,
    bookingReference: string,
    data: {
      ids?: number[];
      insuranceOptionId?: number;
      mileagePackageOptionId?: number;
    },
    requestOptions: RatalityRequestOptions
  ): Promise<any> {
    return this.put<any>(
      `api/v2/reservation/${reservationReference}/rentals/${bookingReference}/options`,
      data,
      requestOptions
    );
  }

  postPayment(
    reservationReference: string,
    body: {
      paymentReference?: string;
      paymentAmount: { value: number; currency: string };
    },
    requestOptions: RatalityRequestOptions
  ): Promise<any> {
    return this.post(
      `api/v2/reservation/${reservationReference}/payment`,
      body,
      requestOptions
    );
  }

  getPaymentOutstanding(
    reservationReference: string,
    requestOptions: RatalityRequestOptions
  ): Promise<any> {
    return this.get(
      undefined,
      `api/v2/reservation/${reservationReference}/payment`,
      requestOptions
    );
  }

  getDepositOutstanding(
    reservationReference: string,
    requestOptions: RatalityRequestOptions
  ): Promise<any> {
    return this.get(
      undefined,
      `api/v2/reservation/${reservationReference}/deposit`,
      requestOptions
    );
  }

  postDepositPayment(
    reservationReference: string,
    body: {
      paymentReference?: string;
      paymentAmount: { value: number; currency: string };
    },
    requestOptions: RatalityRequestOptions
  ): Promise<any> {
    return this.post(
      `api/v2/reservation/${reservationReference}/deposit`,
      body,
      requestOptions
    );
  }

  getBranches(requestOptions: RatalityRequestOptions): Promise<Branch[]> {
    return this.get('branches', `api/v2/branch`, requestOptions);
  }

  getBranch(
    branchId: number,
    requestOptions: RatalityRequestOptions
  ): Promise<Branch> {
    return this.get(undefined, `api/v2/branch/${branchId}`, requestOptions);
  }

  // NOTE: Hack to get verification email without completing a payment (bert jonk flow)
  confirmationEmail(
    reservationReference: string,
    bookingReference: string,
    language: 'nl' | 'en',
    requestOptions: RatalityRequestOptions
  ): Promise<unknown> {
    return this.put(
      `/api/v2/reservation/${reservationReference}/${bookingReference}/invoice-status?language=${language}`,
      { invoiceStatus: 'Confirmed' },
      requestOptions
    );
  }

  sendVehicleCommand(
    command: VehicleCommand,
    reservationReference: string,
    bookingReference: string,
    requestOptions: RatalityRequestOptions
  ) {
    return this.post(
      `api/v2/reservation/${reservationReference}/rentals/${bookingReference}/vehicle/commands?ignoreValidation=false`,
      { command },
      requestOptions
    );
  }

  getDamageTemplate(
    reservationReference: string,
    bookingReference: string,
    requestOptions: RatalityGetRequestOptions
  ): Promise<DamageTemplate> {
    const url = `/api/v2/reservation/${reservationReference}/rentals/${bookingReference}/vehicle/template`;
    return this.get(undefined, url, requestOptions);
  }

  getDefects(
    reservationReference: string,
    bookingReference: string,
    requestOptions: RatalityGetRequestOptions
  ): Promise<Defect[]> {
    const url = `/api/v2/reservation/${reservationReference}/rentals/${bookingReference}/vehicle/defects`;
    return this.get('vehicleDefects', url, requestOptions);
  }

  postDefects(
    reservationReference: string,
    bookingReference: string,
    defects: Defect[],
    requestOptions: RatalityGetRequestOptions
  ): Promise<unknown> {
    const url = `/api/v2/reservation/${reservationReference}/rentals/${bookingReference}/vehicle/defects`;
    const body = { vehicleDefects: defects };
    return this.post(url, body, requestOptions);
  }

  getExtension(
    reservationReference: string,
    bookingReference: string,
    requestOptions: RatalityGetRequestOptions
  ): Promise<unknown> {
    const url = `/api/v2/reservation/${reservationReference}/rentals/${bookingReference}/vehicle/extension`;
    return this.get(undefined, url, requestOptions);
  }

  getFleetAllocations(
    requestOptions: RatalityGetRequestOptions
  ): Promise<unknown> {
    const url = `/api/v2/reports/rentals/fleet-allocations`;
    return this.get(undefined, url, requestOptions);
  }

  postPromotionCode(
    reservationReference: string,
    code: string,
    requestOptions: RatalityGetRequestOptions
  ): Promise<unknown> {
    const url = `/api/v2/reservation/${reservationReference}/promotion-code`;
    const body = { code };
    return this.post(url, body, requestOptions);
  }

  deletePromotionCode(
    reservationReference: string,
    requestOptions: RatalityRequestOptions
  ): Promise<any> {
    return this.delete<any>(
      `api/v2/reservation/${reservationReference}/promotion-code`,
      requestOptions
    );
  }

  getChecklist(
    reservationReference: any,
    bookingReference: any,
    checklistType: string,
    requestOptions: RatalityGetRequestOptions
  ) {
    const url = `api/v2/reservation/${reservationReference}/rentals/${bookingReference}/vehicle/check-list/${checklistType}`;

    return this.get<any>(undefined, url, requestOptions);
  }

  postExtension(
    reservationReference: string,
    bookingReference: string,
    dateTo: string,
    requestOptions: RatalityGetRequestOptions
  ) {

    console.log('POST CALL R2 : ', reservationReference, bookingReference, dateTo)
    const url = `api/v2/reservation/${reservationReference}/rentals/${bookingReference}/vehicle/extension`;
    return this.post<any>(url, { dateTo }, requestOptions);
  }

  postChecklist(
    reservationReference: string,
    bookingReference: string,
    checklistType: string,
    checklistItems: CheckListValue[],
    requestOptions: RatalityRequestOptions
  ) {
    const url = `api/v2/reservation/${reservationReference}/rentals/${bookingReference}/vehicle/check-list/${checklistType}`;
    console.log('CHECKLISTITEMS', checklistItems);

    return this.post<any>(url, { checklistItems }, requestOptions);
  }

  postReservationStatus(
    reservationReference: string,
    bookingReference: string,
    checklistType: string,
    completed: boolean,
    requestOptions: RatalityRequestOptions
  ) {
    const url = `api/v2/reservation/${reservationReference}/rentals/${bookingReference}/vehicle/inspection/${checklistType}`;
    // const now = new Date();

    const body = {
      completed,
      // dateTime: now.toISOString(),
    };

    return this.post<any>(url, body, requestOptions);
  }

  putVehicle(
    reservationReference: string,
    bookingReference: string,
    vehicleId: number,
    requestOptions: RatalityRequestOptions
  ) {
    const url = `api/v2/reservation/${reservationReference}/rentals/${bookingReference}/vehicle`;
    return this.put<any>(url, { vehicleId }, requestOptions);
  }

  // TODO: Review
  getSchedules(
    filters: SchedulesFilter,
    requestOptions: RatalityRequestOptions
  ) {
    const { dateFrom, dateTo, reservationReference } = filters;

    const searchParams = new URLSearchParams();

    if (dateFrom) {
      searchParams.append('dateFrom', dateFrom);
    }

    if (dateTo) {
      searchParams.append('dateTo', dateTo);
    }

    if (reservationReference) {
      searchParams.append('reservationReference', reservationReference);
    }

    const url = `api/v2/schedules`;

    return this.get<Schedules>(undefined, url, {
      ...requestOptions,
      searchParams,
    });
  }

  private async put<T>(
    path: string,
    body: any,
    options: RatalityRequestOptions
  ): Promise<T> {
    const { baseUrl } = this.config;
    const { clientId, channelId } = options;

    const url = `${baseUrl}/${path}`;
    console.log(`PUT: ${url}`);

    const headers: AxiosRequestHeaders = {
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      clientId: `${clientId}`,
      channelId: `${channelId}`,
    };

    const { data } = await axios.put(url, body, { headers });

    console.log('data: ', data);

    return data;
  }

  private async patch<T>(
    path: string,
    body: any,
    options: RatalityRequestOptions
  ): Promise<T> {
    const { baseUrl } = this.config;
    const { clientId, channelId } = options;

    const url = `${baseUrl}/${path}`;
    console.log(`PATCH: ${url}`);

    const headers: AxiosRequestHeaders = {
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      clientId: `${clientId}`,
      channelId: `${channelId}`,
    };

    const { data } = await axios.patch(url, body, { headers });

    console.log('data: ', data);

    return data;
  }

  private async post<T>(
    path: string,
    body: any,
    options: RatalityRequestOptions
  ): Promise<T> {
    const { baseUrl } = this.config;
    const { clientId, channelId } = options;

    const url = `${baseUrl}/${path}`;
    console.log(`POST: ${url}`);

    const headers: AxiosRequestHeaders = {
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      clientId: `${clientId}`,
      channelId: `${channelId}`,
    };

    const { data } = await axios.post(url, body, { headers });

    console.log('data: ', data);

    return data;
  }

  private async delete<T>(
    path: string,
    options: RatalityRequestOptions
  ): Promise<T> {
    const { baseUrl } = this.config;
    const { clientId, channelId } = options;

    const url = `${baseUrl}/${path}`;
    console.log(`POST: ${url}`);

    const headers: AxiosRequestHeaders = {
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      clientId: `${clientId}`,
      channelId: `${channelId}`,
    };

    const { data } = await axios.delete(url, { headers });

    console.log('data: ', data);

    return data;
  }

  private async getToken(): Promise<string> {
    const url = `${this.config.baseUrl}/api/authenticate`;
    const { username, password } = this.config;

    const headers: AxiosRequestHeaders = {
      'Content-Type': 'application/json',
    };

    const { data } = await axios.post<{ id_token: string }>(
      url,
      { username, password, rememberMe: true },
      { headers }
    );

    return data.id_token;
  }

  private async get<T>(
    key: string | undefined,
    path: string,
    options: RatalityGetRequestOptions
  ): Promise<T> {
    const { baseUrl } = this.config;
    const { clientId, channelId, searchParams } = options;

    if (!this.token) {
      throw Error('No token, did you call login()?');
    }

    const url = `${baseUrl}/${path}${
      searchParams ? `?${searchParams.toString()}` : ''
    }`;
    console.log(`GET: ${url}`);

    const headers: AxiosRequestHeaders = {
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      clientId: `${clientId}`,
      channelId: `${channelId}`,
    };

    const { data } = await axios.get(url, { headers });

    if (key) {
      return data[key];
    } else {
      return data;
    }
  }
}
