import axios, { Axios } from "axios";
import { BehaviorSubject, first, firstValueFrom, Observable } from "rxjs";

import { AuthResult } from "./models";
import { RentvisieConfig } from "./rentvisie";
import { logRequest, logResponse } from "./util";

export class ApiClient {
  get baseUrl(): string {
    return this.config.baseUrl;
  }

  get client(): string {
    return this.config.client;
  }

  private readonly refreshToken$$ = new BehaviorSubject<string | null>(null);
  private readonly token$$ = new BehaviorSubject<string | null>(null);

  readonly unauthenticated: Axios;
  readonly authenticated: Axios;

  get hasToken(): boolean {
    return !!this.token$$.getValue();
  }

  get hasRefreshToken(): boolean {
    return !!this.refreshToken$$.getValue();
  }

  constructor(private config: RentvisieConfig) {
    this.unauthenticated = axios.create({ baseURL: config.baseUrl });
    this.unauthenticated.interceptors.request.use(logRequest);
    this.unauthenticated.interceptors.response.use(logResponse);

    this.authenticated = axios.create({ baseURL: config.baseUrl });
    this.authenticated.interceptors.request.use(logRequest);
    this.authenticated.interceptors.response.use(logResponse);

    this.authenticated.interceptors.request.use(
      async (config) => {
        const token = await this.getToken();

        const authHeader = { Authorization: `Bearer ${token}` };

        if (!config.headers) {
          config.headers = authHeader;
        } else {
          config.headers = { ...config.headers, ...authHeader };
        }

        return config;
      }
    );

    this.authenticated.interceptors.response.use(
      undefined,
      async (error) => {
        console.log('Error in response: ', error);
        console.log('url: ', error.response?.config?.url);

        console.log('error data: ', error.response?.data)
        if (error?.response?.status === 401) {
          console.log('Request failed token invalid / expired: ', error.response.config.url);

          if (this.hasRefreshToken) {
            console.log('Attempt to refresh token');
            await this.refreshToken();

            console.log('Refreshed token, retrying request');
            return this.authenticated.request(error.config)
          }
        }

        return Promise.reject(error);
      }
    );
  }

  async firebaseSignIn(idToken: string): Promise<AuthResult> {
    const { data } = await this.unauthenticated.post<AuthResult>(
      'auth/firebase',
      { idToken, client: this.client }
    );

    this.refreshToken$$.next(data.refreshToken);
    this.token$$.next(data.token);

    return data;
  }

  signout(): void {
    this.token$$.next(null);
    this.refreshToken$$.next(null);
  }

  async refreshToken(): Promise<AuthResult> {
    if (!this.hasRefreshToken) {
      return Promise.reject('No refresh token found');
    }

    const refreshToken = this.refreshToken$$.getValue();

    const { data } = await this.unauthenticated.post<AuthResult>(
      'auth/refresh',
      { refreshToken }
    );

    this.refreshToken$$.next(data.refreshToken);
    this.token$$.next(data.token);

    return data;
  }

  getToken(): Promise<string> {
    const filtered = this.token$$.pipe(
      first(value => !!value)
    ) as Observable<string>;
    return firstValueFrom(filtered);
  }
}
