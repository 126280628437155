import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"

const HighlightedBlogs = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiArticle(limit: 3, sort: { order: DESC, fields: createdAt }) {
        nodes {
          heroImage {
            formats {
              small {
                url
              }
            }
            alternativeText
            url
          }
          description
          slug
          title
        }
      }
    }
  `)
  return (
    <div
      className="container"
      style={{ marginBottom: "3rem", textAlign: "center" }}
    >
      <div className="blogs">
        {data.allStrapiArticle.nodes.map(node => (
          <Link
            to={`/blogs/${node.slug.toLowerCase().replaceAll(" ", "-")}`}
            className="none"
            key={node.strapi_id}
          >
            <div className="blogs__card">
              {node.heroImage.url && (
                <img
                  src={node.heroImage.url}
                  alt={node.heroImage.alternativeText}
                  title={node.heroImage.caption}
                  className="img-fluid"
                ></img>
              )}
              <h2 style={{ marginTop: 12 }}>{node.title}</h2>
              <p>{node.description}</p>
            </div>
          </Link>
        ))}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Link className="btn button-primary" to={"/blogs"}>
          Bekijk alle blogs
        </Link>
      </div>
    </div>
  )
}

export default HighlightedBlogs
