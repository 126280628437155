import { useState, useEffect } from "react"
import DateTimeRangePicker from "../shared/cta-form/datetimerangepicker"
import LocationSelect from "../shared/cta-form/location-select"
import Settings from "../settings.json"
import { publicDay, fifthOfMayDay } from "../shared/publicDay"

// note: we parse categoryId and vehicleClassId

const LpForm = props => {
  // TODO: Move duplicate code to datetimepicker
  const [showDateInPastWarning, setShowDateInPastWarning] = useState(false)
  const [showThirtyDaysWarning, setShowThirtyDaysWarning] = useState(false)
  const [showCoolingTruckWarning, setShowCoolingTruckWarning] = useState(false)
  const [showSundayWarning, setShowSundayWarning] = useState(false)
  const [showNextSundayWarning, setShowNextSundayWarning] = useState(false)
  const [coolingTruck, setCoolingTruck] = useState(false) // Temp included for loading of Coolings
  const [holidayWarning, setHolidayWarning] = useState(false)
  const [showFifthOfMayWarning, setShowFifthOfMayWarning] = useState(false)
  const [showTempWarning, setShowTempWarning] = useState(false)

  const today = new Date()
  const tomorrow = new Date()
  tomorrow.setDate(today.getDate() + 1)
  tomorrow.setHours(Settings.dayStartHour)
  tomorrow.setMinutes(Settings.dayStartMinute)
  const endOfTomorrow = new Date()
  endOfTomorrow.setDate(today.getDate() + 1)
  endOfTomorrow.setHours(Settings.dayEndHour)
  endOfTomorrow.setMinutes(Settings.dayEndMinute)
  const endOfToday = new Date()
  endOfToday.setDate(today.getDate())
  endOfToday.setHours(Settings.dayEndHour)
  endOfToday.setMinutes(Settings.dayEndMinute)

  let startRent = {}
  let endRent = {}

  //copy, if available, previous entered field and convert it to correct date format
  if (props.pickupDate && props.pickupTime) {
    const ddmmyyyy = props.pickupDate.split("-")
    const hhmm = props.pickupTime.split(":")
    startRent = new Date(ddmmyyyy[1] + "/" + ddmmyyyy[0] + "/" + ddmmyyyy[2])
    startRent.setHours(hhmm[0])
    startRent.setMinutes(hhmm[1])
    //startRent = new Date(ddmmyyyy[1] + "/" + ddmmyyyy[0] + "/" + ddmmyyyy[2] + "T" + hhmm[0] + "/" + hhmm[1] + "/00");
    //startRent = new Date(`${mm}-${dd}-${yyyy}`);
    // same action for return date
    if (props.returnDate && props.returnTime) {
      const ddmmyyyy = props.returnDate.split("-")
      const hhmm = props.returnTime.split(":")
      endRent = new Date(ddmmyyyy[1] + "/" + ddmmyyyy[0] + "/" + ddmmyyyy[2])
      endRent.setHours(hhmm[0])
      endRent.setMinutes(hhmm[1])
    } else {
      endRent = endOfTomorrow
    }
  } else if (new Date().getHours() >= 17) {
    startRent = new Date()
    startRent.setDate(today.getDate() + 1)
    startRent.setHours(Settings.dayStartHour)
    startRent.setMinutes(Settings.dayStartMinute)
    endRent = endOfTomorrow
  } else {
    startRent = new Date()
    startRent.setDate(today.getDate())
    startRent.setHours(today.getHours() + 1)
    startRent.setMinutes(0)
    endRent = endOfToday
  }

  // duplicate code @ form.js

  //look at datetimerangepicker.js (useeffect on startdate change
  // to see the tests on public holidays, 5th of May and sundays

  const [startDate, setStartDate] = useState(startRent)
  const [endDate, setEndDate] = useState(endRent)

  const parsedStartDate = startDate.toLocaleString("nl-NL")
  const parsedEndDate = endDate.toLocaleString("nl-NL")

  const categoryId = props.category || ""
  const vehicleClassId = props.vehicleClassId || ""
  const locationId = props.locationId || ""
  const branchId = props.branchId || ""

  const parsedLocation = { value: locationId }

  const getBranchName = branchId => {
    const branchArray = Settings.locations.filter(
      location => location.branchId === branchId
    )
    return branchArray[0].label
  }

  const switchLocationMessage = () => {
    alert(
      "Gebruik de filterknoppen om de voertuigen van de desbetreffende locaties te laden."
    )
  }

  return (
    <form action={`/huren/voertuig-kiezen`} method="get">
      {showTempWarning && (
        <div className="hero-form__warning">
          <div> (!) </div>
          <div>
            <p>
              Filiaal maandag weer open. U kunt nu bij een van onze andere
              locaties terecht.
            </p>
          </div>
        </div>
      )}
      {showDateInPastWarning && (
        <div className="hero-form__warning">
          <div> (!) </div>
          <div>
            <p>
              Er is een tijd in het verleden geselecteerd: De tijd is aangepast
              naar een volgende gelegenheid.
            </p>
          </div>
        </div>
      )}
      {showThirtyDaysWarning && (
        <div className="hero-form__warning">
          <div> (!) </div>
          <div>
            <p>
              Voor huurperiodes vanaf 30 dagen maken wij graag een offerte op
              maat. Neem contact met ons op via{" "}
              <a href="tel:0883745900">088-3745900</a>
            </p>
            <p>
              Kortere huurperiodes kunnen gespecificeerd worden door de datum(s)
              aan te passen.
            </p>
          </div>
        </div>
      )}
      {showSundayWarning && (
        <div className="hero-form__warning">
          <div> (!) </div>
          <div>
            <p>Vandaag zijn wij gesloten, selecteer een andere dag.</p>
          </div>
        </div>
      )}
      {showNextSundayWarning && (
        <div className="hero-form__warning">
          <div> (!) </div>
          <div>
            <p>
              Op zondag zijn wij gesloten. Vaak is het mogelijk om het voertuig
              de dag ervoor op te halen zonder meerprijs. Neem contact met ons
              op via <a href="tel:0883745900">088-3745900</a>
            </p>
          </div>
        </div>
      )}
      {holidayWarning && (
        <div className="hero-form__warning">
          <div> (!) </div>
          <div>
            <p>
              Op publieke feestdagen zijn wij gesloten. Reserveren in
              telefonisch overleg via <a href="tel:0883745900">088-3745900</a>
            </p>
          </div>
        </div>
      )}

      {showFifthOfMayWarning && (
        <div className="hero-form__warning">
          <div> (!) </div>
          <div>
            <p>
              Op 5 mei zijn we om 12.30 uur gesloten. Als U op 5 mei vanaf 12.30
              uur wilt huren, neem dan contact met ons op via{" "}
              <a href="tel:0883745900">088-3745900</a>
            </p>
          </div>
        </div>
      )}
      <input type="hidden" name="ophalen" value={parsedStartDate}></input>
      <input type="hidden" name="retour" value={parsedEndDate}></input>
      <input type="hidden" name="vehicleClassId" value={vehicleClassId}></input>
      <input type="hidden" name="categoryId" value={categoryId}></input>
      <input type="hidden" name="location" value={locationId}></input>

      <div className="hero-form">
        <div className="hero-form__search-wrapper">
          {/* TODO: MAP LOCATION TO BRANCH IN SETTINGS.JSON */}
          <div className="hero-form__search-input">
            <div className="hero-form__search-input" name="picker">
              <div className="hero-form__search-input-text">Locatie</div>
              <button
                className=""
                disabled={true}
                onHover={() => {
                  switchLocationMessage()
                }}
                style={{
                  border: "1px solid red",
                  width: "100%",
                  height: "48px",
                  borderRadius: "0.5rem",
                }}
              >
                {getBranchName(branchId)}
              </button>
            </div>
          </div>

          <div className="hero-form__search-input" name="picker">
            <div className="hero-form__search-wrapper-header-datetimepicker">
              <div className="hero-form__datetimerange-text">Ophalen</div>
              <div className="hero-form__datetimerange-text">Retour</div>
            </div>
            <DateTimeRangePicker
              today={today}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setShowDateInPastWarning={setShowDateInPastWarning}
              setShowThirtyDaysWarning={setShowThirtyDaysWarning}
              setShowCoolingTruckWarning={setShowCoolingTruckWarning}
              setShowSundayWarning={setShowSundayWarning}
              setShowNextSundayWarning={setShowNextSundayWarning}
              setHolidayWarning={setHolidayWarning}
              setShowFifthOfMayWarning={setShowFifthOfMayWarning}
              coolingTruck={coolingTruck}
              setShowTempWarning={setShowTempWarning}
              location={parsedLocation}
            ></DateTimeRangePicker>
          </div>
          <div className="hero-form__search-input" name="picker">
            <div className="hero-form__search-input-text transparant">
              Bevestig
            </div>
            <input
              type="submit"
              value="Voertuig zoeken"
              className="hero-form__button button-primary"
              disabled={
                showThirtyDaysWarning ||
                showCoolingTruckWarning ||
                showSundayWarning ||
                showNextSundayWarning ||
                holidayWarning ||
                showTempWarning ||
                (showFifthOfMayWarning &&
                  (startDate.getHours() > 12 ||
                    (startDate.getHours() === 12 &&
                      startDate.getMinutes() > 30)))
              }
            />
          </div>
        </div>
      </div>
    </form>
  )
}

export default LpForm
