import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setCustomer } from '../../redux/customerSlice';
import { API_URL, clientId, channelId } from '../../constants/api';

// TODO:
// Webhook stripe
// IF PAST PICKUPDATE -> do not show

const FindReservation = ({ props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.location?.search) {
      const query = new URLSearchParams(props.location.search)
      const paramLastName = query.get('lastName');
      const paramReservationReference = query.get('reservationReference');

      setState({
        lastName: paramLastName,
        reservationReference: paramReservationReference
      })
    }

  }, [] )
  
  const [state, setState] = useState({
    lastName: '',
    reservationReference: '',
  });
  
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setState((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const searchCustomer = async () => {
    if (!state.reservationReference || !state.lastName) {
      return;
    }

    setLoading(true);

    try {
      const reservation = await axios.post(
        `${API_URL}/customers/reservations/${state.reservationReference}/bookings/${state.reservationReference}01`, { lastName: state.lastName } , {
          headers: {
            clientId,
            channelId,
          }
        }
      );

      dispatch(setCustomer({
        customer: reservation.data,
        lastName: state.lastName,
        reservationReference: state.reservationReference
      }));

      localStorage.setItem('customerReservation', reservation.data);

      setError('')

    } catch (e) {
      setError('Reservering niet gevonden, probeer het opnieuw');
      dispatch(setCustomer({
        customer: null,
        lastName: null,
        reservationReference: null
      }));
      localStorage.setItem('customerReservation', null);

    }

    setLoading(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!state.lastName || state.lastName.length < 2) {
      alert('Vul uw achternaam in');
    }
    if (!state.reservationReference) {
      alert(
        'Vul het reserveringsnummer in. Deze staat in de e-mails die u heeft gekregen'
      );
    }

   searchCustomer();
  };

  return (
    <>
      <form onSubmit={handleSubmit} className='search-customer-wrapper'>
      <TextField
        variant="outlined"
        margin="dense"
        value={state.lastName}
        onChange={handleChange}
        label="Achternaam of Bedrijfsnaam"
        name="lastName"
        autoFocus
        fullWidth
      />

      <TextField
        variant="outlined"
        margin="dense"
        value={state.reservationReference}
        onChange={handleChange}
        label="ReserveringsNr."
        name="reservationReference"
        fullWidth
      />

      {!loading ?
        <Button type="submit" variant="contained" fullWidth>
        Zoeken
      </Button> :
      <p>Gegevens ophalen...</p>
      }
    </form>
      {error && <p>{error}</p>}
    </>
  );
};

export default FindReservation;
