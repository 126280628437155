import React, { useState, useEffect } from "react"
import { Rental, Rentvisie, InsuranceOption } from "@bakkie/ratality"
import Contact from "../shared/contact"
import Footer from "../shared/footer"
import ReservationHeader from "../shared/reservation-header"
import { navigate } from "gatsby"
import { ValidatorUtil } from "../validator.util"
import { Container } from "react-bootstrap"
import OwnRisk from "../../../images/svg/own_risk.png"
import Insurance from "../../../images/svg/insurance.png"
import Distance from "../../../images/svg/distance.png"
import Other from "../../../images/svg/other.png"
import Vehicle from "../../../images/svg/vehicle.png"
import SEO from "../../seo"
import FreeParking from "../shared/freeParking"
import Spinner from "../../shared/spinner"
import { SelectableOptions } from "../../../interfaces/ReservationInterfaces"

declare global {
  interface Window {
    dataLayer: any
  }
}
interface PutOptions {
  ids?: number[]
  mileagePackageOptionId?: number
  insuranceOptionId?: number
}

Rentvisie.setConfig({
  baseUrl: "https://api.rentvisie.com/api",
  client: "bertjonk",
  useGuestToken: true,
})

function getPrice(
  rental: Rental,
  selectedOptions: SelectableOptions,
  discount: string
): number {
  const basePrice = rental.totalPrice.value

  const optionsPrice = Object.keys(selectedOptions)
    .map(key =>
      selectedOptions[key]
        .map(optionId => getOptionPrice(rental, key, optionId))
        .reduce((acc, value) => acc + value, 0)
    )
    .reduce((acc, value) => acc + value, 0)

  if (discount) {
    const discountPercentage = parseInt(discount)
    return (basePrice * (100 - discountPercentage)) / 100 + optionsPrice
  }
  return basePrice + optionsPrice
}

function getOptionPrice(
  rental: Rental,
  optionType: string,
  optionId: number
): number {
  const options = rental.vehicleClass[optionType]
  const optionPerDay = options?.find(
    option =>
      option.optionId == optionId && option.price.unitDescription == "per Day"
  )
  const optionPerTrip = options?.find(
    option =>
      option.optionId == optionId && option.price.unitDescription !== "per Day"
  )
  if (optionPerDay) {
    return optionPerDay.price.value * rental.days
  } else if (optionPerTrip) {
    return optionPerTrip.price.value
  } else {
    return 0
  }
}

const ReservationSelectUpsell = ({ rental, id }) => {
  const defaultMileageOption = rental.vehicleClass.mileagePackageOptions.find(
    option => option.description.toLowerCase().includes("2100km")
  )
  const defaultInsuranceOption = rental.vehicleClass.insuranceOptions.find(
    option => option.description.toLowerCase().includes("verlaagd")
  )
  const defaultPackageOption = rental.vehicleClass.optionalOptions.find(
    option => option.description.toLowerCase().includes("wintersportpakket")
  )

  const [selectedOptions, setSelectedOptions] = useState<SelectableOptions>({
    mileagePackageOptions: [defaultMileageOption.optionId],
    insuranceOptions: [defaultInsuranceOption.optionId],
    optionalOptions: [defaultPackageOption.optionId],
  })


  const [showSpinner, setShowSpinner] = useState(false)
  const [showExtras, setShowExtras] = useState(false)
  const [showExtraText, setShowExtraText] = useState(true)
  const category = rental.vehicleClass.category.categoryId

  const toggleExtras = () => {
    setShowExtras(true)
    setShowExtraText(false)
  }

  const hideExtras = () => {
    setShowExtras(false)
    setShowExtraText(true)
  }

  let includeVat = "no"
  let discount = ""
  if (typeof window !== "undefined") {
    includeVat = localStorage.getItem("includeVat")
    if (category === 542 || category === 543) {
      discount = localStorage.getItem("promocodeWispo")
    }
  }

  const valueChanges = (key: string, ids: number[]) => {
    const newSelectedOptions = { ...selectedOptions, [key]: ids }
    setSelectedOptions(newSelectedOptions)
  }

  const handleCheckBox = (key: string, event: any) => {
    const checked = event.target.checked
    const value = event.target.value
    const currentValues = selectedOptions[key] ?? []
    if (checked) {
      valueChanges(key, [...currentValues, value])
    } else {
      valueChanges(
        key,
        currentValues.filter(id => id !== value)
      )
    }
  }
  const mapped = Object.entries(selectedOptions).map(
    ([key, value]) => `${key}=${value.join(",")}`
  )

  const createReservation = rentalId => {
    createReservationAndSetOptions(rentalId)
    setShowSpinner(true)
  }

  const createReservationAndSetOptions = async rentalId => {
    const reservation = await Rentvisie.postReservation(rentalId)
    const reservationReference = reservation.reservationReference
    const bookingReference = reservation.rentals[0].bookingReference

    const hasInsuranceOptions = ValidatorUtil.validateInsuranceOptions(
      selectedOptions.insuranceOptions
    )
    const hasMileageOptions = ValidatorUtil.validateMileageOptions(
      selectedOptions.mileagePackageOptions
    )
    const hasOptionalOptions = ValidatorUtil.validateOptionalOptions(
      selectedOptions.optionalOptions
    )

    const data: PutOptions = {}

    if (hasInsuranceOptions) {
      data.insuranceOptionId = Number(selectedOptions.insuranceOptions[0])
    }
    if (hasMileageOptions) {
      data.mileagePackageOptionId = Number(
        selectedOptions.mileagePackageOptions[0]
      )
    }
    if (hasOptionalOptions) {
      data.ids = selectedOptions.optionalOptions.map(id => Number(id))
    }

    // set overrides administration costs and adblue
    // await Rentvisie.setAllOptions(reservationReference, bookingReference, data)  

    await Rentvisie.addAllOptions(reservationReference, bookingReference, data)


    const url = `/wintersport/checkout?${mapped.join(
      "&"
    )}&reservationReference=${reservation.reservationReference}`
    setTimeout(() => navigate(url), 1250)
  }

  const [showButton, setShowButton] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 250) {
        setShowButton(true)
      } else {
        setShowButton(false)
      }
    })
  }, [])

  const dataLayer = window.dataLayer || []
  const [pushedDataLayer, setPushedDataLayer] = useState(false)
  if (!pushedDataLayer) {
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: `begin_checkout`,
      ecommerce: {
        items: [
          {
            item_name: `${rental.vehicleClass.description}`,
            item_id: `${rental.vehicleClass.vehicleClassId}`,
            price: `${rental.price.value}`,
            price_excl_vat: `${
              Math.round((rental.price.value / 1.21) * 100) / 100
            }`,
            currency: "EUR",
            item_category: `${rental.vehicleClass.category.categoryId}`,
            quantity: 1,
            pick_up_location_id: `${rental.pickupLocation.locationId}`,
            pick_up_location: `${rental.pickupLocation.town}`,
          },
        ],
      },
    })
    setPushedDataLayer(true)
  }

  // Separate Mileagepackageoptions => Backend updates required for robust solutions //
  const totalDays = rental.days

  let mileageDefault = []
  let mileageDefaultNew = []
  let mileageDay = []
  // let mileageWeekend = [];
  let mileageWeekA = []
  let mileageWeekB = []
  let mileageFilter = []
  if (rental.vehicleClass.mileagePackageOptions) {
    mileageDefault = rental.vehicleClass.mileagePackageOptions.filter(option =>
      option.description.includes("Inbegrepen")
    )
    if (totalDays <= 1) {
      mileageDay = rental.vehicleClass.mileagePackageOptions.filter(option =>
        option.description.includes(" 500")
      )
      mileageDefaultNew = mileageDefault.map(obj => ({ ...obj, km: 100 }))
    } else if (totalDays > 1 && totalDays < 7) {
      mileageDay = rental.vehicleClass.mileagePackageOptions.filter(option =>
        option.description.includes(" 500")
      )
      // mileageWeekend = rental.vehicleClass.mileagePackageOptions.filter((option) => option.description.includes('200'));
      mileageDefaultNew = mileageDefault.map(obj => ({ ...obj, km: 100 }))
    } else {
      mileageWeekA = rental.vehicleClass.mileagePackageOptions.filter(option =>
        option.description.includes("2100")
      )
      mileageWeekB = rental.vehicleClass.mileagePackageOptions.filter(option =>
        option.description.includes("3500")
      )
      mileageDefaultNew = mileageDefault.map(obj => ({ ...obj, km: 150 }))
    }
  }

  const mileageDayNew = mileageDay.map(obj => ({ ...obj, km: 500 }))
  const MileageWeekANew = mileageWeekA.map(obj => ({ ...obj, km: 300 }))
  const mileageWeekBNew = mileageWeekB.map(obj => ({ ...obj, km: 500 }))
  mileageFilter = mileageDefaultNew.concat(
    mileageDayNew,
    MileageWeekANew,
    mileageWeekBNew
  )
  const defaultInsuranceOptions = rental.vehicleClass.insuranceOptions
    ? rental.vehicleClass.insuranceOptions.filter(option =>
        option.description.toLowerCase().includes("verlaagd")
      )
    : []

    const defaultMileagePackageOption = rental.vehicleClass.mileagePackageOptions
    ? rental.vehicleClass.mileagePackageOptions.filter(option =>
        option.description.toLowerCase().includes("2100km")
      )
    : []

  const wispoFilter = rental.vehicleClass.optionalOptions
    ? rental.vehicleClass.optionalOptions.filter(
        option => option.category === "Wintersport"
      )
    : []
  const optionFilter = rental.vehicleClass.optionalOptions
    ? rental.vehicleClass.optionalOptions.filter(
        option =>
          option.description.toLowerCase().includes("skibox") ||
          option.description.toLowerCase().includes("ruit")
      )
    : []

  const compare = (a, b) => {
    if (a.price.value < b.price.value) {
      return -1
    }
    if (a.price.value > b.price.value) {
      return 1
    }
    return 0
  }

  //   if rental.vehicleClass.insuranceOptions === undefined  rental.vehicleClass.insuranceOptions ;
  return (
    <div>
      <SEO
        title="Bert Jonk Autoverhuur - Personaliseer"
        index={false}
        follow={true}
      />
      <ReservationHeader activeStepIndex={2} />
      {!showSpinner ? (
        <React.Fragment>
          <Container fluid="xxl">
            <div className="reservation__title">
              <h2>Personaliseer jouw voertuig</h2>
            </div>
            {!rental.vehicleClass.insuranceOptions &&
            !rental.vehicleClass.mileagePackageOptions &&
            !rental.vehicleClass.optionalOptions ? (
              <p>
                Voor dit voertuig kun je geen extra's selecteren. Ga{" "}
                <span onClick={() => createReservation(id)}>
                  direct naar de digitale kassa.
                </span>
              </p>
            ) : (
              <p>
                Alles dat u nodig heeft voor uw wintersport zit bij de prijs in.{" "}
                <span
                  onClick={() => createReservation(id)}
                  className="btn btn-outline-secondary"
                >
                  direct naar de digitale kassa.
                </span>
              </p>
            )}

            <div
              className="reservation-select-wrapper my-l"
              style={{ paddingBottom: "8rem" }}
            >
              <div className="reservation-select-left">
                <div className="extra-wrapper">
                  <div className="extra-wrapper__title">
                    <img src={Vehicle} alt="Overige Opties"></img>
                    <h3>Inbegrepen Opties</h3>
                  </div>

                  <div className="extrabox-grid">
                    {wispoFilter.sort(compare).map(option => (
                      <div key={option.optionId}>
                        <input
                          type="radio"
                          id={option.optionId}
                          name="optionId"
                          value={option.price.value}
                          checked={true}
                        />
                        <label htmlFor={option.optionId}>
                          <div className="extrabox">
                            <h3>{option.description}</h3>
                            <p className="dark-gray">
                              {option.labelMarkup.replace(/(<([^>]+)>)/gi, "")}
                            </p>
                          </div>
                        </label>
                      </div>
                    ))}
                    {defaultInsuranceOptions.sort(compare).map(option => (
                      <div key={option.optionId}>
                        <input
                          type="radio"
                          id={option.optionId}
                          name="insuranceId"
                          value={option.price.value}
                          checked={true}
                        />
                        <label htmlFor={option.optionId}>
                          <div className="extrabox">
                            <h3>
                              Verlaagd eigen risico van €{" "}
                              {option.insuranceExcessAmount.value}
                            </h3>
                            <p className="dark-gray">
                              In geval van schade ben je aansprakelijk tot een
                              bedrag van € {option.insuranceExcessAmount.value}{" "}
                              per schadegeval <br></br>
                              <span style={{ fontStyle: "italic" }}>
                                (bovenhoofdse schade uitgezonderd)
                              </span>{" "}
                            </p>
                          </div>
                        </label>
                      </div>
                    ))}
                    {defaultMileagePackageOption.sort(compare).map(option => (
                      <div key={option.optionId}>
                        <input
                          type="radio"
                          id={option.optionId}
                          name="mileageId"
                          value={option.price.value}
                          checked={true}
                        />
                        <label htmlFor={option.optionId}>
                          <div className="extrabox">
                            <h3>Totaal {rental.includedMileage + (150 * rental.days)} inbegrepen.</h3>
                            <p className="dark-gray">
                              U krijgt {rental.includedMileage + (150 * rental.days)} vrije km. Genoeg om heen en weer
                              naar Oostenrijk, Frankrijk of Italie te rijden. de
                              prijs.
                            </p>
                          </div>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="extra-wrapper">
                  {rental.vehicleClass.optionalOptions && showExtraText && (
                    <span onClick={toggleExtras} className="mouse">
                      <div className="extra-wrapper__title">
                        <img src={Other} alt="Overige Opties"></img>
                        <h3 className="bold">Toon meer opties {">>"}</h3>
                      </div>
                    </span>
                  )}
                  {showExtras && (
                    <>
                      <div className="extra-wrapper__title">
                        <img src={Other} alt="Overige Opties"></img>
                        <h3>Overige Opties</h3>
                      </div>

                      <h3 style={{ paddingTop: 12 }}>Overig</h3>
                      <div className="extrabox-grid">
                        {optionFilter.sort(compare).map(option => (
                          <div key={option.optionId}>
                            <input
                              type="checkbox"
                              id={option.optionId}
                              name="optionId"
                              value={option.optionId}
                              onChange={event =>
                                handleCheckBox("optionalOptions", event)
                              }
                            />
                            <label htmlFor={option.optionId}>
                              <div className="extrabox">
                                <h3>{option.description}</h3>
                                <p className="dark-gray">
                                  {option.labelMarkup.replace(
                                    /(<([^>]+)>)/gi,
                                    ""
                                  )}
                                </p>
                              </div>
                            </label>
                          </div>
                        ))}
                      </div>

                      <span onClick={hideExtras}>
                        <h3 className="bold mouse" style={{ margin: "10px 0" }}>
                          {"<<"} Extra Opties Verbergen
                        </h3>
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="reservation-select-right">
                <div className="reservation-contact">
                  <div className="reservation-contact__photo">
                    <img
                      src={rental.vehicleClass.images[0]?.imageUrl}
                      alt={rental.vehicleClass.images[0]?.fileName}
                    ></img>
                  </div>
                  <div className="reservation-contact__text">
                    <h3 className="bold">
                      {rental.vehicleClass.labelMarkup
                        ? rental.vehicleClass.labelMarkup?.replace(
                            /(<([^>]+)>)/gi,
                            ""
                          )
                        : rental.vehicleClass.description}
                    </h3>
                    <p>
                      <span className="bold">{rental.pickupLocation.town}</span>{" "}
                      <br></br>
                      Ophalen: {rental.pickupDate} {rental.pickupTime} <br></br>
                      Retour: {rental.dropOffDate} {rental.dropOffTime}
                    </p>
                    {/* Depending on mileage package as well */}
                    {/* Remove 200km */}
                    {mileageFilter.length === 0 && (
                      <div style={{ display: "flex", gap: "1rem" }}>
                        <p>
                          <span className="small-top">
                            Totaal vrije kilometers:
                          </span>
                          <div>
                            {rental.includedMileage &&
                            rental.includedMileage > 0 &&
                            rental.includedMileage !== 9999
                              ? rental.includedMileage
                              : "0"}
                          </div>
                        </p>
                        <p>
                          <span className="small-top">Prijs extra km:</span>
                          <div>
                            {rental.vehicleClass.marketingOverMileagePrice &&
                              rental.vehicleClass.marketingOverMileagePrice
                                .value}
                          </div>
                        </p>
                      </div>
                    )}

                    {/*<p className="small-bottom">
                                    Je bespaart: € 12
                                </p>*/}
                  </div>
                </div>
                <Contact />
                <FreeParking />
              </div>
            </div>
          </Container>
          <div className="reservation-cta__wrapper">
            <div>
              <h3>
                <span className="extrabox__price">
                  {"€ "}
                  {includeVat === "yes"
                    ? (
                        (getPrice(rental, selectedOptions, discount) * 100) /
                        100
                      ).toFixed(2)
                    : (
                        (getPrice(rental, selectedOptions, discount) * 100) /
                        121
                      ).toFixed(2)}
                </span>
                <span>
                  {includeVat === "yes" ? " incl. BTW" : " excl. BTW"}
                </span>
              </h3>
            </div>
            <div className="reservation-cta">
              <button
                className="button-primary"
                onClick={() => createReservation(id)}
              >
                Volgende Stap
              </button>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className="spinner-background">
          <Spinner></Spinner>
        </div>
      )}
    </div>
  )
}

export default ReservationSelectUpsell
