// Public Holdays (may 5 is public holiday once every 5 years)
const holidays = [
    { datum: "2023-5-18 12:00:00" },
    { datum: "2023-5-28 12:00:00" },
    { datum: "2023-5-29 12:00:00" },
    { datum: "2023-12-25 12:00:00" },
    { datum: "2023-12-26 12:00:00" },
    { datum: "2024-1-1 12:00:00" },
    { datum: "2024-3-31 12:00:00" },
    { datum: "2024-4-1 12:00:00" },
    { datum: "2024-4-27 12:00:00" },
    { datum: "2024-5-9 12:00:00" },
    { datum: "2024-5-19 12:00:00" },
    { datum: "2024-5-20 12:00:00" },
    { datum: "2024-12-25 12:00:00" },
    { datum: "2024-12-26 12:00:00" },
    { datum: "2025-1-1 12:00:00" },
    { datum: "2025-4-20 12:00:00" },
    { datum: "2025-4-21 12:00:00" },
    { datum: "2025-4-26 12:00:00" },    
    { datum: "2025-5-5 12:00:00" },  // bevrijdingsdag ? eens in de 5 jaar
    { datum: "2025-5-29 12:00:00" },
    { datum: "2025-6-8 12:00:00" },
    { datum: "2025-6-9 12:00:00" },
    { datum: "2025-12-25 12:00:00" },
    { datum: "2025-12-26 12:00:00" },
]

// convert array into getTime of dateObjects
const holidaysArr = holidays.map(holiday => {
    const dateObj = new Date(holiday.datum.replace(/-/g, "/"));
    const dateObjTime = Math.ceil((dateObj.getTime() / (1000 * 60 * 60 * 24)));

    return dateObjTime;
})

export const publicDay = (dateNum: number) => holidaysArr.some(obj => obj === dateNum);

// TODO: May 5: Liberty Day - open until xx
const fifthOfMay = [
    { datum: "2023-5-5 12:00:00" },
    { datum: "2024-5-5 12:00:00" },
    { datum: "2026-5-5 12:00:00" },
    { datum: "2027-5-5 12:00:00" },
    { datum: "2028-5-5 12:00:00" },    
    { datum: "2029-5-5 12:00:00" },
]

// convert array into getTime of dateObjects
const fifthOfMayArr = fifthOfMay.map(fifthOfMayDay => {
    const dateObj = new Date(fifthOfMayDay.datum.replace(/-/g, "/")); {/* necessary for safari browser */}
    const dateObjTime = Math.ceil((dateObj.getTime() / (1000 * 60 * 60 * 24)));
    return dateObjTime;
})

export const fifthOfMayDay = (dateNum: number) => fifthOfMayArr.some(obj => obj === dateNum);
