import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const HowItWorks = () => (
    <div className="how-it-works-section">
        <div className="how-it-works-background">
            <div className="how-it-works-container">
                <div className="how-it-works">
                    <div className="how-it-works__item">
                        <div className="how-it-works__iconbox">
                            <div className="how-it-works__icon">
                                <FontAwesomeIcon icon={['fas', 'search']} />
                            </div>
                        </div>
                        <div className="how-it-works__text">
                            <p className="bold nomargin__bottom uppercase">Zoeken</p>
                            <p className="nomargin gray">Kies een voertuig en voeg eventueel uw opties toe</p>
                        </div>

                    </div>
                    <div className="how-it-works__item">
                        <div className="how-it-works__iconbox">
                            <div className="how-it-works__icon">
                            <FontAwesomeIcon icon={['fas', 'calendar-alt']} />
                            </div>
                        </div>
                        <div className="how-it-works__text">
                            <p className="bold nomargin__bottom uppercase">Boeken</p>
                            <p className="nomargin gray">Kies een voertuig en voeg eventueel uw opties toe</p>
                        </div>
                    </div>
                    <div className="how-it-works__item">
                        <div className="how-it-works__iconbox">
                            <div className="how-it-works__icon">
                            <FontAwesomeIcon icon={['fas', 'car']} />
                            </div>
                        </div>
                        <div className="how-it-works__text">
                            <p className="bold nomargin__bottom uppercase">Rijden</p>
                            <p className="nomargin gray">Kies een voertuig en voeg eventueel uw opties toe</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default HowItWorks