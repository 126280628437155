import React from "react";
import ReactMarkdown from "react-markdown";
import RentLocations from "../shared/rent-locations";
import bovag from "../../images/bovag-logo.jpg"
import van from "../../images/9-personenbus-mercedes-vito-wit-bert-jonk.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ServiceContent = (props) => (
    <div className="container">
        <div className="grid-two-thirds">
            <div className="grid-two-thirds__left">
                <h1>{props.title}</h1>
                <ReactMarkdown>{props.description.data.description}</ReactMarkdown>
                <div className="service-usps">
                    <div className="service-usp">
                        <div className="service-usp__iconbox">
                            <FontAwesomeIcon icon="check-circle" />
                        </div>
                        <div className="service-usp__content">
                            <p>{props.uspOne}</p>
                        </div>
                    </div>
                    <div className="service-usp">
                        <div className="service-usp__iconbox">
                            <FontAwesomeIcon icon="check-circle" />
                        </div>
                        <div className="service-usp__content">
                            <p>{props.uspTwo}</p>
                        </div>
                    </div>
                    <div className="service-usp">
                        <div className="service-usp__iconbox">
                            <FontAwesomeIcon icon="check-circle" />
                        </div>
                        <div className="service-usp__content">
                            <p>{props.uspThree}</p>
                        </div>
                    </div>
                </div>
                <div className="get-in-touch">
                    <div className="get-in-touch__content">
                        <h2>{props.interestedTitle}</h2>
                        <ReactMarkdown>{props.interestedDescription.data.interestedDescription}</ReactMarkdown>
                        <div className="get-in-touch__buttons">
                            <a className="button-primary" href="tel:0883745900"><FontAwesomeIcon icon="phone" flip="horizontal" /> 088-3745900</a>
                            <a className="button-primary" href="mailto:info@bertjonk.nl"><FontAwesomeIcon icon="envelope" /> info@bertjonk.nl</a>
                        </div>
                    </div>
                    <div className="get-in-touch__image">
                        <img src={van} alt="Bert Jonk 9 Persoonsbus" className="img-fluid" />
                    </div>
                </div>
                {props.advantagesWhy?.strapi_json_value &&
                    <div className="why-shortlease">
                        <h2>{props.whyTitle}</h2>
                        <ReactMarkdown>{props.whyDescription}</ReactMarkdown>
                        <ul className="shortlease-advantages__ul">
                        {props.advantagesWhy.strapi_json_value.map((item, index) => {
                            return (
                                <li key={index}><FontAwesomeIcon icon="check-circle" className="primary-color" /> {item}</li>
                            )
                        })}
                        </ul>
                    </div>
                }
            </div>
            <div className="grid-two-thirds__right">
                <RentLocations />
                <div className="bovag">
                    <img src={bovag} alt="logo" />
                </div>
                {props.advantagesService?.strapi_json_value &&
                    <div className="shortlease-advantages">
                        <h3 className="shortlease-advantages__h3">{props.serviceTitle}</h3>
                        <ul className="shortlease-advantages__ul">
                            {props.advantagesService.strapi_json_value.map((item, index) => {
                                return (
                                    <li key={index}> <FontAwesomeIcon icon="check-circle" className="primary-color"></FontAwesomeIcon> {item}</li>
                                )
                            })}
                        </ul>
                    </div>
                }
            </div>
        </div>
    </div>
)

export default ServiceContent