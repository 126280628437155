import { useState } from "react"
import { useForm } from "react-hook-form"
import axios from "axios"
import Settings from "../settings.json"

const TrucksForm = ({ truckName, monthPrice }) => {
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [loading, setLoading] = useState(false)
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const onSubmit = data => {
    setLoading(true)
    setErrorMessage(false)
    axios
      .post(Settings.trucksUrl, { data })
      .then(() => {
        setFormSubmitted(true)
        pushDatalayer(data)
      })
      .catch(() => {
        setErrorMessage(true)
        setFormSubmitted(false)
      })
      .finally(setLoading(false))
  }

  const dataLayer = window.dataLayer || []
  const pushDatalayer = data => {
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      currency: "EUR",
      event: `generate_lead`,
      ecommerce: {
        value: `${data.monthPrice}`,
        items: {
          item_name: `${data.voertuig}`,
          price: `${data.monthPrice}`,
          quantity: 1,
          item_id: `${data.voertuig}`,
          affiliation: 'TrucksNl'
        },
      },
    })
  }



  return (
    <div style={{ backgroundColor: "#fff", padding: 24, margin: "24px 0" }}>
      {!formSubmitted && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-row">
            <div style={{ margin: 8 }}>
              <h2>Offerte opvragen voor : {truckName} </h2>
            </div>
            <div className="form-100">
              <div className="textOnInput">
                <label htmlFor="naam">(Bedrijfs)naam*</label>
                <input
                  type="text"
                  className="form-input-alt"
                  {...register("naam", { required: true, maxLength: 180 })}
                />
              </div>
            </div>
            <div className="form-100">
              <div className="textOnInput">
                <label htmlFor="email">E-mail*</label>
                <input
                  type="text"
                  className="form-input-alt"
                  {...register("email", {
                    required: true,
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                />
              </div>
            </div>
            <div className="form-100">
              <div className="textOnInput">
                <label htmlFor="telefoon">Telefoon*</label>
                <input
                  type="tel"
                  className="form-input-alt"
                  {...register("telefoon", {
                    required: true,
                    maxLength: 11,
                    minLength: 9,
                  })}
                />
              </div>
            </div>
            <div className="form-50">
              <div className="textOnInput">
                <label htmlFor="ophaaldatum">Startdatum*</label>
                <input
                  type="text"
                  className="form-input-alt"
                  placeholder="dd-mm-yyyy"
                  {...register("ophaaldatum", {
                    required: true,
                    minLength: 8,
                    maxLength: 12,
                  })}
                />
              </div>
            </div>
            <div className="form-50">
              <div className="textOnInput">
                <label htmlFor="retourdatum">Geschatte looptijd*</label>
                <input
                  type="text"
                  className="form-input-alt"
                  {...register("looptijd", {
                    required: true,
                  })}
                />
              </div>
            </div>
            <div className="form-100">
              <div className="textOnInput">
                <label htmlFor="bericht">Afleverlocatie</label>
                <input
                  type="text"
                  className="form-input-alt"
                  {...register("afleverlocatie")}
                />
              </div>
            </div>
            <div className="form-100">
              <div className="textOnInput">
                <label htmlFor="bericht">Opmerkingen</label>
                <textarea
                  rows="4"
                  className="form-input-alt"
                  {...register("bericht")}
                />
              </div>
            </div>

            <input
              type="hidden"
              value={truckName.toString()}
              {...register("voertuig")}
            />
            <input
              type="hidden"
              value={monthPrice}
              {...register("monthPrice")}
            />

            {!loading && (
              <div style={{ marginLeft: "auto", marginRight: 8 }}>
                <input
                  type="submit"
                  className="button button-primary"
                  value="Offerte opvragen"
                />
              </div>
            )}
          </div>
          {/* <input type="hidden"
                        value={requestedVehicle.description}
                        {...register("voertuig")}
                    /> */}

          {errors.naam && <p>Controleer uw (bedrijfs)naam</p>}
          {errors.email && <p>Controleer uw e-mail</p>}
          {errors.telefoon && <p>Controleer uw telefoonnummer</p>}
          {errors.ophaaldatum && <p>Controleer de startdatum</p>}
          {errors.looptijd && <p>Controleer de looptijd</p>}
          {errors.bericht && <p>Controleer uw opmerkingen</p>}
        </form>
      )}
      {formSubmitted && !errorMessage && (
        <div style={{ padding: 50 }}>
          <h2>Bedankt, wij hebben uw aanvraag in goede orde ontvangen.</h2>
          <p>
            U ontvangt een mail met een bevestiging. Wij nemen zo spoedig
            mogelijk contact met u op.
          </p>
        </div>
      )}
      {errorMessage && (
        <h3 style={{ padding: 50 }}>
          {" "}
          Iets is fout gegaan, probeer aub opnieuw of geef ons een belletje.{" "}
        </h3>
      )}
    </div>
  )
}

export default TrucksForm
