import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RentLocations = () => (
    <div className="rent-locations__wrapper">
        <div className="rent-locations__title"><h3 className="bold">Huurlocaties</h3></div>
        <ul>
            <li key="1">
                <span className="rent-locations__icon">
                    <FontAwesomeIcon icon="map-marker-alt" />
                </span>
                <p>
                    <span className="bold">Bert Jonk Autoverhuur Purmerend </span><br></br> <a className="bold" href="tel:0299423852">0299 423 852</a>
                </p>
            </li>
            <li key="2">
                <span className="rent-locations__icon">
                    <FontAwesomeIcon icon="map-marker-alt" />
                </span>
                <p>
                    <span className="bold">Bert Jonk Autoverhuur Zwaag (Hoorn)</span><br></br> <a className="bold" href="tel:0229244340">0229-244 340</a>
                </p>
            </li>
            <li key="3">
                <span className="rent-locations__icon">
                    <FontAwesomeIcon icon="map-marker-alt" />
                </span>
                <p>
                    <span className="bold">Bert Jonk Autoverhuur Oude Meer (Schiphol)</span><br></br> <a className="bold" href="tel:0206530032">020 653 0032</a>
                </p>
            </li>
        </ul>
    </div>
)

export default RentLocations