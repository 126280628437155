import * as React from "react"
import AdditionalInfo from '../../components/shared/additional-info'
import BreadCrumbs from "../../components/shared/breadcrumbs"
import ContactForm from "../../components/shared/contact-form"
import CustomerExperience from "../../components/customer-experience"
import CustomerService from "../../components/landing-pages/customer-service"
import Faq from "../../components/shared/faq"
import Layout from "../../components/layout"
import LocationMap from "../../components/location-map"
import Seo from "../../components/seo"
import ServiceContent from "../../components/services/service-content"

const Services = (props) => {
    const {
        title,
        description,
        serviceTitle,
        uspOne,
        uspTwo,
        uspThree,
        interestedDescription,
        interestedTitle,
        whyTitle,
        whyDescription,
        advantagesService,
        advantagesWhy,
        faqKeyword,
        basicSeo,
        reviews,
        serviceFaqs,
        serviceBlocks,
        contact
    } = props.pageContext;
    return (
        <Layout>
            <Seo index={true} follow={true} title={basicSeo.seoTitle} description={basicSeo.seoMetadescription}></Seo>
            <BreadCrumbs title={title}></BreadCrumbs>
            <ServiceContent
                title={title}
                description={description}
                uspOne={uspOne}
                uspTwo={uspTwo}
                uspThree={uspThree}
                interestedTitle={interestedTitle}
                interestedDescription={interestedDescription}
                whyDescription={whyDescription}
                whyTitle={whyTitle}
                advantagesWhy={advantagesWhy}
                advantagesService={advantagesService}
                serviceTitle={serviceTitle}
            >
            </ServiceContent>
            <ContactForm />
            <CustomerExperience reviews={reviews} />
            <LocationMap />
            <AdditionalInfo blocks={serviceBlocks}></AdditionalInfo>
            <Faq faqs={serviceFaqs} faqKeyword={faqKeyword}></Faq>
            <CustomerService contact={contact} />
        </Layout>
    )
}

export default Services