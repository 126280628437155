import * as React from "react"
import logo from "../../images/logo.jpg"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Footer = () => (
    <div>
        <div className="footer-background">
            <div className="container">
                <div className="footer-grid">
                    <div>
                        <div className="title">
                            <h2>Producten Bert Jonk Autoverhuur</h2>
                        </div>
                        <div className="links">
                            <ul>
                            <li><Link to="/veel-gestelde-vragen">Veelgestelde vragen</Link></li>
                            <li><Link to="/over-ons">Over Ons</Link></li>
                            <li><Link to="/auto-huren">Auto huren</Link></li>
                            <li><Link to="/koelwagen-huren">Koelwagen huren</Link></li>
                            <li><Link to="/busje-huren">Busje huren</Link></li>
                            <li><Link to="/bestelbus-huren">Bestelbus huren</Link></li>
                            <li><Link to="/vrachtwagen-huren">Vrachtwagen huren</Link></li>
                            <li><Link to="/aanbiedingen/wintersport">Wintersport Aanbiedingen</Link></li>
                            <li><Link to="/sitemap/sitemap-0.xml">Sitemap</Link></li>
                            </ul>
                        </div>
                        <Link to="/"><img src={logo} alt="logo Bert Jonk" className="footer-logo"></img></Link>
                    </div>
                    <div>
                        <div className="title">
                            <h2>Overige Bert Jonk Autoverhuur</h2>
                        </div>
                        <div className="links">
                            <ul>
                                <li><Link to="/contact">Contact</Link></li>
                                <li><Link to="/algemene-huurvoorwaarden">Algemene Huurvoorwaarden</Link></li>
                                <li><Link to="/personenbus-huren">Personenbus huren</Link></li>
                                <li><Link to="/rolstoelbus-huren">Rolstoelbus huren</Link></li>
                                <li><Link to="/vakantiebus-huren">Vakantiebus huren</Link></li>
                                <li><Link to="/shortlease">Shortlease</Link></li>
                                <li><Link to="/reclamevrije-auto-huren">Reclamevrij huren</Link></li>
                                <li><Link to="/kilometervrij-huren">Kilometervrij huren</Link></li>
                                <li><Link to="/autoambulance-huren">Autoambulance huren</Link></li>
                                <li><Link to="/verhuiswagen-huren">Verhuiswagen huren</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-grid__locations">
                        <div className="title">
                            <h2>Locaties Bert Jonk Autoverhuur</h2>
                        </div>
                        <div className="locations-left">
                            <div>
                                <h3><Link to='/verhuurvestigingen/oude-meer-schiphol'>Oude Meer (Schiphol)</Link></h3>
                                Schipholdijk 245 <br></br>
                                1438AC, Oude Meer
                            </div>
                            <div>
                                <h3><Link to='/verhuurvestigingen/purmerend'>Purmerend</Link></h3>
                                Einsteinstraat 11 <br></br>
                                1446 VE, Purmerend
                            </div>
                        </div>
                        <div className="locations-right">
                        <div>
                        <h3><Link to='/verhuurvestigingen/zwaag-hoorn'>Zwaag (Hoorn)</Link></h3>
                        de Compagnie 49 <br></br>
                        1689G, Zwaag
                    </div>
                            <div className="icons">
                                <div><a href='https://www.facebook.com/bertjonkbv' target='_blank' rel="noreferrer"><FontAwesomeIcon icon={["fab", "facebook"]} className="footer-icon" aria-label="facebook"/></a></div>
                                <div><a href='https://twitter.com/bertjonk' target='_blank' rel="noreferrer"><FontAwesomeIcon icon={["fab", "twitter"]} className="footer-icon" aria-label="twitter"/></a></div>
                                <div><a href='https://www.linkedin.com/company/autoverhuur-bert-jonk/about/' target='_blank' rel="noreferrer"><FontAwesomeIcon icon={["fab", "linkedin"]} className="footer-icon" aria-label="linkedin"/></a></div>
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-background-bottom">
            Powered by <a href="https://www.rentvisie.nl"> RentVisie - Autoverhuursoftware Oplossingen</a>
        </div>
    </div>
)

export default Footer