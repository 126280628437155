import React, { useEffect } from "react"
import DatetimeRangePicker from "../../datetimerangepicker/index"
import "moment/locale/nl"
import Settings from "../../../components/settings.json"
import { publicDay, fifthOfMayDay } from "../../shared/publicDay"

// Holidays

const DateTimeRangePicker = ({
  today,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setShowDateInPastWarning,
  setShowThirtyDaysWarning,
  setShowCoolingTruckWarning,
  setShowSundayWarning,
  setShowNextSundayWarning,
  setHolidayWarning,
  setShowFifthOfMayWarning,
  setShowTempWarning,
  location,
  coolingTruck,
}) => {
  const getDifferenceInDays = (date1, date2) => {
    const diffInMs = Math.abs(date2 - date1)
    return Math.ceil(diffInMs / (1000 * 60 * 60 * 24))
  }

  let yesterday = new Date()
  yesterday.setDate(new Date().getDate() - 1)
  let tomorrow = new Date()
  tomorrow.setDate(new Date().getDate() + 1)

  const valid = current => {
    return current.isAfter(yesterday)
  }

  const onChange = input => {
    let newStartDate = new Date(input.start)
    let newEndDate = new Date(input.end)
    startDate = newStartDate // werkt beter dan setStartDate(newStartDate)
    endDate = newEndDate

    // gelijke datums kan je terugkrijgen uit de datetimerangepicker als je
    // een pickup date in toekomst kiest, die verder is dan de return datum.
    // Dan maakt de picker de return datum gelijk aan de pickup datum
    // Via onderstaand truc, zet je dan de pickup datum 1 dag terug en ..
    // maak je de return datum een aantal uur later.
    // Om te voorkomen dat deze logica door dit module getriggerd wordt, wordt
    // bij een aanpassing van de return tijd altijd 1 seconde anders gemaakt dan de
    // pickup tijd
    if (
      input.start.getDate() === input.end.getDate() &&
      input.start.getMonth() === input.end.getMonth() &&
      input.start.getYear() === input.end.getYear() &&
      input.start.getHours() === input.end.getHours() &&
      input.start.getMinutes() === input.end.getMinutes()
    ) {
      newStartDate.setDate(newStartDate.getDate() - 1)
      newStartDate.setHours(Settings.dayStartHour)
      newStartDate.setMinutes(Settings.dayStartMinute)
      setStartDate(newStartDate)
      newEndDate.setDate(newStartDate.getDate())
      newEndDate.setMonth(newStartDate.getMonth())

      //correcties vanwege raar gedrag setDate / setMonth functies
      if (newStartDate.getDate() === 31) {
        // day 31 leads in setdate function to 1st day,
        newEndDate.setDate(31) // so this is to trick the day to become 31
        if (newStartDate.getMonth() === 11) {
          //similarly in dec (month 11) the year is
          newEndDate.setYear(1900 + newStartDate.getYear()) //set wrongly to eg 122
        }
      }

      newEndDate.setDate(newStartDate.getDate())
      newEndDate.setHours(Settings.dayEndHour)
      newEndDate.setMinutes(Settings.dayEndMinute)
      setEndDate(newEndDate)
    } else {
      setStartDate(input.start)
      setEndDate(input.end)
    }
    //pickup datum in past (kan alleen een eerdere tijd op vandaag zijn) check en eventueel aanpassen
    if (
      startDate.getDate() === today.getDate() &&
      startDate.getMonth() === today.getMonth() &&
      startDate.getYear() === today.getYear() &&
      (startDate.getHours() < today.getHours() ||
        (startDate.getHours() === today.getHours() &&
          startDate.getMinutes() <= today.getMinutes()))
      // || (
      //     (
      //         (startDate.getDate() === 1) &&
      //         (startDate.getMonth() < today.getMonth()) &&
      //         (startDate.getYear() <= today.getYear())
      //     ) || (
      //         (startDate.getDate() === 1) &&
      //         (startDate.getMonth() === 11) &&
      //         (startDate.getMonth() === 0)
      //     )
      // )
    ) {
      if (today.getHours() >= 17 && today.getMinutes() > 0) {
        // user change after 5 PM
        newStartDate = tomorrow
        newStartDate.setHours(10)
      } // set leadtime for huur at 1-2 hours
      else {
        newStartDate.setHours(today.getHours() + 1)
      }
      newStartDate.setMinutes(Settings.dayStartMinute)

      setStartDate(newStartDate)
      setShowDateInPastWarning(true)
    } else {
      setShowDateInPastWarning(false)
    }
    //eventueel return datum aanpassen, als die voor startdatum is gekomen
    if (endDate.getYear() === newStartDate.getYear()) {
      if (endDate.getMonth() === newStartDate.getMonth()) {
        if (endDate.getDate() === newStartDate.getDate()) {
          if (
            endDate.getHours() <=
            newStartDate.getHours() + Settings.minTimeRange
          ) {
            if (newStartDate.getHours() + Settings.minTimeRange < 24) {
              newEndDate.setHours(
                newStartDate.getHours() + Settings.minTimeRange
              )
              newEndDate.setMinutes(newStartDate.getMinutes())
              newEndDate.setSeconds((newStartDate.getSeconds() + 1) % 60)
              //anything below should not happen as the date picker takes
              // care of this
              if (endDate.getHours() > 23) {
                // can not happen
                newEndDate.setDate(newStartDate.getDate())
                newEndDate.setHours(23)
                newEndDate.setSeconds((newStartDate.getSeconds() + 1) % 60)
              }
              setEndDate(newEndDate)
            } else {
              newEndDate.setDate(newStartDate.getDate() + 1)
              newEndDate.setHours(12)
              newEndDate.setSeconds((newStartDate.getSeconds() + 1) % 60)
              setEndDate(newEndDate)
            }
          }
        } else if (newEndDate.getDate() < newStartDate.getDate()) {
          newEndDate.setDate(newStartDate.getDate())
          newEndDate.setHours(Settings.dayEndHour)
          newEndDate.setSeconds((newStartDate.getSeconds() + 1) % 60)
          setEndDate(newEndDate)
        }
      } else if (
        newEndDate.getMonth() < newStartDate.getMonth() ||
        (newEndDate.getMonth() < 1 && newStartDate.getMonth() > 1)
      ) {
        newEndDate.setDate(1)
        newEndDate.setMonth(newStartDate.getMonth())
        newEndDate.setHours(Settings.dayEndHour)
        newEndDate.setSeconds((newStartDate.getSeconds() + 1) % 60)
        setEndDate(newEndDate)
      }
    } else if (newEndDate.getYear() < newStartDate.getYear()) {
      newEndDate.setDate(1)
      newEndDate.setMonth(newStartDate.getMonth())
      newEndDate.setMonth(newStartDate.getYear())
      newEndDate.setHours(Settings.dayEndHour)
      newEndDate.setSeconds((newStartDate.getSeconds() + 1) % 60)
      setEndDate(newEndDate)
    }

    if (today.getDay() === 1 && input.start.getHours() >= 17) {
      newStartDate.setHours(17)
      newStartDate.setMinutes(0)
      setStartDate(newStartDate)
    }

    if (today.getDay() !== 1 && input.start.getHours() === 17 && input.start.getMinutes() > 15 || input.start.getHours() > 17) {
      console.log(today.getDay())
      newStartDate.setHours(17)
      newStartDate.setMinutes(30)
      setStartDate(newStartDate)
    }
  }

  if (getDifferenceInDays(endDate, startDate) >= 30) {
    // endDate = new Date(startDate);
    // endDate.setDate(endDate.getDate()+30)
    setShowThirtyDaysWarning(true)
  } else {
    setShowThirtyDaysWarning(false)
  }

  if (coolingTruck && getDifferenceInDays(endDate, startDate) >= 7) {
    setShowCoolingTruckWarning(true)
  } else {
    setShowCoolingTruckWarning(false)
  }

  useEffect(() => {
    if (publicDay(Math.ceil(startDate.getTime() / (1000 * 60 * 60 * 24)))) {
      setHolidayWarning(true)
      setShowSundayWarning(false)
      setShowNextSundayWarning(false)
      setShowFifthOfMayWarning(false)
    } else {
      setHolidayWarning(false)
      if (
        startDate.getDay() === 0 &&
        new Date().toDateString() === startDate.toDateString()
      ) {
        setShowSundayWarning(true)
        setShowNextSundayWarning(false)
        setShowFifthOfMayWarning(false)
      } else {
        if (startDate.getDay() === 0) {
          setShowSundayWarning(false)
          setShowNextSundayWarning(true)
          setShowTempWarning(false)
          setShowFifthOfMayWarning(false)
        } else {
          setShowSundayWarning(false)
          setShowNextSundayWarning(false)
          if (
            fifthOfMayDay(
              Math.ceil(startDate.getTime() / (1000 * 60 * 60 * 24))
            )
          ) {
            setShowFifthOfMayWarning(true)
          } else if (location?.value === 5219 && startDate.getDay() === 6) {
            const currentDate = new Date(startDate)
            const currentHours = currentDate.getHours()
            const currentMinutes = currentDate.getMinutes()

            const closed =
              (currentHours === 11 && currentMinutes > 30) || currentHours > 11

            if (closed) {
              setShowTempWarning(true)
            } else {
              setShowTempWarning(false)
            }
          } else {
            setShowFifthOfMayWarning(false)
            setShowTempWarning(false)
          }
        }
      }
    }
  }, [startDate, location])


  return (
    <div>
      <DatetimeRangePicker
        locale="nl-NL"
        timeFormat="HH:mm"
        dateFormat="DD-MM-YYYY"
        onChange={onChange}
        startDate={startDate || new Date()}
        endDate={endDate}
        input={false}
        readOnly={true}
        isValidStartDate={valid}
        startTimeConstraints={{
          hours: { min: 8, max: 17, step: 1 },
          minutes: { min: 0, max: 60, step: 15 },
        }}
        endTimeConstraints={{
          hours: { min: 9, max: 23 },
          minutes: { min: 0, max: 60, step: 15 },
        }}
        pickerClassName="datetimerange-input"
        className="datetimerange-wrapper"
        closeOnSelect={true}
      />
    </div>
  )
}

export default DateTimeRangePicker
