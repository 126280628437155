import React, { useState, useEffect } from "react"
import { Rental, Rentvisie } from "@bakkie/ratality"
import Contact from "./shared/contact"
import ReservationHeader from "./shared/reservation-header"
import { navigate } from "gatsby"
import { ValidatorUtil } from "./validator.util"
import { Container } from "react-bootstrap"
import SEO from "../seo"
import FreeParking from "./shared/freeParking"
import Spinner from "../shared/spinner"
import { SelectableOptions } from "../../interfaces/ReservationInterfaces"
import SelectInsuranceOptions from "./extras/SelectInsuranceOptions"
import SelectMileagePackageOptions from "./extras/SelectMileageOptions"
import SelectAdditionalInsuranceOptions from "./extras/SelectAdditionalInsuranceOptions"
import SelectSpecificExtras from "./extras/SelectSpecificExtras"
import SelectOtherOptions from "./extras/SelectOtherOptions"

declare global {
  interface Window {
    dataLayer: any
  }
}

interface PutOptions {
  ids?: number[]
  mileagePackageOptionId?: number
  insuranceOptionId?: number
}

Rentvisie.setConfig({
  baseUrl: "https://api.rentvisie.com/api",
  client: "bertjonk",
  useGuestToken: true,
})

function getOptionPrice(
  rental: Rental,
  optionType: string,
  optionId: number
): number {
  const options = rental.vehicleClass[optionType]
  const optionPerDay = options?.find(
    option =>
      option.optionId == optionId && option.price.unitDescription == "per Day"
  )
  const optionPerTrip = options?.find(
    option =>
      option.optionId == optionId && option.price.unitDescription !== "per Day"
  )
  if (optionPerDay) {
    return optionPerDay.price.value * rental.days
  } else if (optionPerTrip) {
    return optionPerTrip.price.value
  } else {
    return 0
  }
}

function getPrice(
  rental: Rental,
  selectedOptions: SelectableOptions,
  includeVat: string,
  hasDiscount: boolean
): number {
  const basePriceBeforeDiscount = rental.totalPrice.value

  let basePrice = basePriceBeforeDiscount
  if (hasDiscount) {
    basePrice = basePriceBeforeDiscount - basePriceBeforeDiscount * 0.3
  }

  const optionsPrice = Object.keys(selectedOptions)
    .map(key =>
      selectedOptions[key]
        .map(optionId => getOptionPrice(rental, key, optionId))
        .reduce((acc, value) => acc + value, 0)
    )
    .reduce((acc, value) => acc + value, 0)
  const totalPrice = basePrice + optionsPrice

  let totalPriceAfterVat = totalPrice

  if (includeVat !== "yes") {
    totalPriceAfterVat = (totalPriceAfterVat / 1.21).toFixed(2)
  } else {
    totalPriceAfterVat = totalPrice.toFixed(2)
  }

  return totalPriceAfterVat
}

const ReservationSelectUpsell = ({ rental, id, hasDiscount }) => {
  const [selectedOptions, setSelectedOptions] = useState<SelectableOptions>({})

  const [showSpinner, setShowSpinner] = useState(false)
  const category = rental.vehicleClass.category.categoryId
  const [mileageFilter, setMileageFilter] = useState([])

  let includeVat = "no"
  if (typeof window !== "undefined") {
    includeVat = localStorage.getItem("includeVat")
  }

  const valueChanges = (key: string, ids: number[]) => {
    const newSelectedOptions = { ...selectedOptions, [key]: ids }
    setSelectedOptions(newSelectedOptions)
  }

  const handleCheckBox = (key: string, event: any) => {
    const checked = event.target.checked
    const value = event.target.value
    const currentValues = selectedOptions[key] ?? []
    if (checked) {
      valueChanges(key, [...currentValues, value])
    } else {
      valueChanges(
        key,
        currentValues.filter(id => id !== value)
      )
    }
  }

  const createReservation = (rentalId: number) => {
    createReservationAndSetOptions(rentalId)
    setShowSpinner(true)
  }

  // TODO: Add to state and move to checkout
  // rentalId
  // selectedOptions
  // hasDiscount
  const createReservationAndSetOptions = async (rentalId: number) => {
    const reservation = await Rentvisie.postReservation(rentalId)
    const reservationReference = reservation.reservationReference
    const bookingReference = reservation.rentals[0].bookingReference

    const hasInsuranceOptions = ValidatorUtil.validateInsuranceOptions(
      selectedOptions.insuranceOptions
    )
    const hasMileageOptions = ValidatorUtil.validateMileageOptions(
      selectedOptions.mileagePackageOptions
    )
    const hasOptionalOptions = ValidatorUtil.validateOptionalOptions(
      selectedOptions.optionalOptions
    )

    const data: PutOptions = {}

    if (hasInsuranceOptions) {
      data.insuranceOptionId = Number(selectedOptions.insuranceOptions[0])
    }
    if (hasMileageOptions) {
      data.mileagePackageOptionId = Number(
        selectedOptions.mileagePackageOptions[0]
      )
    }
    if (hasOptionalOptions) {
      data.ids = selectedOptions.optionalOptions.map(id => Number(id))
    }

    // TODO: Add to state and move to checkout
    await Rentvisie.addAllOptions(reservationReference, bookingReference, data)

    // TODO: Add to state and move to checkout
    if (hasDiscount) {
      const code = "bestelbus30"
      await Rentvisie.postPromotionCode(reservationReference, code)
    }

    const url = `/huren/checkout?reservationReference=${reservation.reservationReference}`
    setTimeout(
      () =>
        navigate(url, { state: { hasDiscount: hasDiscount }, replace: true }),
      1000
    )
  }

  const dataLayer = window.dataLayer || []
  const [pushedDataLayer, setPushedDataLayer] = useState(false)
  if (!pushedDataLayer) {
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: `begin_checkout`,
      ecommerce: {
        items: [
          {
            item_name: `${rental.vehicleClass.description}`,
            item_id: `${rental.vehicleClass.vehicleClassId}`,
            price: `${rental.price.value}`,
            price_excl_vat: `${
              Math.round((rental.price.value / 1.21) * 100) / 100
            }`,
            currency: "EUR",
            item_category: `${rental.vehicleClass.category.categoryId}`,
            quantity: 1,
            pick_up_location_id: `${rental.pickupLocation.locationId}`,
            pick_up_location: `${rental.pickupLocation.town}`,
          },
        ],
      },
    })
    setPushedDataLayer(true)
  }

  // Separate Mileagepackageoptions => Backend updates required for robust solutions //
  const totalDays = rental.days

  // const vehicleFilter = rental.vehicleClass.optionalOptions ? rental.vehicleClass.optionalOptions.filter(option => option.category === 'Voertuig') : [];

  return (
    <div>
      <SEO
        title="Bert Jonk Autoverhuur - Personaliseer"
        index={false}
        follow={true}
      />
      <ReservationHeader activeStepIndex={2} />
      {!showSpinner ? (
        <React.Fragment>
          <Container fluid="xxl">
            <div className="reservation__title">
              <h2>Personaliseer jouw voertuig</h2>
            </div>
            {!rental.vehicleClass.insuranceOptions &&
            !rental.vehicleClass.mileagePackageOptions &&
            !rental.vehicleClass.optionalOptions ? (
              <p>
                Voor dit voertuig kun je geen extra's selecteren. Ga{" "}
                <span onClick={() => createReservation(id)}>
                  direct naar de digitale kassa.
                </span>
              </p>
            ) : (
              <p>
                Voeg Extra's toe aan jouw voertuig of ga{" "}
                <span
                  onClick={() => createReservation(id)}
                  className="btn btn-outline-secondary"
                >
                  direct naar de digitale kassa.
                </span>
              </p>
            )}

            <div
              className="reservation-select-wrapper my-l"
              style={{ paddingBottom: "8rem" }}
            >
              <div className="reservation-select-left">
                {rental.vehicleClass.insuranceOptions && (
                  <SelectInsuranceOptions
                    insuranceOptions={rental.vehicleClass.insuranceOptions}
                    valueChanges={valueChanges}
                    totalDays={totalDays}
                    includeVat={includeVat}
                  />
                )}

                {rental.vehicleClass.mileagePackageOptions?.length > 0 && (
                  <SelectMileagePackageOptions
                    mileagePackageOptions={
                      rental.vehicleClass.mileagePackageOptions
                    }
                    valueChanges={valueChanges}
                    totalDays={totalDays}
                    includeVat={includeVat}
                    marketingOverMileagePrice={
                      rental.vehicleClass.marketingOverMileagePrice
                    }
                    setMileageFilter={setMileageFilter}
                    mileageFilter={mileageFilter}
                    defaultIncluded={rental.includedMileage}
                  />
                )}

                {rental.vehicleClass.optionalOptions?.length > 0 && (
                  <SelectAdditionalInsuranceOptions
                    optionalOptions={rental.vehicleClass.optionalOptions}
                    handleCheckBox={handleCheckBox}
                    totalDays={totalDays}
                    includeVat={includeVat}
                  />
                )}

                <SelectSpecificExtras />

                <SelectOtherOptions
                  optionalOptions={rental.vehicleClass.optionalOptions}
                  handleCheckBox={handleCheckBox}
                  totalDays={totalDays}
                  includeVat={includeVat}
                />
              </div>
              <div className="reservation-select-right">
                <div className="reservation-contact">
                  <div className="reservation-contact__photo">
                    <img
                      src={rental.vehicleClass.images[0]?.imageUrl}
                      alt={rental.vehicleClass.images[0]?.fileName}
                    ></img>
                  </div>
                  <div className="reservation-contact__text">
                    <h3 className="bold">
                      {rental.vehicleClass.labelMarkup
                        ? rental.vehicleClass.labelMarkup?.replace(
                            /(<([^>]+)>)/gi,
                            ""
                          )
                        : rental.vehicleClass.description}
                    </h3>
                    <p>
                      <span className="bold">{rental.pickupLocation.town}</span>{" "}
                      <br></br>
                      Ophalen: {rental.pickupDate} {rental.pickupTime} <br></br>
                      Retour voor: {rental.dropOffDate} {rental.dropOffTime}
                    </p>
                    {/* Depending on mileage package as well */}
                    {/* Remove 200km */}
                    {mileageFilter.length === 0 && (
                      <div style={{ display: "flex", gap: "1rem" }}>
                        <p>
                          <span className="small-top">
                            Totaal vrije kilometers:
                          </span>
                          <div>
                            {rental.includedMileage &&
                            rental.includedMileage > 7 &&
                            rental.includedMileage !== 9999
                              ? rental.includedMileage
                              : "0"}
                          </div>
                        </p>
                        <p>
                          <span className="small-top">Prijs extra km:</span>
                          <div>
                            {rental.vehicleClass.marketingOverMileagePrice &&
                              rental.vehicleClass.marketingOverMileagePrice
                                .value}
                          </div>
                        </p>
                      </div>
                    )}

                    {/*<p className="small-bottom">
                                    Je bespaart: € 12
                                </p>*/}
                  </div>
                </div>
                <Contact />
                <FreeParking />
              </div>
            </div>
          </Container>
          <div className="reservation-cta__wrapper">
            <div>
              <h3>
                {hasDiscount && (
                  <span
                    style={{ textDecoration: "line-through", marginRight: 6 }}
                  >
                    {"€ "}
                    {getPrice(rental, selectedOptions, includeVat, false)}
                  </span>
                )}
                <span className="extrabox__price">
                  {"€ "}
                  {getPrice(
                    rental,
                    selectedOptions,
                    includeVat,
                    hasDiscount ? hasDiscount : false
                  )}
                </span>
                <span>
                  {includeVat === "yes" ? " incl. BTW" : " excl. BTW"}
                </span>
              </h3>
            </div>
            <div className="reservation-cta">
              <button
                className="button-primary"
                onClick={() => createReservation(id)}
              >
                Volgende Stap
              </button>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className="spinner-background">
          <Spinner></Spinner>
        </div>
      )}
    </div>
  )
}

export default ReservationSelectUpsell
