import React, { useEffect, useState } from "react"
import Categories from "./categories"
import DateTimeRangePicker from "./datetimerangepicker"
import LocationSelect from "./location-select"
import Settings from "../../../components/settings.json"
import { publicDay } from "../../shared/publicDay"


const CtaForm = props => {
  const [showDateInPastWarning, setShowDateInPastWarning] = useState(false)
  const [showThirtyDaysWarning, setShowThirtyDaysWarning] = useState(false)
  const [showCoolingTruckWarning, setShowCoolingTruckWarning] = useState(false)
  const [showSundayWarning, setShowSundayWarning] = useState(false)
  const [showNextSundayWarning, setShowNextSundayWarning] = useState(false)
  const [holidayWarning, setHolidayWarning] = useState(false)
  const [showFifthOfMayWarning, setShowFifthOfMayWarning] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState(undefined)
  const [showTempWarning, setShowTempWarning] = useState(false)

  const today = new Date()
  let tomorrow = new Date()
  tomorrow.setDate(today.getDate() + 1)
  tomorrow.setHours(Settings.dayStartHour)
  tomorrow.setMinutes(Settings.dayStartMinute)
  let endOfTomorrow = new Date()
  endOfTomorrow.setDate(today.getDate() + 1)
  endOfTomorrow.setHours(Settings.dayEndHour)
  endOfTomorrow.setMinutes(Settings.dayEndMinute)
  let endOfToday = new Date()
  endOfToday.setDate(today.getDate())
  endOfToday.setHours(Settings.dayEndHour)
  endOfToday.setMinutes(Settings.dayEndMinute)

  let startRent = {}
  let endRent = {}

  //copy, if available, previous entered field and convert it to correct date format
  if (props.pickupDate && props.pickupTime) {
    const ddmmyyyy = props.pickupDate.split("-")
    const hhmm = props.pickupTime.split(":")
    startRent = new Date(ddmmyyyy[1] + "/" + ddmmyyyy[0] + "/" + ddmmyyyy[2])
    startRent.setHours(hhmm[0])
    startRent.setMinutes(hhmm[1])
    //startRent = new Date(ddmmyyyy[1] + "/" + ddmmyyyy[0] + "/" + ddmmyyyy[2] + "T" + hhmm[0] + "/" + hhmm[1] + "/00");
    //startRent = new Date(`${mm}-${dd}-${yyyy}`);
    // same action for return date
    if (props.returnDate && props.returnTime) {
      const ddmmyyyy = props.returnDate.split("-")
      const hhmm = props.returnTime.split(":")
      endRent = new Date(ddmmyyyy[1] + "/" + ddmmyyyy[0] + "/" + ddmmyyyy[2])
      endRent.setHours(hhmm[0])
      endRent.setMinutes(hhmm[1])
    } else {
      endRent = endOfTomorrow
    }
  } else if (new Date().getHours() >= 17) {
    startRent = new Date()
    startRent.setDate(today.getDate() + 1)
    startRent.setHours(Settings.dayStartHour)
    startRent.setMinutes(Settings.dayStartMinute)
    endRent = endOfTomorrow
  } else {
    startRent = new Date()
    startRent.setDate(today.getDate())
    startRent.setHours(today.getHours() + 1)
    startRent.setMinutes(0)
    endRent = endOfToday
  }

  useEffect(() => {
    if (props.location) {
      setSelectedLocation(props.location)
    }
  }, [props.location])

  const [startDate, setStartDate] = useState(startRent)
  const [endDate, setEndDate] = useState(endRent)

  const parsedStartDate = startDate.toLocaleString("nl-NL")
  const parsedEndDate = endDate.toLocaleString("nl-NL")

  const vehicleClassId = props.vehicleClassId || ""
  const [coolingTruck, setCoolingTruck] = useState(false) // Temp included for loading of Coolings

  // duplicate code @ lp-form.tsx

  //look at datetimerangepicker.js (useeffect on startdate change
  // to see the tests on public holidays, 5th of May and sundays

  return (
    <form action={`/huren/voertuig-kiezen`} method="get">
      {showTempWarning && (
        <div className="hero-form__warning">
          <div> (!) </div>
          <div>
            <p>
              Filiaal maandag weer open. U kunt nu bij een van onze andere
              locaties terecht.
            </p>
          </div>
        </div>
      )}

      {showDateInPastWarning && (
        <div className="hero-form__warning">
          <div> (!) </div>
          <div>
            <p>
              Er is een tijd in het verleden geselecteerd: De tijd is aangepast
              naar een volgende gelegenheid.
            </p>
          </div>
        </div>
      )}
      {showThirtyDaysWarning && (
        <div className="hero-form__warning">
          <div> (!) </div>
          <div>
            <p>
              Voor huurperiodes vanaf 30 dagen maken wij graag een offerte op
              maat. Neem contact met ons op via{" "}
              <a href="tel:0883745900">088-3745900</a>
            </p>
            <p>
              Kortere huurperiodes kunnen gespecificeerd worden door de datum(s)
              aan te passen.
            </p>
          </div>
        </div>
      )}
      {showCoolingTruckWarning && (
        <div className="hero-form__warning">
          <div> (!) </div>
          <div>
            <p>
              Voor huurperiodes vanaf 7 dagen verstrekken wij graag een offerte
              op maat. Neem contact met ons op via{" "}
              <a href="tel:0883745900">088-3745900</a>
            </p>
          </div>
        </div>
      )}
      {holidayWarning && (
        <div className="hero-form__warning">
          <div> (!) </div>
          <div>
            <p>
              Op publieke feestdagen zijn wij gesloten. Reserveren in
              telefonisch overleg via <a href="tel:0883745900">088-3745900</a>
            </p>
          </div>
        </div>
      )}
      {showSundayWarning && (
        <div className="hero-form__warning">
          <div> (!) </div>
          <div>
            <p>Vandaag zijn wij gesloten, selecteer een andere dag.</p>
          </div>
        </div>
      )}
      {showNextSundayWarning && (
        <div className="hero-form__warning">
          <div> (!) </div>
          <div>
            <p>
              Op zondag zijn wij gesloten. Vaak is het mogelijk om het voertuig
              de dag ervoor op te halen zonder meerprijs. Neem contact met ons
              op via <a href="tel:0883745900">088-3745900</a>
            </p>
          </div>
        </div>
      )}

      {showFifthOfMayWarning && (
        <div className="hero-form__warning">
          <div> (!) </div>
          <div>
            <p>
              Op 5 mei zijn we om 12.30 uur gesloten. Als U op 5 mei vanaf 12.30
              uur wilt huren, neem dan contact met ons op via{" "}
              <a href="tel:0883745900">088-3745900</a>
            </p>
          </div>
        </div>
      )}

      <input type="hidden" name="ophalen" value={parsedStartDate}></input>
      <input type="hidden" name="retour" value={parsedEndDate}></input>
      <input type="hidden" name="vehicleClassId" value={vehicleClassId}></input>

      <div className="hero-form">
        <Categories
          category={props.category}
          setCoolingTruck={setCoolingTruck}
        ></Categories>

        <div className="hero-form__search-wrapper">
          <div className="hero-form__search-input">
            <div className="hero-form__search-input-text">Locatie</div>
              <LocationSelect
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
              ></LocationSelect>
          </div>
          <div className="hero-form__search-input" name="picker">
            <div className="hero-form__search-wrapper-header-datetimepicker">
              <div className="hero-form__datetimerange-text">Ophalen</div>
              <div className="hero-form__datetimerange-text">Retour</div>
            </div>
            <DateTimeRangePicker
              today={today}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setShowDateInPastWarning={setShowDateInPastWarning}
              setShowThirtyDaysWarning={setShowThirtyDaysWarning}
              setShowCoolingTruckWarning={setShowCoolingTruckWarning}
              setShowSundayWarning={setShowSundayWarning}
              setShowNextSundayWarning={setShowNextSundayWarning}
              setHolidayWarning={setHolidayWarning}
              setShowFifthOfMayWarning={setShowFifthOfMayWarning}
              setShowTempWarning={setShowTempWarning}
              location={selectedLocation}
              coolingTruck={coolingTruck}
            ></DateTimeRangePicker>
          </div>
          <div className="hero-form__search-input" name="picker">
            <div className="hero-form__search-input-text transparant">
              Bevestig
            </div>
            <input
              type="submit"
              value="Voertuig zoeken"
              className="hero-form__button button-secondary"
              disabled={
                showThirtyDaysWarning ||
                showCoolingTruckWarning ||
                showSundayWarning ||
                showNextSundayWarning ||
                holidayWarning ||
                showTempWarning ||
                (showFifthOfMayWarning &&
                  (startDate.getHours() > 12 ||
                    (startDate.getHours() === 12 &&
                      startDate.getMinutes() > 30)))
              }
            />
          </div>
        </div>
      </div>
    </form>
  )
}

export default CtaForm
