import * as React from "react"
import AdditionalInfo from '../../components/shared/additional-info'
import BreadCrumbs from "../../components/shared/breadcrumbs"
import CtaForm from "../../components/shared/cta-form/form"
import Links from "../../components/shared/links"
import CustomerExperience from "../../components/customer-experience"
import CustomerService from "../../components/landing-pages/customer-service"
import Faq from "../../components/shared/faq"
import HowItWorks from "../../components/landing-pages/how-it-works"
import Layout from "../../components/layout"
import LocationMap from "../../components/location-map"
import { OpeningHoursLanding } from "../../components/shared/opening-hours"
import Seo from "../../components/seo"
import VehicleSelect, { VehicleIntroduction } from "../../components/landing-pages/vehicle-select"

const Landing = (props) => {
  // TODO : READD POPULAR PRODUCTS
  // popularProducts,
  const {
    category,
    title,
    description,
    information,
    faqKeyword,
    reviews,
    productLinks,
    faqs,
    productFaqs,
    blocks,
    productBlocks,
    contact,
    basicSeo
  } = props.pageContext;

  return (
    <Layout>
      <Seo index={true} follow={true} title={basicSeo.seoTitle} description={basicSeo.seoMetadescription}></Seo>
      <BreadCrumbs title={title}></BreadCrumbs>
      <VehicleIntroduction
        title={title}
        description={description}
      // popularProducts={popularProducts}
      >
      </VehicleIntroduction>
      <div className="call-to-action-wrapper container" id="rentnow">
        <CtaForm category={category} title={title} />
      </div>
      {/* <HowItWorks /> */}
      <VehicleSelect category={category}></VehicleSelect>
      {/* <CustomerExperience reviews={reviews} /> */}
      <LocationMap />
      <OpeningHoursLanding information={information}></OpeningHoursLanding>
      <AdditionalInfo blocks={productBlocks} sharedBlocks={blocks}></AdditionalInfo>
      <Faq faqs={productFaqs} sharedFaqs={faqs} faqKeyword={faqKeyword}></Faq>
      <Links productLinks={productLinks}></Links>
      <CustomerService contact={contact} />
    </Layout>
  )
}

export default Landing