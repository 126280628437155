import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { OpeningHoursHome } from "../components/shared/opening-hours"
import LocationMap from "../components/location-map"
import RentLocations from "../components/shared/rent-locations"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Pagina niet gevonden..." index={false} follow={true} />
    <div className="container" style={{ marginTop: 50 }}>
      <h1>Oeps...de pagina is niet gevonden!</h1>
      <p>Geen zorgen, wij helpen u weer op weg.</p>
      <div style={{ maxWidth: 360 }}>
        <RentLocations />
      </div>
    </div>
    <OpeningHoursHome />
    {typeof window !== 'undefined' &&
      <LocationMap />
    }

  </Layout>
)

export default NotFoundPage
