import React from "react";
import { Link } from "gatsby";
import car from '../../images/svg/car.svg'
import passengerVan from '../../images/svg/passengerVan.svg'
import heavyTruck from '../../images/svg/heavyTruck.svg'
import coolingTruck from '../../images/svg/coolingTruck.svg'
import deliveryVan from '../../images/svg/deliveryVan.svg'
import ReactMarkdown from "react-markdown";

const CustomerService = ({contact}) => {
    return (
        <div className="cs-background">
            <div className="container">
                <div className="cs-wrapper">
                    <div className="cs-left">
                        <h2>{(contact && contact.title) ? contact.title : 'Klantenservice'}</h2>
                            {(contact && contact.description.data.description) ? <ReactMarkdown>{contact.description.data.description}</ReactMarkdown> : <p>Indien je enige vragen hebt, neem dan gerust contact met ons op. Service is onze kracht!</p>}
                    </div>
                    <div className="cs-right">
                        <div className="cs-right__title">
                            <h2>Huurauto Aanbiedingen</h2>
                        </div>
                        <div className="cs-right__grid">
                            <Link to='/auto-huren'>
                                <div className="thumbnail__product">
                                    <div>
                                        <img src={car} alt="Auto huren" title="auto huren" width="48" height="48" style={{ paddingBottom: 4 }} className='img-fluid'></img>
                                        <br></br>
                                        Auto
                                    </div>
                                </div>
                            </Link>
                            <Link to='/bestelauto-huren'>
                                <div className="thumbnail__product">
                                    <div>
                                        <img src={deliveryVan} alt="Bestelauto huren" title="bestelauto huren" width="48" height="48" style={{ paddingBottom: 4 }} className='img-fluid'></img>
                                        <br></br>
                                        Bestelauto
                                    </div>
                                </div>
                            </Link>
                            <Link to='/personenbus-huren'>
                                <div className="thumbnail__product">
                                    <div>
                                        <img src={passengerVan} alt="Personenbus huren" title="personenbus huren" width="48" height="48" style={{ paddingBottom: 4 }} className='img-fluid'></img>
                                        <br></br>
                                        Personenbus
                                    </div>
                                </div>
                            </Link>
                            <Link to='/busje-huren'>
                                <div className="thumbnail__product">
                                    <div>
                                        <img src={deliveryVan} alt="Busje huren" title="busje huren" width="48" height="48" style={{ paddingBottom: 4 }} className='img-fluid'></img>
                                        <br></br>
                                        Busje
                                    </div>
                                </div>
                            </Link>
                            <Link to='/vrachtwagen-huren'>
                                <div className="thumbnail__product">
                                    <div>
                                        <img src={heavyTruck} alt="Vrachtwagen huren" title="vrachtwagen huren" width="48" height="48" style={{ paddingBottom: 4 }} className='img-fluid'></img>
                                        <br></br>
                                        Vrachtwagen
                                    </div>
                                </div>
                            </Link>
                            <Link to='/koelwagen-huren'>
                                <div className="thumbnail__product">
                                    <div>
                                        <img src={coolingTruck} alt="Koelwagen huren" title="koelwagen huren" width="48" height="48" style={{ paddingBottom: 4 }} className='img-fluid'></img>
                                        <br></br>
                                        Koelwagen
                                    </div>
                                </div>
                            </Link>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default CustomerService