import axiosInstance from "./instance"
export default async function postCustomer(
  reservationReference: string,
  bookingReference: string,
  body: any
) {
  const url = `/api/v2/reservations/${reservationReference}/rentals/${bookingReference}/customer`
  try {
    const response = await axiosInstance.post(url, body, {
      headers: {
        clientid: "420365",
        channelid: "3707",
      },
    })
    console.log('response postCustomer', response.data)
    return response.data
  } catch (error) {
    console.error(error)
  }
}
