import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const Footer = () => (
    <div className="reservation-footer">
        <div className="container">
            <div className="reservation-flex">
                <div>
                    <FontAwesomeIcon icon={faCheckCircle} className="reservation-footer__check" /> Service is onze Kracht
                </div>
                <div>
                    <FontAwesomeIcon icon={faCheckCircle} className="reservation-footer__check" /> Kwaliteit
                </div>
                <div>
                    <FontAwesomeIcon icon={faCheckCircle} className="reservation-footer__check" /> Familiebedrijf
                </div>
            </div>
        </div>
    </div>
)

export default Footer