import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { Container } from "@mui/material"
import SEO from "../components/seo"

const Blogs = ({ data }) => {

  const sortByCreationDate = (a, b) => {
    if (new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime()) {
      return 1;
    }
    if (new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()) {
      return -1;
    };

    return 0;
  };

  return (
  <Layout>
    <SEO index={true} follow={true} title='Lees alles over onze verhuur software oplossingen en meer' description='Wij delen onze kennis en gedachtes inzake autoverhuur en shortlease graag met jou. Keertje sparren?'></SEO>
    <Container>
      <div className="blogs">
        <div>
          <h1>Onze Blogs</h1>
          <p>
            Wij vinden het belangrijk om onze visie en kennis te delen.
          </p>
        </div>
        {data.allStrapiArticle.nodes.sort(sortByCreationDate).map((node) => (
          <Link to={`/blogs/${node.slug.toLowerCase().replaceAll(' ', '-')}`} className="none" key={node.strapi_id}>
            <div className="blogs__card">
              {node.heroImage.url &&
                <img src={node.heroImage.url} alt={node.heroImage.alternativeText} title={node.heroImage.caption} className='img-fluid'></img>
              }
              <h2 style={{marginTop: 12}}>{node.title}</h2>
              <p>{node.description}</p>
            </div>
          </Link>
        ))}
      </div>
    </Container>

  </Layout>
)}

export const query = graphql`
  {
    allStrapiArticle {
      nodes {
        heroImage {
          url
          alternativeText
          caption
        }
        description
        createdAt
        slug
        strapi_id
        title
      }
    }
  }
`

export default Blogs
