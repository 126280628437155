import { Request } from "express";

import { RatalityRequestOptions } from "@bakkie/ratality";

function hasDecodedJwt(request: unknown): request is { user: { clientId: number; channelId: number, userId?: string } } {
  return request instanceof Object && 'user' in request;
}

export function getRequestOptions(request: Request): RatalityRequestOptions {
  if (hasDecodedJwt(request)) {
    const { clientId, channelId, userId } = request.user;
    return { clientId, channelId, userId };
  } else {
    throw new Error('Missing JWT');
  }
}

export function logRatalityError(error: unknown, context: string): void {
  switch (typeof error) {
    case 'object':
      if ('response' in error) {
        console.error(`[${context}] error, status: ${error['response'].status}, data: `, error['response'].data);
      } else {
        console.error(`[${context}] error, status: `, error);
      }
      break;
    case 'string':
      console.error(`[${context}] error: ${error}`);
      break;
    default:
      console.error(`[${context}] error: `, error);
      break;
  }
}

export function searchParams(params): URLSearchParams {
  const searchParams = new URLSearchParams();

  Object.entries(params)
    .forEach(([key, value]) => {
      switch (typeof value) {
        case 'string':
          searchParams.append(key, value);
          break;
        case 'number':
          searchParams.append(key, `${value}`);
          break;
      }
    });

  return searchParams;
}