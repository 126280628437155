import * as React from "react"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"

const Products = ({ products }) => {
    return (
        <Container fluid='xxl'>
            <div className="products">
                {products.map(product => (
                    <div className="product" key={product.strapi_id}>
                        <div className="product-image">
                            {<img src={product.image.url} alt={product.image.alternativeText} className="product__image"></img>}    
                        </div>
                        <div className="product-content">
                        <div className="product__content__title">{product.title}</div>
                            <div>
                                <p>
                                    {product.description}
                                </p>
                            </div>
                            {/* <p className="price">V.A. €{product.price}</p> */}

                            <div className="vehicle-select__buttons">
                                <div><Link className="btn button-primary" to={product.link}>{product.cta}</Link></div>
                            </div>                    
                        </div>
                    </div>
                ))}
            </div>
        </Container>
    )
}

export default Products