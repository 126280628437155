import Layout from "../../components/layout"
import Seo from "../../components/seo"
import VehicleClassComponent from "../../components/landing-pages/vehicle-class"
import BreadCrumbs from "../../components/shared/breadcrumbs"
import ReactMarkdown from "react-markdown"
import Faq from "../../components/shared/faq"
import Settings from '../../components/settings.json'

const Product = (props) => {
  const { basicSeo, title, description, subFolder, vehicleClassId, faqs } = props.pageContext;
  const query = new URLSearchParams(props.location.search);
  const queryId = query.get('id');
  const queryBranch = query.get('branch');

  return (
    <Layout>
      <Seo index={true} follow={true} title={basicSeo.seoTitle} description={basicSeo.seoMetadescription}></Seo>
      <BreadCrumbs title={title} subFolder={subFolder} ></BreadCrumbs>
      <div className="container">
        <div className="vehicle-select-headline">
          <h1>{title}</h1>
          {description.data &&
            <ReactMarkdown>{description.data.description}</ReactMarkdown>
          }
        </div>
      </div>
      <VehicleClassComponent fallbackId={vehicleClassId} queryId={queryId} queryBranch={queryBranch} fallbackBranch={Settings.locations[0].branchId}></VehicleClassComponent>
      <Faq faqs={faqs} faqKeyword={title}></Faq>
    </Layout>
  )
}

export default Product