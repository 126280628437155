import * as React from "react"
import { useEffect, useState } from "react"
import { Rentvisie } from "@bakkie/ratality"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./shared/header"
import "../styles/styles.scss"
import "normalize.css"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faCalendarAlt,
  faCar,
  faCheckCircle,
  faChevronRight,
  faMapMarkerAlt,
  faPhone,
  faQuoteRight,
  fas,
  faSearch,
  faStar,
} from "@fortawesome/free-solid-svg-icons"
import loadable from "@loadable/component"
import AskForVat from "./shared/show-vat"
import OpeningHoursDialog from "./banners/OpeningHours"
const LoadableFooter = loadable(() => import("./shared/footer"))
const LoadableCookieBanner = loadable(() => import("./cookie-consent-nl"))
const LoadableCompleteCheckout = loadable(() =>
  import("../components/shared/completeCheckout")
)
library.add(
  fas,
  faStar,
  faMapMarkerAlt,
  faPhone,
  faChevronRight,
  faCheckCircle,
  faSearch,
  faCalendarAlt,
  faCar,
  faQuoteRight
)

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const [showButton, setShowButton] = useState(false)
  useEffect(() => {
    if (window !== "undefined") {
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > 300) {
          setShowButton(true)
        } else {
          setShowButton(false)
        }
      })
    }
    Rentvisie.setConfig({
      baseUrl: "https://api.rentvisie.com/api",
      client: "bertjonk",
    })
  }, [])

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    })
  }

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
        <main>{children}</main>
        <AskForVat />
        <LoadableCookieBanner />
        <LoadableCompleteCheckout />
        <OpeningHoursDialog />
        {showButton && (
          <button onClick={scrollToTop} className="back-to-top">
            &#8679;
          </button>
        )}
      </div>
      <LoadableFooter />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
