import { Table } from "react-bootstrap"

const dagtarieven = ['Enkele Dag', '3 tot 6 Dagen', 'Weekend', 'Week'];

const PriceTable = ({ vehicleClass }) => {
    let includeVat = 'no'
    if (typeof window !== "undefined") {
        includeVat = localStorage.getItem('includeVat');
    }
    return (
        <>
            <h2>Prijzen</h2>
            <p>Onderstaande prijzen zijn de gemiddelde prijzen gemeten over een periode van 1 jaar. Afhankelijk van het moment van boeken en de gekozen periode is het bedrag soms iets hoger en soms iets lager.</p>
            <Table borderless className="price-table" responsive='sm'>
            <thead>
                <tr>
                    <th><p>Huurperiode</p></th>
                    <th><p>Dagprijs</p></th>
                    <th><p>Vrije Kilometers per Dag</p></th>
                </tr>
            </thead>
            <tbody>
                {vehicleClass.marketingPrices.slice(1, 5).map((price, i) => (
                    <tr key={i}>
                        <td><p>{dagtarieven[i]}</p></td>
                        <td><p> {'€ '}
                            {includeVat === 'yes' ? (((price.value * 100) / 100).toFixed(2).toString().replace(".", ",")) : ((price.value / 1.21).toFixed(2).toString().replace(".", ","))}
                        </p></td>
                        {vehicleClass.marketingMileages.map((mile, j) => (i === j)
                            && <td><p>{mile.mileageDescription}</p></td>
                        )}
                    </tr>
                ))}

                <tr>
                    <td><p>Maand</p></td>
                    <td colSpan={2}><p>Op aanvraag</p></td>
                </tr>
            </tbody>
        </Table>
        </>
    )
}

export default PriceTable