import { Container } from "react-bootstrap";
import OthersBackground from "../../components/backgrounds/others";
import Layout from "../../components/layout";
import { useState } from "react";
import ContactForm from "../../components/shared/contact-form";
import { Link, useStaticQuery, graphql } from "gatsby";
import LocationMap from "../../components/location-map";
import { OpeningHoursHome } from "../../components/shared/opening-hours";
import SEO from "../../components/seo";
import Modal from 'react-bootstrap/Modal';
import CtaFormWispo from "../../components/reservation/wispo/CtaForm";

const WispoPage = () => {
    const [showPromocode, setShowPromocode] = useState(false);
    const [show, setShow] = useState(false);
    const [days, setDays] = useState(9)

    const handleClose = () => setShow(false);

    const revealPromocode = (amount) => {
        setShowPromocode(true)
        localStorage.setItem('promocodeWispo', amount);
    }

    const [productSelected, setProductSelected] = useState(undefined);

    const getPrice = (price) => price * days;


    const data = useStaticQuery(graphql`
    query {
    strapiWintersport {
        discountProducts {
            strapi_id
            price
            name
            includedItems
            description
            rentvisieCategoryId
            image {
            alternativeText
            caption
            url
            }
            location {
            oudeMeer {
                vehicleClassId
            }
            purmerend {
                vehicleClassId
            }
            zwaag {
                vehicleClassId
            }
            }
        }
    }
    }
`)

    const products = data.strapiWintersport.discountProducts

    return (
        <Layout>
            <SEO
                title='15% Wintersport Korting 2023/2024 - Auto of Busje huren - Bert Jonk Autoverhuur'
                metadescription='Auto of Busje huren voor Wintersport 2022/2023 doe je bij Bert Jonk Autoverhuur. 25% Korting op de speciaal voor wintersport uitgeruste voertuigen.'
                index={true}
                follow={true}
            />
            <OthersBackground title='Wintersport Aanbiedingen 2023/2024' subtitle={'15% Korting op jouw vervoer naar de witte bergen'} />
            {productSelected &&
                <Modal show={show} dialogClassName='modal-90w' onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body closeButton>
                        <h2>Huur uw {productSelected.name}</h2>
                        <CtaFormWispo productSelected={productSelected} />
                    </Modal.Body>
                </Modal>
            }
            <section>
                <Container>
                    <div style={{ textAlign: 'center' }}>
                        <h2>Wintersport Aanbiedingen: Busje of Auto huren met korting?</h2>
                        <p>Ga voordelig met een huurauto van Bert Jonk Autoverhuur op wintersportvakantie</p>
                        <div>
                            <p>Met slechts enkele klikken heeft u de eerste apres-ski rondjes verdient. Klik eerst op de knop hieronder om uw Kortingscode te onthullen.</p>
                        </div>
                    </div>
                    <div style={{ textAlign: 'center', border: '1px solid red', padding: '2rem', margin: '2rem', background: 'white' }} >
                        <h2>Wintersport Aanbiedingen</h2>
                        <p style={{ fontWeight: 600 }}>Wij investeren in uw wintersport: De eerste rondjes zijn van ons.</p>
                        <p>Ga extra voordelig op weg met maar liefst 15% korting op het basistarief van alle personenbussen en personenauto's.</p>
                        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                            {!showPromocode && <button className="button-primary" onClick={() => { revealPromocode(15) }}>Onthul jouw kortingscode</button>}
                            {showPromocode &&
                                <ul style={{ listStyle: 'none' }}>
                                    <li style={{ marginBottom: 4 }}><strong>Kortingscode:</strong> Wispo15</li>
                                </ul>
                            }
                        </div>
                    </div>
                    <div>
                        {showPromocode &&
                            <div style={{ textAlign: 'center' }}>
                                <p>Gefeliciteerd, u heeft uw korting succesvol geclaimt. <span role="img" aria-label="wintersport emoticons">&#128519; &#128526;</span></p>
                                <p>

                                    Hieronder treft u de meestgehuurde voertuigen aan. Alle voertuigen worden standaard uitgerust met winterbanden en u krijgt sneeuwkettingen mee.
                                </p>
                                <p>
                                    Via de knop <strong>"Beschikbaarheid Controleren"</strong> kunt u de beschikbaarheid van het voertuig controleren en direct reserveren.
                                    Indien wij het voertuig online niet beschikbaar hebben of indien u contact met ons wilt, kunt u het formulier hieronder gebruiken om een offerte op te vragen.
                                </p>
                            </div>
                        }

                    </div>
                </Container>
            </section>
            <section>
                <Container>
                    <h2>Meestgehuurde voertuigen wintersport</h2>
                    <div className="products">
                        {products.map(product => (
                            <div className="product" key={product?.strapi_id} style={{ paddingTop: 24 }}>
                                <div className="product-image">
                                    {<img src={product?.image?.url} alt={product?.image?.alternativeText} title={product?.image?.caption} className="product__image"></img>}
                                </div>
                                <div className="product-content">
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div className="product__content__title"><h3 style={{ fontWeight: 600 }}>{product?.name}</h3></div>
                                        <p className="price">€{product?.price.toFixed(2).toString().replace('.', ',')} /dag</p>
                                    </div>
                                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: 340, fontSize: 16, paddingBottom: 8 }}> */}
                                    <div>
                                        <div style={{ fontWeight: 600, fontSize: 12 }}>Bereken jouw prijs: </div>
                                        <div className="price-calculation">
                                            <p style={{ margin: 5 }}><input className='amount-of-days' type="number" value={days} min="0" max="29" step="1" onChange={(e) => { setDays(e.target.value) }}></input> <span className="small">dagen</span></p>
                                            <p style={{ margin: 5 }}>  {days < 30 ? `€ ${getPrice(product.price).toFixed(2).toString().replace('.', ',')}` : <span className="small">vul het aantal dagen in (max. 30)</span>}</p>
                                        </div>
                                    </div>
                                    <p style={{ fontSize: 12 }}>Prijzen inclusief BTW, winterbanden en sneeuwkettingen</p>

                                    <div className="vehicle-select__buttons" style={{ marginTop: 24 }}>
                                        <div><Link className="btn button-outline-primary" to='#offerte-opvragen'>Offerte Opvragen</Link></div>
                                        <div className="btn button-primary" onClick={() => { setShow(true); setProductSelected(product) }}>Beschikbaarheid Controleren</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Container>
            </section>
            <div id='offerte-opvragen'>
                <ContactForm />
            </div>
            <Container>
                <div style={{ textAlign: 'center' }}>
                    <p>Winteraanbiedingen zijn geldig vanaf november 2023 tot en met april 2024.</p>
                </div>
            </Container>
            <LocationMap />
            <OpeningHoursHome />
        </Layout>
    )
}

export default WispoPage;